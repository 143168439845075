import axios from "axios";

export default class serviceLine {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getServices(queries) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/serviceLine/cms`,
        data: queries
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getServiceById(id) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/serviceLine/service/${id}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async downloadServiceTable(queries) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/serviceLine/cms`,
        data: queries
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
