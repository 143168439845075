import axios from "axios";

export default class Apps {
	constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getLinks() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/users/device`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async saveLink(platform, body) {
    // console.log(`Saved ${platform}`);
    // console.log(body);
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/users/device`,
        data: {
          deviceType: platform,
          ...body
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
}