<template>
  <main id="MobileStiva" class="animated fadeIn">
    <router-view>
    </router-view>
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss"  >
</style>
