<template>
  <div id="conduct-list">
    <header>
      <h1>{{$t("conduct.list.title")}}</h1>
      <p>{{$t('conduct.list.directions')}}</p>
    </header>
    <div class="cards-container">
      <!-- New Post -->
      <InfoCard
      @click.native="newModalActive = true"
      :addBtn="true">
        <i class="fas fa-plus"></i>
      </InfoCard>
      <!-- Cards -->
      <InfoCard
      v-for="(category, index) in categories"
      :key="index">
        <template #header>
          <span>{{category.title}}</span>
        </template>
        <!-- <template>
          {{category.description}}
        </template> -->
        <template #left-control>
          <div class="actions">
            <!-- Edit -->
            <i
            class="far fa-edit"
            @click="openEditModal(category)"></i>
            <!-- Delete -->
            <i
            class="far fa-trash-alt"
            @click="openDeleteModal(category)"></i>
          </div>
        </template>
        <!-- Edit content -->
        <template #right-control>
          <router-link
          class="content"
          :to="{
            name: 'Code Edit',
            params: {
              id: category.entryId
            }
          }">
            <span>{{$t('conduct.list.edit')}}</span>
            <i class="fas fa-chevron-right"></i>
          </router-link>
        </template>
      </InfoCard>
    </div>
    <!-- New Category Modal -->
    <Modal
    v-if="newModalActive"
    v-on:closeModal="closeModal">
      <!-- Modal title -->
      <template #header>
        <span class="title">{{modalTitle}}</span>
      </template>
      <!-- Modal body -->
      <template>
        <p>{{$t('conduct.modal.directions')}}</p>
        <div class="form-container">
          <!-- Name -->
          <div class="input-container">
            <label for="name">{{$t('conduct.modal.name')}}</label>
            <input
            type="text"
            id="name"
            name="name"
            :placeholder="$t('conduct.modal.name_ph')"
            v-model="$v.form.title.$model">
            <div class="error-msg">
              <span v-if="$v.form.title.$error">{{$t("conduct.errors.name")}}</span>
            </div>
          </div>
          <!-- Description -->
          <!-- <div class="input-container">
            <label for="description">{{$t('conduct.modal.description')}}</label>
            <div class="error-msg">
              <span v-if="$v.form.description.$error">{{$t("conduct.errors.description")}}</span>
            </div>
            <textarea
            id="description"
            name="description"
            :placeholder="$t('conduct.modal.description_ph')"
            v-model="$v.form.description.$model"></textarea>
          </div> -->
        </div>
      </template>
      <!-- Modal footer -->
      <template #footer>
        <div class="modal-actions">
          <span
          class="cancel-btn"
          @click="closeModal">{{$t('conduct.modal.cancel')}}</span>
          <button
          type="button"
          class="btn btn-dark save-btn"
          :disabled="isLoading"
          @click="validateForm">{{$t('conduct.modal.save')}}</button>
        </div>
      </template>
    </Modal>
    <!-- Delete Modal -->
    <Modal
    v-if="deleteModalActive"
    v-on:closeModal="closeDeleteModal">
      <!-- Modal title -->
      <template #header>
        <span class="title">{{$t('conduct.modal_delete.title')}}</span>
      </template>
      <!-- Modal Body -->
      <template>
        <p>{{$t('conduct.modal_delete.body')}}</p>
        <strong>{{modalToDelete.title}}</strong>
      </template>
      <!-- Modal footer -->
      <template #footer>
        <div class="modal-actions">
          <span
          class="cancel-btn"
          @click="closeDeleteModal">{{$t('conduct.modal_delete.cancel')}}</span>
          <button
          type="button"
          class="btn btn-dark save-btn"
          :disabled="isLoading"
          @click="deleteCategory">{{$t('conduct.modal_delete.delete')}}</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import CONDUCT_API from "@/lib/api/Conduct.js";
import { required } from "vuelidate/lib/validators";
const InfoCard = () => import("@/components/InfoCard");
const Modal = () => import("@/components/Modal");

export default {
  components: {
    InfoCard,
    Modal
  },
  data: function() {
    return {
      categories: null,
      newModalActive: false,
      deleteModalActive: false,
      form: {
        title: null
        // description: null
      },
      isLoading: false,
      categoryId: null
    }
  },
  computed: {
    modalTitle: function() {
      return this.categoryId ? this.$t('conduct.modal.edit_title') : this.$t('conduct.modal.new_title');
    },
    modalToDelete: function() {
      return this.categories.filter(category => category.entryId == this.categoryId)[0];
    }
  },
  validations: {
    form: {
      title: {
        required
      }
      // description: {
      //   required
      // }
    }
  },
  beforeMount: async function() {
    await this.getCategories();
  },
  methods: {
    getCategories: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new CONDUCT_API();
      try {
        const response = await api.getCategories();
        this.categories = response;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    openEditModal: function(category) {
      this.categoryId = category.entryId;
      this.form.title = category.title;
      // this.form.description = category.description;
      this.newModalActive = true;
    },
    openDeleteModal: function(category) {
      this.categoryId = category.entryId;
      this.deleteModalActive = true;
    },
    closeModal: function() {
      this.categoryId = null;
      this.form.title = null;
      // this.form.description = null;
      this.newModalActive = false;
      this.$v.$reset();
    },
    closeDeleteModal: function() {
      this.categoryId = null;
      this.deleteModalActive = false;
    },
    validateForm: async function() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      await this.saveCategory();
    },
    saveCategory: async function() {
      this.isLoading = true;
      this.$store.commit("global/SET_LOADING", true);
      const api = new CONDUCT_API();
      try {
        // Add empty content
        const data = {};
        data.title = this.form.title;
        data.author = this.$store.getters["session/userControl"];
        if (!this.categoryId) {
          data.body = {
            "ops": [
              {
                "insert": "\n"
              }
            ]
          }
        }
        await api.saveCategory(this.categoryId, data);
        await this.getCategories();
        this.$store.commit("global/SET_LOADING", false);
        this.isLoading = false;
        await this.closeModal();
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        this.isLoading = false;
        throw error;
      }
    },
    deleteCategory: async function() {
      this.isLoading = true;
      this.$store.commit("global/SET_LOADING", true);
      const api = new CONDUCT_API();
      try {
        await api.deleteCategory(this.categoryId);
        await this.getCategories();
        this.$store.commit("global/SET_LOADING", false);
        this.isLoading = false;
        await this.closeDeleteModal();
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        this.isLoading = false;
        throw error;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.fa-plus { 
  font-size: 3rem;
}
.cards-container .actions, .cards-container .content {
  display: flex;
  align-items: center;
  > * {
    cursor: pointer;
  }
  > i {
    font-size: 1.2rem;
  }
}
.cards-container .actions {
  > * {
    margin-right: 1rem;
  }
}
.cards-container .content {
  color: $b_2;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    > span {
      text-decoration: underline;
    }
  }
  
  > * {
    margin-left: 0.5rem;
  }
}

.title {
  @extend %audi-ext-b;
  font-size: 1.2rem;
}
.input-container {
  margin: 1rem 0;
  label {
    @extend %audi-b;
  }
  input, textarea {
    color: $bg_1;
  }
}
.modal-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-left: 1rem;
    cursor: pointer;
  }

  .cancel-btn {
    &:hover {
      text-decoration: underline;
    }
  }
  .save-btn {
    @extend %audi-b;
    color: $white;
    background-color: $black;
    border-color: $black;
    padding: 10px 20px;
    border-radius: 0px;
    transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  }
}
#description {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 0.5rem 1rem;
}
</style>