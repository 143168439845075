var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-manage"}},[_c('header',[_c('h1',[_vm._v(_vm._s(_vm.$t('users.extern.table.title')))]),_c('router-link',{attrs:{"to":{
      name: 'New Extern User'
    }}},[_c('button',{staticClass:"new-btn"},[_vm._v(_vm._s(_vm.$t('users.extern.table.new')))])]),_c('button',{staticClass:"btn-download",on:{"click":_vm.downloadReport}},[_vm._v(_vm._s(_vm.$t("events.listEvents.donwloadBtn")))])],1),_c('v-server-table',{ref:"userTable",attrs:{"columns":_vm.table_options.columns,"options":_vm.table_options.options},scopedSlots:_vm._u([{key:"actions",fn:function(props){return [_c('div',{staticClass:"actionsExterns"},[_c('router-link',{staticClass:"far edit-btn",attrs:{"to":{
          name: 'User Extern Edit',
          params: {
            controlNumber: props.row.controlNumber
          }
        }}},[_c('i',{staticClass:"far fa-edit"})]),_c('i',{staticClass:"far fa-trash-alt",on:{"click":function($event){return _vm.deleteExternUser(props)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }