var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"driver"},[_c('div',{staticClass:"row bg-white"},[_c('div',{staticClass:"col-md-12 top py-2 px-4"},[_c('router-link',{staticClass:"return",attrs:{"to":{
          name: 'Trip Details',
          params: {
            id: this.id,
            travelStatus: this.data.travelStatus,
            data: this.data,
            details: this.details,
            tripDone: this.tripDone,
            onGoingOrDone: this.onGoingOrDone,
          }
        }}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v("\n        "+_vm._s(_vm.$t("events.newEvent.back"))+"\n      ")])],1),_c('div',{staticClass:"col-md-12 px-5 py-2"},[_c('h2',{staticClass:"labelBold"},[_vm._v("\n        "+_vm._s(this.$t("qrAccess.travels.driverDetail.title"))+"\n      ")]),_c('div',{staticClass:"driverInfo py-4"},[_c('label',{staticClass:"labelBold"},[_vm._v("\n          "+_vm._s(this.$t("qrAccess.travels.driverDetail.name"))+"\n        ")]),_c('p',[_vm._v(_vm._s(this.name))]),_c('label',{staticClass:"labelBold py-2"},[_vm._v("\n          "+_vm._s(this.$t("qrAccess.travels.driverDetail.lastName"))+"\n        ")]),_c('p',[_vm._v(_vm._s(this.lastName))]),_c('label',{staticClass:"labelBold py-2"},[_vm._v("\n          "+_vm._s(this.$t("qrAccess.travels.driverDetail.motherLastName"))+"\n        ")]),_c('p',[_vm._v(_vm._s(this.mothersLastName))]),_c('label',{staticClass:"labelBold py-2"},[_vm._v("\n          "+_vm._s(this.$t("qrAccess.travels.driverDetail.company"))+"\n        ")]),_c('p',[_vm._v(_vm._s(this.company))]),_c('label',{staticClass:"labelBold py-2"},[_vm._v("\n          "+_vm._s(this.$t("qrAccess.travels.driverDetail.idProvider"))+"\n        ")]),_c('p',[_vm._v(_vm._s(this.providerNumber))]),_c('label',{staticClass:"labelBold py-2"},[_vm._v("\n          "+_vm._s(this.$t("qrAccess.travels.driverDetail.uniqueId"))+"\n        ")]),_c('p',[_vm._v(_vm._s(this.controlNumber))]),_c('label',{staticClass:"labelBold py-2"},[_vm._v("\n          "+_vm._s(this.$t("qrAccess.travels.driverDetail.phone"))+"\n        ")]),_c('p',[_vm._v(_vm._s(this.phone))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }