<template>
  <div id="campaign-list">
    <header>
      <h1>{{$t("ideas.campaign.list.title")}}</h1>
      <!-- <p>{{$t('ideas.campaign.list.directions')}}</p> -->
    </header>
    <div class="cards-container">
      <!-- New Campaign -->
      <InfoCard :addBtn="true" @click.native="$router.push({ name: 'Campaign New'})">
        <i class="fas fa-plus"></i>
      </InfoCard>
      <InfoCard v-for="(campaign, i) in campaigns" :key="i">
        <template #header>{{campaign.title}}</template>
        <!-- <template #sub>Alerta - Actualizado<br>06/Ago/2019</template> -->
        <template>{{campaign.description}}</template>
        <template #right-control>
          <router-link
            :to="{
            name: 'Campaign Edit',
            params: {
              id: campaign.id
            }
          }"
          >
            <i class="icons-black far fa-edit"></i>
          </router-link>
          <span @click="openModal(campaign)">
            <i class="icons-black far fa-trash-alt"></i>
          </span>
        </template>
      </InfoCard>
    </div>
    <!-- Modal -->
    <Modal v-if="modalActive" v-on:closeModal="closeModal">
      <template #header>
        <h6 class="modal-title">{{$t('ideas.campaign.list.modal.title')}}</h6>
      </template>
      <template>
        <p>{{$t('ideas.campaign.list.modal.body')}}</p>
      </template>
      <template #footer>
        <div class="modal-controls">
          <span
            class="modal--cancel-btn"
            @click="closeModal"
          >{{$t('ideas.campaign.list.modal.cancel')}}</span>
          <button
            type="button"
            class="modal--delete-btn"
            :disabled="isLoading"
            @click="deleteCampaign"
          >{{$t('ideas.campaign.list.modal.delete')}}</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import API_SERVICE from "@/lib/api/Ideas.js";
const InfoCard = () => import("@/components/InfoCard");
const Modal = () => import("@/components/Modal");
const api = new API_SERVICE();

export default {
  components: {
    InfoCard,
    Modal
  },
  data: function() {
    return {
      campaigns: null,
      campaignToDelete: null,
      modalActive: false
    };
  },
  computed: {
    isLoading: function() {
      return this.$store.getters["global/isLoading"];
    }
  },
  beforeMount: async function() {
    await this.getCampaigns();
  },
  methods: {
    getCampaigns: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getCampaigns();
        this.campaigns = resp;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    openModal: function(campaign) {
      this.campaignToDelete = campaign;
      this.modalActive = true;
    },
    closeModal: function() {
      this.campaignToDelete = null;
      this.modalActive = false;
    },
    deleteCampaign: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        await api.deleteCampaign(this.campaignToDelete.id);
        this.closeModal();
        await this.getCampaigns();
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cards-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.edit-btn,
.delete-btn {
  @extend %audi;
  color: $black;
  cursor: pointer;
}
.edit-btn {
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.delete-btn {
  &:hover {
    color: $warn_red;
    text-decoration: underline;
  }
}
.modal-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.modal-controls > * {
  margin-left: 15px;
  font-size: 16px;
  cursor: pointer;
}
.modal--cancel-btn {
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    text-decoration: underline;
  }
}
.modal--delete-btn {
  @extend %audi-b;
  color: $white;
  background-color: $black;
  border-color: $black;
  padding: 10px 20px;
  border: 1px solid $black;
  border-radius: 0px;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    background-color: $warn_red;
    border-color: $warn_red;
  }
}
</style>
<style >
.icons-black {
  color: #000;
  font-size: 16px;
  cursor: pointer;
}
</style>
