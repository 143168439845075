<template>
  <div id="ideas-list">
    <header>
      <h1>{{ $t("ideas.top.list.title") }}</h1>
    </header>
    <div class="main-actions">
      <div class="ideas-email">
        <emailForm />
      </div>
      <Json2Csv v-if="ideas" :json-data="csvData" :csv-title="`Ideas_List`">
        <button class="download-btn">
          {{ $t("ideas.top.list.download") }}
        </button>
      </Json2Csv>
    </div>
    <main class="list-table">
      <v-client-table
        v-if="ideas"
        :columns="table_options.columns"
        :options="table_options.options"
        :data="ideas"
      >
        <!-- Actions -->
        <template v-slot:top="props">
          <div class="starred">
            <i v-if="props.row.top" class="fas fa-star"></i>
          </div>
        </template>
        <template v-slot:createdAt="props">
          {{ props.row.createdAt | formatDate(appLang) }}
        </template>
        <template v-slot:actions="props">
          <div class="actions">
            <!-- Edit -->
            <router-link
              class="edit-btn"
              :to="{
                name: 'Ideas Edit',
                params: {
                  id: props.row.id
                }
              }"
            >
              <i class="far fa-edit"></i>
            </router-link>
            <!-- Delete -->
            <i class="far fa-trash-alt" @click="openModal(props.row)"></i>
          </div>
        </template>
      </v-client-table>
    </main>
    <!-- Modal -->
    <Modal v-if="modalActive" v-on:closeModal="closeModal">
      <template #header>
        <h6 class="modal-title">{{ $t("ideas.top.list.modal.title") }}</h6>
      </template>
      <template>
        <p>{{ $t("ideas.top.list.modal.body") }}</p>
        <p class="delete-warning">
          {{ $t("ideas.top.list.modal.body_warning") }}
        </p>
      </template>
      <template #footer>
        <div class="modal-controls">
          <span class="modal--cancel-btn" @click="closeModal">{{
            $t("ideas.top.list.modal.cancel")
          }}</span>
          <button
            type="button"
            class="modal--delete-btn"
            :disabled="isLoading"
            @click="deleteIdea"
          >
            {{ $t("ideas.top.list.modal.delete") }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Json2Csv from "vue-json-to-csv";
import S3_API from "@/lib/api/s3_handler";
import API_SERVICE from "@/lib/api/Ideas.js";
const emailForm = () => import("./IdeasEmail");
const Modal = () => import("@/components/Modal");
const api = new API_SERVICE();
const s3 = new S3_API();

export default {
  components: {
    emailForm,
    Json2Csv,
    Modal
  },
  data: function() {
    return {
      ideas: null,
      modalActive: false,
      ideaToDelete: null,
      csvData: null
    };
  },
  computed: {
    table_options: function() {
      return {
        columns: [
          "id",
          "top",
          "status",
          "author",
          "problem",
          "createdAt",
          "actions"
        ],
        options: {
          texts: {
            filter: `${this.$t("components.vue_tables.filter")}:`,
            filterPlaceholder: this.$t("components.vue_tables.filter_ph")
          },
          headings: {
            id: this.$t("ideas.top.list.table.id"),
            top: this.$t("ideas.top.list.table.top"),
            status: this.$t("ideas.top.list.table.status"),
            author: this.$t("ideas.top.list.table.author"),
            problem: this.$t("ideas.top.list.table.problem"),
            createdAt: this.$t("ideas.top.list.table.createdAt"),
            actions: this.$t("ideas.top.list.table.actions")
          },
          sortable: ["id", "top", "createdAt"],
          filterable: ["id", "top", "status", "author", "problem", "createdAt"],
          sortIcon: {
            base: "fas",
            up: "fa-sort-up",
            down: "fa-sort-down",
            is: "fa-sort"
          },
          columnsClasses: {
            id: "id-col",
            top: "top-col",
            status: "status-col",
            author: "author-col",
            problem: "problem-col",
            createdAt: "created-col",
            actions: "actions-col"
          }
        }
      };
    },
    appLang: function() {
      return this.$store.getters["settings/currentLang"];
    },
    isLoading: function() {
      return this.$store.getters["global/isLoading"];
    }
  },
  beforeMount: async function() {
    await this.getIdeas();
  },
  methods: {
    getIdeas: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getIdeas();
        this.ideas = resp;
        this.listToDownload();
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    listToDownload: async function() {
      const ideaFields = [
        "id",
        "campaign",
        "author",
        "responsible",
        "problem",
        "solution",
        "benefit",
        "isPossible",
        "needsExperiment",
        "problemImage",
        "solutionImage",
        "createdAt",
        "updatedAt"
      ];
      const csv = this.ideas.map(idea => {
        const obj = {};
        ideaFields.forEach(field => {
          if (field == "problemImage" || field == "solutionImage") {
            obj[field] = idea[field].includes(process.env.VUE_APP_S3_BUCKET)
              ? s3.getSignedUrl(idea[field], 86400)
              : idea[field];
          } else {
            obj[field] = idea[field];
          }
        });
        return obj;
      });
      this.csvData = csv;
    },
    openModal: function(idea) {
      this.modalActive = true;
      this.ideaToDelete = idea;
    },
    closeModal: function() {
      this.modalActive = false;
      this.ideaToDelete = null;
    },
    deleteIdea: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        await api.deleteIdea(this.ideaToDelete.id);
        this.closeModal();
        await this.getIdeas();
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
};
</script>
<style>
.form-inline label {
  display: initial;
}
</style>
<style lang="scss" scoped>
.main-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.download-btn {
  background-color: $black;
  color: $white;
  border: 1px solid $black;
  border-radius: 0;
  padding: 0.5rem 2rem;
}

.starred {
  text-align: center;
}
.actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.actions > * {
  cursor: pointer;
}
.actions > .edit-btn {
  color: $black;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  &:hover {
    color: $black;
  }
}

.modal-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.modal-controls > * {
  margin-left: 15px;
  font-size: 16px;
  cursor: pointer;
}
.modal--cancel-btn {
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    text-decoration: underline;
  }
}
.modal--delete-btn {
  @extend %audi-b;
  color: $white;
  background-color: $black;
  border-color: $black;
  padding: 10px 20px;
  border: 1px solid $black;
  border-radius: 0px;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    background-color: $warn_red;
    border-color: $warn_red;
  }
}
.delete-warning {
  color: $warn_red;
}
</style>

<style lang="scss">
#ideas-list {
  .VueTables__search-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .VueTables__table > thead th {
    vertical-align: middle;
    width: 10%;
  }
  .VueTables__table > tbody td {
    vertical-align: middle;
    width: 10%;
  }
  .VueTables__table > tbody .problem-col {
    width: 30%;
  }
  .VueTables__table > tbody .created-col {
    width: 20%;
  }
}
</style>
