<template>
  <div class="passenger">
    <div class="row bg-white">
      <div class="col-md-12 top py-2 px-4">
        <router-link
          class="return"
          :to="{
            name: 'Trip Passengers',
            params: {
              id: data.travelId,
              data: {}
            }
          }"
        >
          <i class="fas fa-chevron-left"></i>
          {{ $t("events.newEvent.back") }}
        </router-link>
      </div>
      <div class="col-md-12 px-5 py-2">
        <h2 class="labelBold">
          {{ this.$t(`qrAccess.travels.passengerDetail.title`) }}
        </h2>
        <div class="passengerInfo py-5 px-3">
          <label class="labelBold">
            {{ this.$t(`qrAccess.travels.passengerDetail.name`) }}
          </label>
          <p>{{data.passengerFullName}}</p>
          <label class="labelBold">
            {{ this.$t(`qrAccess.travels.passengerDetail.userType`) }}
          </label>
          <p>{{data.userType}}</p>
          <label class="labelBold">
            {{ this.$t(`qrAccess.travels.passengerDetail.idOrControlNumber`) }}
          </label>
          <p>{{data.controlNumber}}</p>
          <label class="labelBold">
            {{ this.$t(`qrAccess.travels.passengerDetail.company`) }}
          </label>
          <p>{{data.companyName}}</p>
          <label class="labelBold">
            {{ this.$t(`qrAccess.travels.passengerDetail.phone`) }}
          </label>
          <p>{{data.personalCellphoneNumber}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      id: {
        type: Number
      },
      travelId: {
        type: Number
      },
      data: {
        type: Object
      }
    },
    async beforeMount() {
    },
  };
</script>

<style>
  .return {
    color: black;
    cursor: pointer;
  }
  .return:hover {
    text-decoration: underline !important;
    color: black;
  }
  .labelBold {
    font-weight: bold;
    color: #000;
  }
</style>