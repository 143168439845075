<template>
  <div id="benefits-list">
    <header class="header">
      <div class="title">
        <h1>{{ $t("map.benefits.list.title") }}</h1>
        <p>
          {{ $t("map.benefits.list.directions1") }}
          <br />
          {{ $t("map.benefits.list.directions2") }}
        </p>
      </div>
      <div class="controllers">
        <button
          type="button"
          class="btn btn-dark control new-btn"
          @click="$router.push({ name: 'Benefits New' })"
        >
          {{ $t("map.benefits.list.new") }}
        </button>
      </div>
    </header>
    <!-- Benefits email -->
    <div class="ideas-email">
      <emailForm />
    </div>
    <!-- List table -->
    <v-client-table
      v-if="benefitsList"
      :columns="table_options.columns"
      :options="table_options.options"
      :data="benefitsList"
    >
      <!-- Logo -->
      <template #logo="props">
        <img
          v-if="props.row.logo"
          class="company_logo"
          :src="props.row.logo"
          :alt="`${props.row.name}_logo`"
        />
      </template>
      <template #description="props">
        <ul class="benefit-list">
          <li v-for="(desc, index) in props.row.benefits" :key="index">
            {{ desc.shortDescription }}
          </li>
        </ul>
      </template>
      <template #actions="props">
        <div class="actions">
          <!-- Delete btn -->
          <i class="far fa-trash-alt" @click="openModal(props.row)"></i>
          <!-- Edit btn -->
          <router-link
            :to="{
              name: 'Benefits Edit',
              params: {
                id: props.row.id
              }
            }"
          >
            <!-- {{$t('map.benefits.list.table_headers.details')}} -->
            <i class="far fa-edit"></i>
          </router-link>
        </div>
      </template>
    </v-client-table>
    <!-- Modal -->
    <Modal v-if="modalActive" @closeModal="closeModal">
      <template #header>
        <h6 class="modal-title">{{ $t("map.benefits.list.modal.title") }}</h6>
      </template>
      <template>
        <strong>{{ $t("map.benefits.list.modal.body") }}</strong>
      </template>
      <template #footer>
        <div class="controls">
          <span class="control cancel-btn" @click="closeModal">{{
            $t("map.benefits.list.modal.cancel")
          }}</span>
          <button
            type="control button"
            class="btn btn-dark control delete-btn"
            :disabled="isLoading"
            @click="deleteBenefit"
          >
            {{ $t("map.benefits.list.modal.delete") }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ClientTable } from "vue-tables-2";
import API_Service from "@/lib/api/Benefits.js";
import S3_Handler from "@/lib/api/s3_handler";
const Modal = () => import("@/components/Modal");
const emailForm = () => import("./BenefitsEmail");

export default {
  components: {
    emailForm,
    ClientTable,
    Modal
  },
  data: function() {
    return {
      benefitsList: null,
      modalActive: false,
      benefitToDelete: null,
      isLoading: false
    };
  },
  computed: {
    table_options: function() {
      return {
        columns: ["logo", "name", "description", "actions"],
        options: {
          texts: {
            filter: `${this.$t("components.vue_tables.filter")}:`,
            filterPlaceholder: this.$t("components.vue_tables.filter_ph")
          },
          headings: {
            logo: this.$t("map.benefits.list.table_headers.logo"),
            name: this.$t("map.benefits.list.table_headers.name"),
            description: this.$t("map.benefits.list.table_headers.body"),
            actions: this.$t("map.benefits.list.table_headers.actions")
          },
          sortable: ["name"],
          filterable: ["name", "description"],
          sortIcon: {
            base: "fas",
            up: "fa-sort-up",
            down: "fa-sort-down",
            is: "fa-sort"
          },
          columnsClasses: {
            logo: "logo-column",
            name: "name-column",
            description: "description-column",
            actions: "actions-column"
          },
          resizableColumns: false
        }
      };
    }
  },
  beforeMount: async function() {
    await this.getBenefitsList();
  },
  methods: {
    getBenefitsList: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      const s3 = new S3_Handler();
      try {
        const response = await api.getBenefitsList();
        const benefits = response.map(benefit => {
          if (benefit.logo.includes(process.env.VUE_APP_S3_BUCKET)) {
            benefit.logo = s3.getSignedUrl(benefit.logo);
          }
          return benefit;
        });
        this.benefitsList = benefits;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    openModal: function(benefit) {
      this.benefitToDelete = benefit;
      this.modalActive = true;
    },
    closeModal: function() {
      this.benefitToDelete = null;
      this.modalActive = false;
    },
    deleteBenefit: async function() {
      this.$store.commit("global/SET_LOADING", true);
      this.isLoading = true;
      const api = new API_Service();
      try {
        await api.deleteBenefit(this.benefitToDelete.id);
        this.isLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        this.closeModal();
        this.getBenefitsList();
      } catch (error) {
        this.isLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.new-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  padding: 0.5rem 2rem;
}

.benefit-list {
  margin: 0;
}

.actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.actions > * {
  cursor: pointer;
}
.actions > .edit-btn {
  color: $black;

  &:hover {
    color: $black;
  }
}

.modal-title {
  margin: 0;
}
.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.controls .control {
  margin-left: 15px;
  font-size: 16px;
  cursor: pointer;
}
.control.cancel-btn {
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    text-decoration: underline;
  }
}
.control.delete-btn {
  @extend %audi-b;
  color: $white;
  background-color: $black;
  border-color: $black;
  padding: 10px 20px;
  border-radius: 0px;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    background-color: $warn_red;
    border-color: $warn_red;
  }
}
.company_logo {
  max-height: 70px;
}
</style>

<style lang="scss">
#benefits-list {
  .VueTables__table td {
    vertical-align: middle;
  }
  .VueTables__table .logo-column {
    text-align: center;
  }
  .VueTables__table .name-column {
    width: 30%;
  }
  .VueTables__table .description-column {
    width: 40%;
  }
  .VueTables__table .actions-column {
    width: 15%;
  }
}
</style>
<style>
.benefit-list {
  padding: 1rem;
  list-style: none;
}
.actions i {
  color: black;
}
.form-inline label {
  display: initial;
}
</style>
