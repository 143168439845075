<template>
  <div id="bus-routes-upload">
    <h1>{{ $t("qrAccess.busRoutes.title") }}</h1>
    <p>{{ $t("qrAccess.busRoutes.directions") }}</p>
    <div class="row">
      <div class="col-md-12 text-right">
        <a
          v-if="signedURL"
          :href="signedURL"
          class="download-btn"
          download
          target="_blank"
          >{{ $t("qrAccess.busRoutes.currentDownload") }}</a
        >
      </div>
    </div>
    <!-- File input - csv -->
    <div class="input-container">
      <FileUploader
        :placeholder="$t('qrAccess.busRoutes.file_ph')"
        :type="'.xlsx'"
        :label="$t('qrAccess.busRoutes.browse')"
        :fileUrl="fileUrl"
        @setFile="setFile"
      />
      <div class="error-msg">
        <span v-if="$v.file.$error">{{ $t("qrAccess.busRoutes.errors.file_required") }}</span>
      </div>
    </div>
    <!-- Drag & drop file -->
    <!-- <div
    v-if="!file"
    class="drop-container"
    v-on:dragover.prevent
    v-on:drop.prevent="dropGetFile">
      {{$t('map.transport.stops.dropFile')}}
    </div>-->
    <div class="uploadInfo">{{ uploadStatus }}</div>
    <div class="row">
      <div class="col-md-12 text-right">
        <button
          type="button"
          class="btn btn-dark control publish-btn"
          :disabled="isLoading"
          @click="validateForm"
        >
          {{ $t("qrAccess.busRoutes.upload") }}
        </button>
      </div>
    </div>

    <!-- Menu table -->
    <div class="row">
      <div class="col-md-12">
        <div class="menu-table">
          <v-client-table
            v-if="table_columns"
            :columns="table_options.columns"
            :options="table_options.options"
            :data="busRoutes"
          >
          </v-client-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { required } from "vuelidate/lib/validators";
  import moment from "moment";
  import S3_Handler from "@/lib/api/s3_handler";
  
  import API_SERVICE from "@/lib/api/QRAccess.js";
  const api = new API_SERVICE();

  const FileUploader = () => import("@/components/FileUploader");
  const s3 = new S3_Handler();
  // import MENU_API from "@/lib/api/Menu.js";
  // const api = new MENU_API();

  export default {
    components: {
      FileUploader
    },
    filters: {
      formatDateOnly: function(value, lang) {
        if (!value) return "";
        if (lang == "es") {
          moment.locale("es-do");
        } else {
          moment.locale("en");
        }
        return moment(value).format("L");
      }
    },
    data: function() {
      return {
        isLoading: false,
        file: null,
        fileUrl: null,
        uploadStatus: "",
        busRoutes: null,
        signedURL: null
      };
    },
    computed: {
      appLang: function() {
        return this.$store.getters["settings/currentLang"];
      },
      table_columns: function() {
        if (this.busRoutes) return Object.keys(this.busRoutes[0]);
        return null;
      },
      table_headings: function() {
        if (this.table_columns) {
          const headings = {};
          this.table_columns.forEach(column => {
            headings[column] = this.$t(`qrAccess.busRoutes.table.headings.${column}`);
          });
          return headings;
        }
        return null;
      },
      table_options: function() {
        return {
          columns: this.table_columns,
          options: {
            texts: {
              filter: `${this.$t("components.vue_tables.filter")}:`,
              filterPlaceholder: this.$t("components.vue_tables.filter_ph")
            },
            headings: this.table_headings,
            sortable: ["date"],
            sortIcon: {
              base: "fas",
              up: "fa-sort-up",
              down: "fa-sort-down",
              is: "fa-sort"
            },
            perPage: 10,
            resizableColumns: false,
            perPageValues: [10, 25, 50, 100]
          }
        };
      }
    },
    validations: {
      file: {
        required
      }
    },
    beforeMount: async function() {
      await this.getAllRoutes();
      await this.getFile();
    },
    methods: {
      getAllRoutes: async function() {
        this.isLoading = true;
        this.$store.commit("global/SET_LOADING", true);
        try {
          const resp = await api.getAllRoutes();
          this.busRoutes = resp.busRoutes;
          this.isLoading = false;
          this.$store.commit("global/SET_LOADING", false);
        } catch (error) {
          this.isLoading = false;
          this.$store.commit("global/SET_LOADING", false);
          throw error;
        }
      },
      setFile: function(file) {
        this.file = file;
        if (this.file) {
          this.uploadStatus = "Ready to upload";
        } else {
          this.uploadStatus = "";
        }
      },
      dropGetFile: function(event) {
        const file = event.dataTransfer.files[0];
        this.file = file;
        this.fileUrl = file.name;
        this.uploadStatus = "Ready to upload";
      },
      validateForm: async function() {
        this.$v.$touch();
        if (this.$v.$error) {
          return;
        }
        await this.uploadFile();
      },
      async getFile() {
        this.$store.commit("global/SET_LOADING", true);
        try {
          const response = await api.getBusRoutesFiles();
          this.fileUrl = response[0].source;
          const urlResp = s3.getSignedUrl(this.fileUrl);
          this.signedURL = urlResp;
        } catch (error) {
          console.log(error);
          this.$store.commit("global/SET_LOADING", false);
        } finally {
          this.$store.commit("global/SET_LOADING", false);
        }
      },
      uploadFile: async function() {
        this.isLoading = true;
        this.$store.commit("global/SET_LOADING", true);
        this.uploadStatus = "Uploading...";
        try {
          const timestamp = Date.now();
          const fileResp = await s3.uploadFile(
            `bus_routes/${timestamp}_bus_routes.xlsx`,
            this.file
          );
          const { Bucket, Key } = fileResp;
          await api.postBusRoutesFile(fileResp.Location, "bus_routes");
          await api.uploadBusRoutes(Bucket, Key);
          this.uploadStatus = "File uploaded";
          this.$store.commit("global/SET_LOADING", false);
          this.isLoading = false;
          this.file = null;
          this.fileUrl = null;
          this.$v.$reset();
          await this.getAllRoutes();
        } catch (error) {
          this.uploadStatus = error;
          this.$store.commit("global/SET_LOADING", false);
          this.isLoading = false;
          throw error;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  #bus-routes-upload {
    background-color: $white;
    padding: 2rem 4rem;
  }

  .input-container {
    width: 40%;
  }

  .error-msg {
    color: $warn_red;
    font-size: 14px;
  }

  .drop-container,
  .uploadInfo {
    width: 80%;
    height: 6rem;
    background-color: $w_2;
    border: 1px solid $wg_2;
    align-self: center;
    margin: 3rem 0;
    padding: 1rem;
  }
  .drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadInfo {
    overflow-y: scroll;
  }

  .publish-btn {
    align-self: flex-end;
    background: #000;
    border-radius: 0px;
    padding: 0.5rem 1rem;
  }

  .menu-table {
    margin-top: 3rem;
  }
  .download-btn {
    background-color: $black;
    color: $white;
    border-radius: 0;
    padding: 0.5rem 2rem;
    align-self: flex-end;
  }
  .download-btn:hover {
    color: $white;
  }
  </style>
  <style>
  .form-inline label {
    display: initial;
  }
  nav.text-center > ul > li > a {
      color: #333333;
  }
  .page-item.disabled .page-link {
      color: #333333;
  }
</style>
