<template>
  <div id="calendar-list">
    <!-- Header -->
    <header>
      <h1>{{ $t("calendar.list.title") }}</h1>
    </header>
    <!-- PDF file -->
    <calendarFile />
    <!-- List table -->
    <main class="list-table">
      <button class="main-btn new-btn" @click="newModalActive = true">
        {{ $t("calendar.list.new") }}
      </button>
      <!-- Filters -->
      <div class="filter-controls">
        <!-- Type -->
        <div class="input-container">
          <label for="type-filter">{{
            $t("calendar.list.filters.type")
          }}</label>
          <select name="type-filter" id="type-filter" v-model="filters.type">
            <option>5x2</option>
            <option>6x1</option>
          </select>
        </div>
        <!-- Month -->
        <div class="input-container">
          <label for="month-filter">{{
            $t("calendar.list.filters.month")
          }}</label>
          <select name="month-filter" id="month-filter" v-model="filters.month">
            <option v-for="i in 12" :key="i">{{ i }}</option>
          </select>
        </div>
        <!-- Year -->
        <div class="input-container">
          <label for="year-filter">{{
            $t("calendar.list.filters.year")
          }}</label>
          <select name="year-filter" id="year-filter" v-model="filters.year">
            <option
              v-for="i in new Date().getFullYear() - 2018 + 1"
              :key="i"
              :value="i + 2018"
              >{{ i + 2018 }}</option
            >
          </select>
        </div>
        <button class="alt-btn" @click="getEvents">
          {{ $t("calendar.list.filters.filter") }}
        </button>
      </div>
      <v-client-table
        v-if="events"
        :columns="table_options.columns"
        :options="table_options.options"
        :data="events"
      >
        <!-- Event Type -->
        <template #category="props">
          <span>{{ props.row.category[category_lang] }}</span>
        </template>
        <!-- Actions -->
        <template v-slot:actions="props">
          <div class="actions">
            <!-- Edit -->
            <i class="far fa-edit" @click="openEditModal(props.row)"></i>
            <!-- Delete -->
            <i class="far fa-trash-alt" @click="openDeleteModal(props.row)"></i>
          </div>
        </template>
      </v-client-table>
    </main>
    <!-- Modal -->
    <Modal v-if="modalActive" v-on:closeModal="closeModal">
      <!-- Modal title -->
      <template #header>
        <span class="title">{{ modalTitle }}</span>
      </template>
      <!-- Modal - form body -->
      <template>
        <p class="delete-body" v-if="deleteModalActive">
          {{ $t("calendar.modal.delete_warning") }}
        </p>
        <div class="form-container" v-else>
          <!-- type -->
          <div class="input-container">
            <label for="type">{{ $t("calendar.form.type") }}</label>
            <select name="type" id="type" v-model="$v.form.type.$model">
              <option disabled :value="null">{{
                $t("calendar.form.type_ph")
              }}</option>
              <option>5x2</option>
              <option>6x1</option>
            </select>
            <div class="error-msg">
              <span v-if="$v.form.type.$error">{{
                $t("calendar.errors.type_required")
              }}</span>
            </div>
          </div>
          <!-- Date -->
          <div class="input-container">
            <label for="date">{{ $t("calendar.form.date") }}</label>
            <input
              type="date"
              name="date"
              id="date"
              min="2019-01-01"
              :max="`${new Date().getFullYear() + 1}-12-31`"
              v-model="$v.form.date.$model"
            />
            <div v-if="$v.form.date.$error" class="error-msg">
              <p v-if="!$v.form.date.required">
                {{ $t("calendar.errors.date_required") }}
              </p>
              <p v-if="!$v.form.date.isValid">
                {{ $t("calendar.errors.date_invalid") }}
              </p>
              <p v-if="!$v.form.date.format">
                {{ $t("calendar.errors.date_format") }}
              </p>
            </div>
          </div>
          <!-- Category -->
          <div class="input-container">
            <label for="category">{{ $t("calendar.form.category") }}</label>
            <select
              v-if="categories"
              name="category"
              id="category"
              v-model="$v.form.category.$model"
            >
              <option disabled :value="null">{{
                $t("calendar.form.category_ph")
              }}</option>
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id.toString()"
                >{{ category[category_lang] }}</option
              >
            </select>
            <div class="error-msg">
              <span v-if="$v.form.category.$error">{{
                $t("calendar.errors.category_required")
              }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Modal footer -->
      <template #footer>
        <div class="modal-actions">
          <span class="cancel-btn" @click="closeModal">{{
            $t("calendar.modal.cancel")
          }}</span>
          <button
            type="button"
            class="main-btn delete-btn"
            v-if="deleteModalActive"
            :disabled="isLoading"
            @click="deleteEvent"
          >
            {{ $t("calendar.modal.delete") }}
          </button>
          <button
            type="button"
            class="main-btn save-btn"
            v-else
            :disabled="isLoading"
            @click="validateForm"
          >
            {{ $t("calendar.modal.save") }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import CALENDAR_API from "@/lib/api/Calendar.js";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import { ClientTable as CustomTable } from "vue-tables-2";
const calendarFile = () => import("./CalendarFile.vue");
const Modal = () => import("@/components/Modal");
const api = new CALENDAR_API();

export default {
  components: {
    CustomTable,
    calendarFile,
    Modal
  },
  data: function() {
    return {
      newModalActive: false,
      editModalActive: false,
      deleteModalActive: false,
      categories: null,
      events: null,
      form: {
        type: null,
        date: null,
        category: null
      },
      filters: {
        type: "5x2",
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      },
      eventSelected: null
    };
  },
  computed: {
    category_lang: function() {
      let lang = this.$store.getters["settings/currentLang"];
      return `name_${lang}`;
    },
    isLoading: function() {
      return this.$store.getters["global/isLoading"];
    },
    table_options: function() {
      return {
        columns: ["category", "date", "actions"],
        options: {
          texts: {
            filter: `${this.$t("components.vue_tables.filter")}:`,
            filterPlaceholder: this.$t("components.vue_tables.filter_ph")
          },
          headings: {
            category: this.$t("calendar.list.table.category"),
            date: this.$t("calendar.list.table.date"),
            actions: this.$t("calendar.list.table.actions")
          },
          sortable: ["category", "date"],
          resizableColumns: false,
          filterable: ["category", "date"],
          sortIcon: {
            base: "fas",
            up: "fa-sort-up",
            down: "fa-sort-down",
            is: "fa-sort"
          },
          columnsClasses: {
            category: "category-column",
            date: "date-column",
            actions: "actions-column"
          }
        }
      };
    },
    modalActive: function() {
      if (this.newModalActive || this.editModalActive || this.deleteModalActive)
        return true;
      return false;
    },
    modalTitle: function() {
      if (this.newModalActive) return this.$t("calendar.new.title");
      if (this.editModalActive) return this.$t("calendar.edit.title");
      if (this.deleteModalActive) return this.$t("calendar.delete.title");
      return "";
    }
  },
  validations: {
    form: {
      type: {
        required
      },
      date: {
        required,
        format: function(value) {
          if (!value) return true;
          return moment(value, "YYYY-MM-DD", true).isValid();
        },
        isValid: function(value) {
          if (!value) return true;
          return (
            !moment(value, "YYYY-MM-DD", true).isBefore("2019-01-01") &&
            !moment(value, "YYYY-MM-DD", true).isAfter(
              `${new Date().getFullYear() + 1}-12-31`
            )
          );
        }
      },
      category: {
        required
      }
    }
  },
  beforeMount: async function() {
    this.$store.commit("global/SET_LOADING", true);
    await this.getCategories();
    await this.getEvents();
    this.$store.commit("global/SET_LOADING", false);
  },
  methods: {
    getCategories: async function() {
      try {
        const resp = await api.getCategories();
        this.categories = resp.categories;
      } catch (error) {
        throw error;
      }
    },
    getEvents: async function() {
      try {
        const resp = await api.getEvents(
          this.filters.type,
          this.filters.month,
          this.filters.year
        );
        // this.events = resp.currMonth;
        this.events = resp.currMonth.map(event => {
          event.category = this.categories.find(
            category => category.id == event.category
          );
          return event;
        });
      } catch (error) {
        throw error;
      }
    },
    openEditModal: function(event) {
      this.editModalActive = true;
      this.eventSelected = event;
      this.form.type = this.filters.type;
      this.form.date = event.date;
      this.form.category = event.category.id;
    },
    openDeleteModal: function(event) {
      this.deleteModalActive = true;
      this.eventSelected = event;
    },
    closeModal: function() {
      this.newModalActive = false;
      this.editModalActive = false;
      this.deleteModalActive = false;
      this.eventSelected = null;
      this.resetForm();
      this.$v.$reset();
    },
    resetForm: function() {
      for (let prop in this.form) {
        this.form[prop] = null;
      }
    },
    validateForm: async function() {
      this.$v.$touch();
      if (this.$v.$error) return;
      await this.saveEvent();
    },
    saveEvent: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const eventId = this.eventSelected ? this.eventSelected.id : null;
        if (typeof this.form !== "string")
          this.form.category = this.form.category.toString();
        await api.saveEvent(eventId, this.form);
        this.closeModal();
        await this.getEvents();
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    deleteEvent: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        await api.deleteEvent(this.eventSelected.id);
        this.closeModal();
        await this.getEvents();
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-btn {
  @extend %audi;
  color: $white;
  background-color: $black;
  border-color: $black;
  padding: 10px 20px;
  border-radius: 0px;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
}
.new-btn {
  margin: 1rem 0;
}
.alt-btn {
  @extend %audi-b;
  color: black;
  background-color: transparent;
  border: 1px solid black;
  padding: 5px 10px;
}
.cancel-btn {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.title {
  @extend %audi-ext-b;
  font-size: 1.2rem;
}

.list-table {
  margin: 1rem 0;

  .filter-controls {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
  .filter-controls > * {
    margin-right: 1rem;
  }

  .actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .actions > * {
    cursor: pointer;
  }
}

.form-container {
  .input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .input-container > label {
    font-weight: normal;
  }
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modal-actions > * {
  margin-left: 1rem;
}

.error-msg {
  color: $warn_red;
  font-size: 14px;

  > p {
    margin: 0;
  }
}
</style>
<style>
.form-inline label {
  display: initial;
}
</style>
