import axios from "axios";

export default class FAQ {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
    // this.API_LINK = 'http://localhost:8032'
  }
  // Travel endpoints start
  async getAllTravels(params = {}) {
    try {
      let keys = Object.keys(params);
      const queryString = Object.keys(params.query).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params.query[key])
      }).join('&');

      const requestOptions = {
        method: "get",
        url: `${this.API_LINK}/qrAccess/travel?page=${params.page}&limit=${params.limit}&${queryString}`
      }

      const response = await axios(requestOptions);

      // console.log(JSON.stringify({ params: params, requestOptions: requestOptions, response: response.data }, null, 4))
      return response.data;
    } catch (error) {
      console.log('Error on request ', error)
      throw error;
    }
  }
  async detailTravel(params = {}) {
    try {
      const requestOptions = {
        method: "get",
        url: `${this.API_LINK}/qrAccess/travel/${params.id}`,
      };

      const response = await axios(requestOptions);

      return response.data;
    } catch (error) {
      console.log('Error trying to get detail travel ', error);
      throw error;
    }
  }
  async downloadTravelsTable(params = {}) {
    try {
      let queryParams = {};
      let keys = Object.keys(params.query);
      for(let i = 0; i < keys.length; i++){
        if(params.query[keys[i]].length > 0) {
          queryParams[keys[i]] = params.query[keys[i]];
        }
      }

      queryParams.excel = true;
      const queryString = Object.keys(queryParams).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
      }).join('&');

      const requestOptions = {
        method: "get",
        url: `${this.API_LINK}/qrAccess/travel?page=${params.page-1}&limit=${params.limit}&${queryString}`
      }

      const response = await axios(requestOptions);
      return response.data;
    } catch (error) {
      console.log('Error on request ', error)
      throw error;
    }
  }
  // Travel endpoints end
  // Passenger endpoints start
  async getAllPassengers(params) {
    try {
      const { id, query } = params;

      const find = Object.keys(query).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params.query[key])
      }).join('&');

      const queryString = ['page', 'limit', 'ascending', 'orderBy'].map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      }).join('&');

      const requestOptions = {
        method: "get",
        url: `${this.API_LINK}/qrAccess/passengersByTravel/${id}?${queryString}&${find}`
      }

      const response = await axios(requestOptions);

      return response.data;
    } catch (error) {
      console.log('Error on request ', error)
      throw error;
    }
  }
  async downloadPassengersTable(params = {}, id) {
    try {
      let queryParams = {};
      let keys = Object.keys(params.query);
      for(let i = 0; i < keys.length; i++){
        if(params.query[keys[i]].length > 0) {
          queryParams[keys[i]] = params.query[keys[i]];
        }
      }

      queryParams.excel = true;
      const queryString = Object.keys(queryParams).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
      }).join('&');


      const requestOptions = {
        method: "get",
        url: `${this.API_LINK}/qrAccess/passengersByTravel/${id}?${queryString}`
      }

      const response = await axios(requestOptions);
      return response.data;
    } catch (error) {
      console.log('Error on request ', error)
      throw error;
    }
  }
  // Passenger endpoints end
  // Drivers Endpoints start
  async getAllDrivers(params) {
    try {
      const serialize = function(obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      };
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/qrAccess/busDriver?page=${params.page}&limit=${params.limit}&${serialize(params.query)}`
      });
      if(!response) {
        return {}
      }
      return response.data;
    } catch (error) {
      console.log('Error in request get all drivers ', error)
      throw error;
    }
  }
  async createDriver(params) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/qrAccess/busDriver`,
        data: params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async detailDriver(params) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/qrAccess/busDriver?controlNumber=${params.controlNumber}`,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async updateDriver(params) {
    try {
      const response = await axios({
        method: "put",
        url: `${this.API_LINK}/qrAccess/busDriver?controlNumber=${params.controlNumber}`,
        data: params
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async deleteDriver(params) {
    try {
      const response = await axios({
        method: "delete",
        url: `${this.API_LINK}/qrAccess/busDriver`,
        data: {
          controlNumber: params.controlNumber
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async downloadBusDriversTable(data = {}) {
    try {
      let queryParams = {};
      let keys = Object.keys(data);
      for(let i = 0; i < keys.length; i++){
        if(data[keys[i]].length > 0) {
          queryParams[keys[i]] = data[keys[i]];
        }
      }
      queryParams.excel = true;

      const queryString = Object.keys(queryParams).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
      }).join('&');

      const requestOptions = {
        method: "get",
        url: `${this.API_LINK}/qrAccess/busDriver?${queryString}`
      }

      const response = await axios(requestOptions);
      return response.data;
    } catch (error) {
      console.log('Error on request ', error)
      throw error;
    }
  }
  // Drivers Endpoints end
  // Bus routes Endpoints start
  async getAllRoutes() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/qrAccess/getAllRoutes`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async uploadBusRoutes(bucket, key) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/qrAccess/syncRoutes`,
        data: {
          srcBucket: bucket,
          src: key
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async postBusRoutesFile(url, fileName) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/files/`,
        data: {
          fileName: fileName,
          source: url
        }
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }
  async getBusRoutesFiles() {
    try {
      const requests = [
        axios({
          method: "get",
          url: `${this.API_LINK}/bin/files/bus_routes`
        })
      ];
      const response = await Promise.all(requests);
      return response.map(resp => resp.data);
    } catch (error) {
      throw error;
    }
  }
  async getQrReports(params) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/qrAccess/reports/${params.type}`,
        data: params
      });
      return response
    } catch (error) {
      throw error;
    }
  }
  // Bus routes Endpoints end
}
