<template>
  <div id="campaign-new">
    <div class="header">
      <div class="title">
        <h1 v-if="id">{{$t('ideas.campaign.edit.title')}}</h1>
        <h1 v-else>{{$t('ideas.campaign.new.title')}}</h1>
        <p>{{$t('ideas.campaign.new.directions')}}</p>
      </div>
    </div>
    <!-- Form -->
    <div class="form-container">
      <!-- Title -->
      <div class="input-container">
        <label for="title">{{$t("ideas.campaign.new.form.title")}}</label>
        <input
        type="text"
        id="title"
        name="title"
        :placeholder="$t('ideas.campaign.new.form.title_ph')"
        v-model="$v.form.title.$model">
        <div class="error-msg">
          <span v-if="$v.form.title.$error">{{$t("ideas.campaign.new.errors.title_required")}}</span>
        </div>
      </div>
      <!-- File input - img -->
      <div class="input-container">
        <label>{{$t("ideas.campaign.new.form.cover")}}</label>
        <FileUploader
        v-if="!campaignLoading"
        :placeholder="$t('ideas.campaign.new.form.cover_ph')"
        :type="'image/*'"
        :validate="'image/'"
        :label="$t('ideas.campaign.new.form.browse')"
        :fileUrl="imgUrl"
        @setFile="setFile"/>
        <div class="error-msg">
          <p v-if="$v.form.cover.$error">{{$t("ideas.campaign.new.errors.cover_required")}}</p>
        </div>
      </div>
      <!-- Valid -->
      <div class="valid">
        <!-- Start -->
        <label>{{$t("ideas.campaign.new.form.valid")}}</label>
        <div class="input-container">
          <label for="start">{{$t("ideas.campaign.new.form.start")}}</label>
          <input
          type="date"
          name="start"
          id="start"
          v-model="$v.form.start.$model">
          <div v-if="$v.form.start.$error" class="error-msg">
            <p v-if="!$v.form.start.required">{{$t("ideas.campaign.new.errors.start_required")}}</p>
            <p v-if="!$v.form.start.format">{{$t("ideas.campaign.new.errors.start_format")}}</p>
            <p v-if="!$v.form.start.isValid">{{$t("ideas.campaign.new.errors.start_valid")}}</p>
          </div>
        </div>
        <!-- End -->
        <div class="input-container">
          <label for="end">{{$t("ideas.campaign.new.form.end")}}</label>
          <input
          type="date"
          name="end"
          id="end"
          v-model="$v.form.end.$model">
          <div v-if="$v.form.end.$error" class="error-msg">
            <p v-if="!$v.form.end.required">{{$t("ideas.campaign.new.errors.end_required")}}</p>
            <p v-if="!$v.form.end.format">{{$t("ideas.campaign.new.errors.end_format")}}</p>
            <p v-if="!$v.form.end.isValid">{{$t("ideas.campaign.new.errors.end_valid")}}</p>
          </div>
        </div>
      </div>
      <!-- Description -->
      <div class="input-container">
        <label for="description">{{$t("ideas.campaign.new.form.shortDescription")}}</label>
        <div class="error-msg">
          <p v-if="$v.form.description.$error">{{$t("ideas.campaign.new.errors.shortdesc_required")}}</p>
        </div>
        <textarea
        name="description"
        id="description"
        :placeholder="$t('ideas.campaign.new.form.shortDescription_ph')"
        v-model="$v.form.description.$model">
        </textarea>
      </div>
      <!-- Wysiwyg -->
      <div class="input-container">
        <label for="body">{{$t("ideas.campaign.new.form.body")}}</label>
        <div v-if="$v.form.body.$error" class="error-msg">
          <p v-if="!$v.form.body.isRequired">{{$t("ideas.campaign.new.errors.body_required")}}</p>
        </div>
        <Wysiwyg
        v-if="!campaignLoading"
        :placeholder="$t('ideas.campaign.new.form.body_ph')"
        :articleBody="delta"
        v-on:updateBody="form.body = $event"></Wysiwyg>
      </div>
      <!-- Actions -->
      <div class="actions">
        <router-link class="cancel-btn" :to="{ name: 'Campaign List' }">{{$t('ideas.campaign.new.cancel')}}</router-link>
        <button
        type="button"
        class="main-btn publish-btn"
        :disabled="isLoading"
        @click="validateForm">{{$t('ideas.campaign.new.publish')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import IDEAS_API from "@/lib/api/Ideas";
import S3_Handler from "@/lib/api/s3_handler";
import { required } from "vuelidate/lib/validators";
const FileUploader = () => import("@/components/FileUploader");
const Wysiwyg = () => import("@/components/Wysiwyg");
const api = new IDEAS_API();
const s3 = new S3_Handler();

export default {
  components: {
    FileUploader,
    Wysiwyg
  },
  props: {
    id: {
      required: false,
      default: null
    }
  },
  data: function() {
    return {
      campaignLoading: true,
      form: {
        title: null,
        cover: null,
        start: null,
        end: null,
        description: null,
        body: null
      },
      imgUrl: null,
      delta: null
    };
  },
  computed: {
    isLoading: function() {
      return this.$store.getters["global/isLoading"];
    }
  },
  validations: {
    form: {
      title: {
        required
      },
      cover: {
        required
      },
      start: {
        required,
        format: function(value) {
          if (!value) return true;
          return moment(value, 'YYYY-MM-DD', true).isValid();
        },
        isValid: function(value) {
          if (!value) return true;
          if (!this.form.end) return true;
          return !moment(value).isAfter(this.form.end);
        }
      },
      end: {
        required,
        format: function(value) {
          if (!value) return true;
          return moment(value, 'YYYY-MM-DD', true).isValid();
        },
        isValid: function(value) {
          if (!value) return true;
          if (!this.form.start) return true;
          return !moment(value).isBefore(this.form.start);
        }
      },
      description: {
        required
      },
      body: {
        isRequired: function(value) {
          if (!value) return false;
          if (!value.hasOwnProperty("ops")) return false;
          if (value.ops.length == 1 && value.ops[0].insert === "\n") return false;
          return true;
        }
      }
    }
  },
  beforeMount: async function() {
    if (this.id) await this.getCampaign();
    this.campaignLoading = false;
  },
  methods: {
    getCampaign: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getCampaign(this.id);
        this.form.title = resp.title;
        this.form.cover = resp.cover;
        this.imgUrl = resp.cover;
        this.form.description = resp.description;
        this.delta = resp.body;
        this.form.body = resp.body;
        this.form.start = moment(resp.startsAt).format('YYYY-MM-DD');
        this.form.end = moment(resp.endsAt).format('YYYY-MM-DD');
        this.campaignLoading = false;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.campaignLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    setFile: function(file) {
      this.form.cover = file;
    },
    validateForm: async function() {
      this.$v.$touch();
      if (this.$v.$error) return;
      await this.saveCampaign();
    },
    saveCampaign: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        let s3Resp = null;
        if (this.form.cover && (typeof this.form.cover != "string")) {
          const coverName = `${Date.now()}.${this.form.cover.name.split(".").pop()}`;
          s3Resp = await s3.uploadFile(coverName, this.form.cover);
        }
        await api.saveCampaign({
          title: this.form.title,
          description: this.form.description,
          body: this.form.body,
          cover: s3Resp ? s3Resp.Location : this.imgUrl,
          startsAt: this.form.start,
          endsAt: this.form.end
        }, this.id);
        this.$store.commit("global/SET_LOADING", false);
        this.$router.push({
          name: "Campaign List"
        });
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  width: 90%;

  @media (min-width: 769px) {
    width: 65%;
  }
  @media (min-width: 1216px) {
    width: 55%;
  }
  @media (min-width: 1408px) {
    width: 45%;
  }
}
.valid {
  display: flex;
  font-size: 18px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.valid > label {
  @extend %audi-b;
  width: 100%
}
.valid > .input-container {
  width: 45%;
}
.valid > .input-container > label {
  @extend %audi;
}
.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  margin: 20px 0px;

  label {
    @extend %audi-b;
  }
}
#description {
  border: 1px solid $wg_1;
  width: 100%;
  background-color: transparent;
  resize: none;
  color: $bg_1;
  padding: 0.5rem 1rem;
}
.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.actions > * {
  margin-left: 15px;
  font-size: 16px;
  cursor: pointer;
}
.cancel-btn {
  color: $black;
  &:hover {
    color: $black;
  }
}
.publish-btn {
  @extend %audi-b;
  color: $white;
  background-color: $black;
  padding: 10px 20px;
  border: 1px solid $black;
  border-radius: 0px;
}
.error-msg {
  color: $warn_red;
  font-size: 14px;
  p {
    margin: 0;
  }
}
</style>

<style lang="scss">
#campaign-new {
  .quill-editor .ql-editor {
    height: 30vh;
  }
}
</style>