<template>
  <div id="org-chart">
    <header>
      <h1>{{$t('users.chart.title')}}</h1>
    </header>
    <div v-for="(file, index) in $v.files.$each.$iter" :key="index" class="form-container">
      <div class="input-container">
        <FileUploader
          :id="index"
          :placeholder="$t(`users.chart.file.${index}`)"
          :type="'*'"
          :label="$t('users.chart.file.browse')"
          :fileUrl="file.$model.fileUrl"
          @setFile="setFile($event,index)"
        />
        <div v-if="file.file.$error" class="error-msg">
          <p v-if="!file.file.required">{{$t("users.chart.file.errors.required")}}</p>
          <p v-if="!file.file.validFileName">{{$t("users.chart.file.errors.valid_name")}}</p>
        </div>
      </div>
      <button
        :class="['upload-btn',
      { 'upload-btn--diabled': file.$model.loading }]"
        :disabled="file.$model.loading"
        @click="validateForm(index)"
      >{{$t("users.chart.file.upload")}}</button>
      <FileUploadAlert :name="file.$model.alert.name" :status="file.$model.alert.status" />
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import API_SERVICE from "@/lib/api/Users.js";
import S3_Handler from "@/lib/api/s3_handler";
const FileUploader = () => import("@/components/FileUploader");
const FileUploadAlert = () => import("@/components/alerts/FileUploadAlert.vue");
const api = new API_SERVICE();
const s3 = new S3_Handler();

export default {
  components: {
    FileUploader,
    FileUploadAlert
  },
  data: function() {
    return {
      files: {
        orgobj: {
          valName: "orgobj",
          file: null,
          fileUrl: null,
          loading: false,
          alert: {
            name: "orgobj",
            status: null
          }
        },
        orgver: {
          valName: "orgver",
          file: null,
          fileUrl: null,
          loading: false,
          alert: {
            name: "orgver",
            status: null
          }
        }
      }
    };
  },
  validations: {
    files: {
      $each: {
        file: {
          required,
          validFileName: function(value, obj) {
            if (!value) return true;
            return value.name.includes(obj.valName);
          }
        }
      }
    }
  },
  mounted: async function() {
    try {
      const requests = [
        api.getUploadStatus("/organizational/sync/orgobj", 'orgobj'),
        api.getUploadStatus("/organizational/sync/orgver", 'orgver')
      ];
      const responses = await Promise.all(requests);
      responses.forEach(resp => {});
      if (!responses[0].data.status) {
        this.alert.status = "loading";
        await api.statusInterval("/users/sync");
        this.loadingFile = false;
        this.alert.status = "success";
      } else if(!responses[1].data.status) {
        this.alert.status = "loading";
        await api.statusInterval("/users/sync");
        this.loadingFile = false;
        this.alert.status = "success";
      } else {
        this.loadingFile = false;
        this.alert.status = null;
      }
    } catch (error) {
      throw error;
    }
  },
  beforeDestroy: function() {
    this.alert.status = null;
  },
  methods: {
    setFile: function(file, fileIndex) {
      this.files[fileIndex].file = file;
    },
    validateForm: async function(fileIndex) {
      this.$v.files.$each[fileIndex].$touch();
      if (this.$v.files.$each[fileIndex].$error) return;
      await this.uploadFile(fileIndex);
    },
    uploadFile: async function(fileIndex) {
      this.files[fileIndex].loading = true;
      try {
        const timestamp = Date.now();
        const fileResp = await s3.uploadFile(
          `${timestamp}_${fileIndex}`,
          this.files[fileIndex].file
        );
        const { Bucket, Key } = fileResp;
        this.files[fileIndex].alert.status = "loading";
        await api.uploadChart(`/organizational/sync/${fileIndex}`, Bucket, Key);
        this.files[fileIndex].alert.status = "success";
        this.files[fileIndex].loading = false;
        this.files[fileIndex].file = null;
        this.files[fileIndex].fileUrl = null;
        this.$v.files.$each[fileIndex].$reset();
      } catch (error) {
        this.files[fileIndex].alert.status = "error";
        this.files[fileIndex].loading = false;
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  margin-bottom: 2rem;
}
.form-container {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.upload-btn {
  @extend %audi;
  color: $black;
  background-color: transparent;
  border: 1px solid $black;
  border-radius: 0;
  padding: 5px 10px;
  margin-left: 2rem;
  transition: all 0.3s;
  &.upload-btn--diabled {
    color: $wg_1;
    border: 1px solid $wg_1;
  }
}
.error-msg {
  color: $warn_red;
}
.error-msg > p {
  margin: 0;
}
</style>