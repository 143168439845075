<template>
  <div id="blog-details">
    <!-- Back Button -->
    <router-link class="back-btn" :to="{ name: 'Blog List' }">
      <i class="fas fa-angle-left"></i>&nbsp;
      <strong>{{$t('news.details.back_btn')}}</strong>
    </router-link>
    <!-- Header -->
    <header class="header">
      <div class="title">
        <h1>{{$t('news.details.title')}}</h1>
      </div>
      <!-- Download Button -->
      <!-- <button
      type="button"
      class="btn btn-dark control download-btn"
      @click="downloadReport">{{$t('news.details.download')}}</button>-->
    </header>
    <!-- Post Details -->
    <section v-if="post" class="post-details">
      <header class="main-data">
        <div class="data">
          <!-- Post title -->
          <!-- <h2>{{postLangContent.title}}</h2> -->
          <h2>{{post.title}}</h2>
          <!-- Comments disabled -->
          <div v-if="post.enableComments === false" class="report-warning">
            <i class="fas fa-exclamation-triangle"></i>&nbsp;
            <span>{{$t('news.details.comments_disabled')}}</span>
          </div>
          <!-- Report warning -->
          <div v-if="postHasReports" class="report-warning">
            <i class="fas fa-exclamation-triangle"></i>&nbsp;
            <span>{{$t('news.details.report_warning')}}</span>
          </div>
          <!-- Created -->
          <div class="post-data">
            <strong>{{$t("news.details.post.date")}}</strong>
            : {{post.created_at | formatDate(appLang)}}
          </div>
          <!-- Likes -->
          <div class="post-data">
            <strong>{{$t("news.details.post.likes")}}</strong>
            : {{post.likes}}
          </div>
          <!-- Comments -->
          <div class="post-data">
            <strong>{{$t("news.details.post.comments")}}</strong>
            : {{post.comments.length}}
          </div>
        </div>
        <!-- Edit Post -->
        <router-link
          class="edit-link"
          :to="{
          name: 'Blog Edit',
          params: {
            id: id
          }
        }"
        >
          <strong>{{$t("news.details.post.edit")}}</strong>&nbsp;
          <i class="fas fa-angle-right"></i>
        </router-link>
      </header>
      <!-- Comment List -->
      <div class="comment-list">
        <ul>
          <li v-for="(comment, index) in post.comments" :key="index">
            <!-- Comment Body -->
            <p class="comment-body">{{comment.body}}</p>
            <!-- Comment Metadata -->
            <div class="comment-data">
              <p>
                {{comment.author}}
                <br />
                {{$t('screenshots.table.headings.controlNumber')}}: {{comment.controlNumber}}
                <br />
                {{comment.createdAt | formatDate(appLang)}}
              </p>
              <div class="icons">
                <div>
                  <span v-if="comment.reported">
                    <i class="fas fa-exclamation-triangle"></i>
                    &nbsp;
                    {{$t('news.details.report_label')}}
                  </span>
                </div>
                <div>
                  <span v-if="comment.published === false">
                    <i class="fas fa-exclamation-triangle"></i>
                    &nbsp;
                    {{$t('news.details.deleted_label')}}
                  </span>
                </div>
                <i v-if="comment.published" class="far fa-trash-alt" @click="openModal(comment.id)"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <!-- Modal -->
    <Modal v-if="modalActive" @closeModal="closeModal">
      <template #header>
        <h6 class="modal-title">{{$t('news.details.modal.title')}}</h6>
      </template>
      <template>
        <strong>{{$t('news.details.modal.body')}}</strong>
      </template>
      <template #footer>
        <div class="controls">
          <span class="control cancel-btn" @click="closeModal">{{$t('news.details.modal.cancel')}}</span>
          <button
            type="control button"
            class="btn btn-dark control delete-btn"
            :disabled="isLoading"
            @click="deleteComment"
          >{{$t('news.details.modal.delete')}}</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import API_Service from "@/lib/api/News.js";
const Modal = () => import("@/components/Modal");

export default {
  components: {
    Modal,
  },
  props: {
    id: {
      required: true,
    },
  },
  data: function () {
    return {
      post: null,
      commentToDelete: null,
      modalActive: false,
      isLoading: false,
    };
  },
  computed: {
    appLang: function () {
      return this.$store.getters["settings/currentLang"];
    },
    postLangContent: function () {
      if (this.post) {
        return this.post[this.appLang];
      } else {
        return null;
      }
    },
    postHasReports: function () {
      let reportComments = this.post.comments.filter(
        (comment) => comment.reported
      );
      return reportComments.length ? true : false;
    },
  },
  watch: {
    appLang: async function (newValue) {
      await this.getPost();
    },
  },
  beforeMount: async function () {
    await this.getPost();
  },
  methods: {
    getPost: async function () {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      try {
        const response = await api.getPost(this.id, this.appLang);
        this.post = response;
        this.fileLoading = false;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    downloadReport: async function () {},
    openModal: function (commentId) {
      this.commentToDelete = commentId;
      this.modalActive = true;
    },
    closeModal: function () {
      this.commentToDelete = null;
      this.modalActive = false;
    },
    deleteComment: async function () {
      this.$store.commit("global/SET_LOADING", true);
      this.isLoading = true;
      const api = new API_Service();
      try {
        await api.deleteComment(this.commentToDelete);
        this.isLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        this.closeModal();
        this.getPost();
      } catch (error) {
        this.isLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  color: $black;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  display: inline-block;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.header {
  display: flex;
  justify-content: space-between;
}
.header .download-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  padding: 0.5rem 2rem;
}
.post-details {
  margin: 3rem 0;
  padding: 2rem 4rem;
  border: 1px solid $wg_2;

  .main-data {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .data .report-warning {
    background-color: $w_1;
    border: 1px solid $wg_2;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin: 1rem 0;
  }
  .edit-link {
    text-transform: uppercase;
    color: $black;
    transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

    &:hover {
      color: $black;
    }
  }

  .comment-list {
    margin-top: 2rem;
  }
  .comment-list > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .comment-list > ul > li {
    padding: 1rem 0;
    border-bottom: 1px solid $wg_2;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 5fr 1fr;
    grid-template-areas: "body data";
    &:last-child {
      border-bottom: none;
    }

    p {
      margin: 0;
    }

    .comment-body {
      grid-area: body;
    }
    .comment-data {
      grid-area: data;
    }
    .comment-data > p {
      margin-bottom: 0.5rem;
    }
    .comment-data > .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .comment-data > .icons > i {
      cursor: pointer;
    }
    .comment-data > .icons span {
      display: flex;
      align-items: center;
      background-color: $w_1;
      border: 1px solid $wg_2;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }
}

.modal-title {
  margin: 0;
}
.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.controls .control {
  margin-left: 15px;
  font-size: 16px;
  cursor: pointer;
}
.control.cancel-btn {
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    text-decoration: underline;
  }
}
.control.delete-btn {
  @extend %audi-b;
  color: $white;
  background-color: $black;
  border-color: $black;
  padding: 10px 20px;
  border-radius: 0px;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    background-color: $warn_red;
    border-color: $warn_red;
  }
}
</style>