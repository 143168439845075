<template>
  <div class="driver">
    <div class="row bg-white">
      <div class="col-md-12 top py-2 px-4">
        <router-link
          class="return"
          :to="{
            name: 'Trip Details',
            params: {
              id: this.id,
              travelStatus: this.data.travelStatus,
              data: this.data,
              details: this.details,
              tripDone: this.tripDone,
              onGoingOrDone: this.onGoingOrDone,
            }
          }"
        >
          <i class="fas fa-chevron-left"></i>
          {{ $t("events.newEvent.back") }}
        </router-link>
      </div>
      <div class="col-md-12 px-5 py-2">
        <h2 class="labelBold">
          {{ this.$t(`qrAccess.travels.driverDetail.title`) }}
        </h2>
        <div class="driverInfo py-4">
          <label class="labelBold">
            {{ this.$t(`qrAccess.travels.driverDetail.name`) }}
          </label>
          <p>{{this.name}}</p>
          <label class="labelBold py-2">
            {{ this.$t(`qrAccess.travels.driverDetail.lastName`) }}
          </label>
          <p>{{this.lastName}}</p>
          <label class="labelBold py-2">
            {{ this.$t(`qrAccess.travels.driverDetail.motherLastName`) }}
          </label>
          <p>{{this.mothersLastName}}</p>
          <label class="labelBold py-2">
            {{ this.$t(`qrAccess.travels.driverDetail.company`) }}
          </label>
          <p>{{this.company}}</p>
          <label class="labelBold py-2">
            {{ this.$t(`qrAccess.travels.driverDetail.idProvider`) }}
          </label>
          <p>{{this.providerNumber}}</p>
          <!-- <label class="labelBold py-2">
            {{ this.$t(`qrAccess.travels.driverDetail.employeeNumber`) }}
          </label>
          <p>0000 000</p> -->
          <label class="labelBold py-2">
            {{ this.$t(`qrAccess.travels.driverDetail.uniqueId`) }}
          </label>
          <p>{{this.controlNumber}}</p>
          <label class="labelBold py-2">
            {{ this.$t(`qrAccess.travels.driverDetail.phone`) }}
          </label>
          <p>{{this.phone}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import API_SERVICE from "@/lib/api/QRAccess.js";
  const moment = require("moment");
  const api = new API_SERVICE();
  export default {
    props: {
      id: {
        type: Number
      },
      data: {
        type: Object
      },
      travelStatus: {
        type: String
      },
      onGoingOrDone: {
        type: Boolean,
        default: false,
      },
      tripDone: {
        type: Boolean,
        default: false,
      },
      details: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        name: "",
        lastName: "",
        mothersLastName: "",
        company: "",
        providerNumber: "",
        phone: "",
        controlNumber: ""
      };
    },
    async beforeMount() {
      await this.getDetailDriver();
      this.fillData();
    },
    // mounted() {
    //   // let currentLanguage = localStorage.getItem("lang");
    //   console.log('this.travel.checkinDate ', this.travel)
    //   console.log('this.travel.checkinDate ', JSON.stringify(this.travel))
    //   // moment.locale(currentLanguage);
    // },
    methods: {
      getDetailDriver: async function() {
        // let currentLanguage = localStorage.getItem("lang");
        this.$store.commit("global/SET_LOADING", true);
        try {
          const resp = await api.detailDriver(this.data);
          this.busDriver = resp.busDrivers;
        } catch (error) {
          console.log(error);
          this.$store.commit("global/SET_LOADING", false);
        } finally {
          this.$store.commit("global/SET_LOADING", false);
        }
      },
      fillData: function() {
        try {
          // let details = this.$route.params.details;
          let id = this.$route.params.id;
          this.name = this.busDriver[0].name;
          this.lastName = this.busDriver[0].lastName;
          this.mothersLastName = this.busDriver[0].mothersLastName;
          this.company = this.busDriver[0].companyName;
          this.providerNumber = this.busDriver[0].providerNumber;
          this.phone = this.busDriver[0].personalCellphoneNumber;
          this.controlNumber = this.busDriver[0].controlNumber;
        } catch (error) {
          console.log('Error in fillData: ', error);
        }
      }
    }
  };
</script>

<style >
  .return {
    color: black;
    cursor: pointer;
  }
  .return:hover {
    text-decoration: underline !important;
    color: black;
  }
  .labelBold {
    font-weight: bold;
    color: #000;
  }
</style>