<template>
  <div id="benefits-new">
    <!-- Back Button -->
    <router-link class="back-btn" :to="{ name: 'Benefits List' }">
      <i class="fas fa-angle-left"></i>&nbsp;
      <strong>{{$t('map.benefits.new.back_btn')}}</strong>
    </router-link>
    <!-- Header -->
    <header class="header">
      <div class="title">
        <h1>{{$t('map.benefits.new.title')}}</h1>
        <p>{{$t('map.benefits.new.directions')}}</p>
        <p class="bolded">{{$t('news.list.requiredFields')}}</p>
      </div>
      <!-- publish Button -->
      <button
        type="button"
        class="btn btn-dark control publish-btn"
        :disabled="isUploading"
        @click="validateForm"
      >{{$t('map.benefits.new.publish')}}</button>
    </header>
    <!-- Form -->
    <div class="form-container">
      <div class="input-container">
        <label for="name">{{$t("map.benefits.new.form.name")}}(*)</label>
        <input
          type="text"
          id="name"
          name="name"
          :placeholder="$t('map.benefits.new.form.name_ph')"
          v-model="$v.form.name.$model"
        />
        <div class="error-msg">
          <span v-if="$v.form.name.$error">{{$t("map.benefits.new.errors.name")}}</span>
        </div>
      </div>
      <!-- File input - img -->
      <div class="input-container">
        <label>{{$t("map.benefits.new.form.logo")}}</label>
        <FileUploader
          v-if="!fileLoading"
          :placeholder="$t('map.benefits.new.form.logo_ph')"
          :type="'image/*'"
          :validate="'image/'"
          :label="$t('map.benefits.new.form.browse')"
          :fileUrl="ImgUrl"
          @setFile="setFile"
        />
      </div>
      <!-- Categories -->
      <div class="input-container">
        <label for="category">{{$t('map.benefits.new.form.category')}}(*)</label>
        <vSelect
          v-if="categories"
          inputId="select-search-input"
          :loading="!categories"
          v-model="$v.form.idCategory.$model"
          :placeholder="$t('map.benefits.new.form.category_ph')"
          :options="categories"
          :label="categoryLabel"
          :reduce="category => category.id"
          :clearable="false"
        ></vSelect>
        <div class="error-msg">
          <span v-if="$v.form.idCategory.$error">{{$t("map.benefits.new.errors.category")}}</span>
        </div>
      </div>
    </div>
    <h2 class="benefits">{{$t('map.benefits.new.form.benefits')}}</h2>
    <!-- Tabs -->
    <div class="tabs">
      <ul>
        <li
          v-for="(benefit, index) in form.benefits"
          :key="index"
          :class="['tab', index == activeTab ? 'active' : '']"
          @click.self="changeTab(index)"
        >
          <span @click="changeTab(index)">
            {{form.benefits[index].shortDescription ||
            $t('map.benefits.new.form.tab_ph')}}
          </span>
          <i v-if="$v.form.benefits.$each[index].$anyError" class="fas fa-exclamation-triangle"></i>
          <i class="fas fa-times" @click="openModal(index)"></i>
        </li>
        <li class="tab add-btn" @click="createBenefit" :disabled="isBenefitEmpty">
          <span>{{$t('map.benefits.new.form.add')}}</span>
          <i class="fas fa-plus"></i>
        </li>
      </ul>
      <!-- Form -->
      <div class="benefit-form">
        <!-- Short Description -->
        <div class="input-container">
          <label
            :for="`short-description_${activeTab}`"
          >{{$t('map.benefits.new.form.short_description')}}(*)</label>
          <input
            type="text"
            :id="`short-description_${activeTab}`"
            :name="`short-description_${activeTab}`"
            :placeholder="$t('map.benefits.new.form.short_description_ph')"
            v-model="activeTabForm.shortDescription"
            v-on:input="validateIfBenefitEmpty"
          />
          <div class="error-msg">
            <span
              v-if="activeTabVal.shortDescription.$error"
            >{{$t("map.benefits.new.errors.short_description")}}</span>
          </div>
        </div>
        <!-- Restrictions -->
        <!-- <div class="input-container">
          <label :for="`restrictions_${activeTab}`">{{$t('map.benefits.new.form.restrictions')}}</label>
          <input
          type="text"
          :id="`restrictions_${activeTab}`"
          :name="`restrictions_${activeTab}`"
          :placeholder="$t('map.benefits.new.form.restrictions_ph')"
          v-model="activeTabForm.restrictions">
        </div>-->
        <!-- Wysiwyg -->
        <div class="wysiwyg-container">
          <label>{{$t("map.benefits.new.form.body")}}(*)</label>
          <div class="error-msg">
            <span v-if="activeTabVal.body.$error">{{$t("map.benefits.new.errors.body")}}</span>
          </div>
          <div class="wysiwyg">
            <Wysiwyg
              v-if="!fileLoading"
              :placeholder="$t('map.benefits.new.form.body_ph')"
              :articleBody="activeTabForm.delta"
              v-on:updateBody="activeTabVal.body.$model = $event"
            ></Wysiwyg>
          </div>
        </div>
        <!-- Location -->
        <div class="location">
          <h4>{{$t('map.benefits.new.form.location')}}</h4>
          <div v-if="activeTabVal.locations.$error" class="error-msg address-error">
            <p>{{$t("map.benefits.new.errors.address_req")}}</p>
          </div>
          <div class="input-container">
            <!-- Latitude -->
            <label :for="`latitude_${activeTab}`">{{$t('map.benefits.new.form.latitude')}}(*)</label>
            <input
              type="text"
              :id="`latitude_${activeTab}`"
              :name="`latitude_${activeTab}`"
              :placeholder="$t('map.benefits.new.form.latitude_ph')"
              v-model.number="activeTabVal.locations.$each[lastLocation].latitude.$model"
            />
            <div
              v-if="activeTabVal.locations.$each[lastLocation].latitude.$error"
              class="error-msg"
            >
              <p
                v-if="!activeTabVal.locations.$each[lastLocation].latitude.required"
              >{{$t("map.benefits.new.errors.latitude_req")}}</p>
              <p
                v-if="!activeTabVal.locations.$each[lastLocation].latitude.decimal"
              >{{$t("map.benefits.new.errors.latitude_num")}}</p>
            </div>
          </div>
          <div class="input-container">
            <!-- Longitude -->
            <label :for="`longitude_${activeTab}`">{{$t('map.benefits.new.form.longitude')}}(*)</label>
            <input
              type="text"
              :id="`longitude_${activeTab}`"
              :name="`longitude_${activeTab}`"
              :placeholder="$t('map.benefits.new.form.longitude_ph')"
              v-model.number="activeTabVal.locations.$each[lastLocation].longitude.$model"
            />
            <div
              v-if="activeTabVal.locations.$each[lastLocation].longitude.$error"
              class="error-msg"
            >
              <p
                v-if="!activeTabVal.locations.$each[lastLocation].longitude.isRequired"
              >{{$t("map.benefits.new.errors.longitude_req")}}</p>
              <p
                v-if="!activeTabVal.locations.$each[lastLocation].longitude.decimal"
              >{{$t("map.benefits.new.errors.longitude_num")}}</p>
            </div>
          </div>
          <div class="input-container address">
            <!-- Address -->
            <label :for="`address_${activeTab}`">{{$t('map.benefits.new.form.address')}}(*)</label>
            <input
              type="text"
              :id="`address_${activeTab}`"
              :name="`address_${activeTab}`"
              :placeholder="$t('map.benefits.new.form.address_ph')"
              v-model="activeTabVal.locations.$each[lastLocation].address.$model"
            />
            <div class="error-msg">
              <span
                v-if="activeTabVal.locations.$each[lastLocation].address.$error"
              >{{$t("map.benefits.new.errors.address")}}</span>
            </div>
          </div>
          <div class="input-container add-location">
            <i class="fas fa-plus" @click="addAddress"></i>
          </div>
        </div>
        <div
          v-for="(location, index) in activeTabForm.locations"
          :key="index"
          class="location added"
        >
          <div
            v-if="index < activeTabForm.locations.length - 1"
            class="input-container"
          >{{location.latitude}}</div>
          <div
            v-if="index < activeTabForm.locations.length - 1"
            class="input-container"
          >{{location.longitude}}</div>
          <div
            v-if="index < activeTabForm.locations.length - 1"
            class="input-container address"
          >{{location.address}}</div>
          <div
            v-if="index < activeTabForm.locations.length - 1"
            class="input-container remove-location"
          >
            <i class="fas fa-minus" @click="removeAddress(index)"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <Modal v-if="modalActive" @closeModal="closeModal">
      <template #header>
        <h6 class="modal-title">{{$t('map.benefits.list.modal.title')}}</h6>
      </template>
      <template>
        <strong>{{$t('map.benefits.list.modal.body')}}</strong>
      </template>
      <template #footer>
        <div class="controls">
          <span
            class="control cancel-btn"
            @click="closeModal"
          >{{$t('map.benefits.list.modal.cancel')}}</span>
          <button
            type="control button"
            class="btn btn-dark control delete-btn"
            @click="deleteBenefit"
          >{{$t('map.benefits.list.modal.delete')}}</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { required, decimal } from "vuelidate/lib/validators";
import API_Service from "@/lib/api/Benefits.js";
import S3_Handler from "@/lib/api/s3_handler";
const FileUploader = () => import("@/components/FileUploader");
const Wysiwyg = () => import("@/components/Wysiwyg");
const Modal = () => import("@/components/Modal");

export default {
  components: {
    FileUploader,
    Wysiwyg,
    Modal
  },
  props: {
    id: {
      required: false
    }
  },
  data: function() {
    return {
      categories: null,
      form: {
        name: null,
        logo: null,
        idCategory: null,
        benefits: [
          {
            shortDescription: null,
            // restrictions: null,
            body: null,
            delta: null,
            locations: [
              {
                longitude: null,
                latitude: null,
                address: null
              }
            ]
          }
        ]
      },
      ImgUrl: null,
      fileLoading: true,
      activeTab: 0,
      modalActive: false,
      benefitToDelete: null,
      isUploading: false,
      isBenefitEmpty: false
    };
  },
  computed: {
    categoryLabel: function() {
      if (this.$store.getters["settings/currentLang"] == "en") {
        return "nameEn";
      }
      return "nameEs";
    },
    activeTabForm: function() {
      return this.form.benefits[this.activeTab];
    },
    activeTabVal: function() {
      return this.$v.form.benefits.$each[this.activeTab];
    },
    lastLocation: function() {
      return this.activeTabForm.locations.length - 1;
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      idCategory: {
        required
      },
      benefits: {
        $each: {
          shortDescription: {
            required
          },
          body: {
            isRequired: function(data) {
              if (data) {
                if (data.hasOwnProperty("ops")) {
                  if (data.ops.length == 1 && data.ops[0].insert === "\n") {
                    return false;
                  } else {
                    return true;
                  }
                }
                return false;
              }
              return false;
            }
          },
          locations: {
            isRequired: function(data) {
              if (data.length < 2) {
                return false;
              }
              return true;
            },
            $each: {
              latitude: {
                decimal,
                isRequired: function(data) {
                  if (
                    (this.activeTabForm.locations[this.lastLocation]
                      .longitude ||
                      this.activeTabForm.locations[this.lastLocation]
                        .address) &&
                    !data
                  ) {
                    return false;
                  }
                  if (!this.activeTabForm.locations[0].latitude) {
                    return false;
                  }
                  return true;
                }
              },
              longitude: {
                decimal,
                isRequired: function(data) {
                  if (
                    (this.activeTabForm.locations[this.lastLocation].latitude ||
                      this.activeTabForm.locations[this.lastLocation]
                        .address) &&
                    !data
                  ) {
                    return false;
                  }
                  if (!this.activeTabForm.locations[0].longitude) {
                    return false;
                  }
                  return true;
                }
              },
              address: {
                isRequired: function(data) {
                  if (
                    (this.activeTabForm.locations[this.lastLocation].latitude ||
                      this.activeTabForm.locations[this.lastLocation]
                        .longitude) &&
                    !data
                  ) {
                    return false;
                  }
                  if (!this.activeTabForm.locations[0].address) {
                    return false;
                  }
                  return true;
                }
              }
            }
          }
        }
      }
    }
  },
  beforeMount: async function() {
    await this.getCategories();
    if (this.id) {
      await this.getBenefits();
    }
    this.fileLoading = false;
  },
  mounted() {
    this.validateIfBenefitEmpty();
  },
  methods: {
    validateIfBenefitEmpty() {
      for (let index = 0; index < this.benefits.length; index++) {
        for (
          let index2 = 0;
          index2 < this.benefits[index].locations.length;
          index2++
        ) {
          if (
            this.benefits[index].body === null ||
            this.benefits[index].shortDescription === null ||
            this.benefits[index].locations[index2].address === null ||
            this.benefits[index].locations[index2].latitude === null ||
            this.benefits[index].locations[index2].longitude === null ||
            this.benefits[index].body.ops[0].insert === "\n" ||
            this.benefits[index].post.body.ops[0].insert === "\n\n"
          ) {
            this.isBenefitEmpty = true;
          } else {
            this.isBenefitEmpty = true;
          }
        }
      }
    },
    getCategories: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      try {
        const response = await api.getCategories();
        this.categories = response;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    getBenefits: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      try {
        const response = await api.getBenefits(this.id);
        this.form.name = response.name;
        this.ImgUrl = response.logo;
        this.form.idCategory = response.category.id;
        this.form.benefits = response.benefits.map(benefit => {
          const object = {};
          object.shortDescription = benefit.shortDescription;
          // object.restrictions = benefit.restrictions;
          object.delta = benefit.body;
          object.body = benefit.description;
          object.locations = benefit.locations;
          object.locations.push({
            longitude: null,
            latitude: null,
            address: null
          });
          return object;
        });
        // this.fileLoading = false;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    setFile: function(file) {
      this.form.logo = file;
    },
    openModal: function(benefit) {
      this.benefitToDelete = benefit;
      this.modalActive = true;
    },
    closeModal: function() {
      this.benefitToDelete = null;
      this.modalActive = false;
    },
    changeTab: function(tabIndex) {
      this.activeTab = tabIndex;
      const tab = this.form.benefits[tabIndex];
      if (tab.body) {
        tab.delta = tab.body;
      } else if (tab.delta) {
        tab.delta = tab.delta;
      } else {
        tab.delta = {};
      }
    },
    deleteBenefit: function() {
      let index = this.benefitToDelete;
      this.form.benefits.splice(index, 1);
      if (!this.form.benefits.length) {
        this.createBenefit();
      } else if (this.activeTab > 0 && index <= this.activeTab) {
        this.changeTab(this.activeTab - 1);
      }
      this.closeModal();
    },
    createBenefit: function() {
      this.form.benefits.push({
        shortDescription: null,
        // restrictions: null,
        body: null,
        delta: null,
        locations: [
          {
            longitude: null,
            latitude: null,
            address: null
          }
        ]
      });
      this.changeTab(this.form.benefits.length - 1);
    },
    addAddress: function() {
      this.$v.form.benefits.$each[this.activeTab].locations.$touch();
      if (this.$v.form.benefits.$each[this.activeTab].locations.$each.$error) {
        return;
      }
      this.activeTabForm.locations.push({
        longitude: null,
        latitude: null,
        address: null
      });
    },
    removeAddress: function(addressIndex) {
      this.activeTabForm.locations.splice(addressIndex, 1);
      if (!this.activeTabForm.locations.length) {
        this.activeTabForm.locations.push({
          longitude: null,
          latitude: null,
          address: null
        });
      }
    },
    validateForm: async function() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.isUploading = true;
      await this.uploadBenefit();
    },
    uploadBenefit: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      const s3 = new S3_Handler();
      try {
        let s3Resp = null;
        if (this.form.logo) {
          const timestamp = Date.now();
          const logoName = `${timestamp}.${this.form.logo.name
            .split(".")
            .pop()}`;
          s3Resp = await s3.uploadFile(logoName, this.form.logo);
          this.form.logo = s3Resp.Location;
        } else {
          this.form.logo = this.ImgUrl;
        }
        const data = JSON.parse(JSON.stringify(this.form));
        data.benefits.forEach(benefit => {
          benefit.locations.pop();
        });
        await api.uploadBenefit(data);
        // this.categories = response;
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        this.$router.push({
          name: "Benefits List"
        });
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.back-btn {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  color: $black;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  display: inline-block;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.header {
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.header .publish-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  padding: 0.5rem 2rem;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid $wg_2;
  padding: 1rem 3rem;
  margin: 2rem 4rem;
}
.input-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  margin: 20px 0px;

  label {
    @extend %audi-b;
  }
}

.benefits {
  @extend %audi-ext-b;
}

.tabs {
  width: auto;
  margin: 2rem 4rem;
}
.tabs ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
  position: relative;
  z-index: 9;
  display: flex;
  overflow-x: auto;
}
.tabs .tab {
  @extend %audi-b;
  max-width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  border: 1px solid $wg_1;
  background-color: $w_1;
  cursor: pointer;
  &.active {
    background-color: $white;
    border-bottom-color: $white;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    margin-left: 10px;
  }
}
.tabs .tab.add-btn {
  color: $bg_3;
  @extend %audi;
}
.benefit-form {
  border: 1px solid $wg_1;
  position: relative;
  top: -1px;
  padding: 1rem 3rem;
  background-color: $white;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-around;
}

.benefit-form .input-container {
  width: 45%;
}
.benefit-form .wysiwyg-container {
  width: 95%;
}
.benefit-form .wysiwyg-container label {
  width: 95%;
  @extend %audi-b;
  font-size: 18px;
}
.location {
  width: 95%;
  margin-top: 2rem;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  h4 {
    @extend %audi-b;
    width: 100%;
  }
  .input-container {
    width: 20%;
  }
  .input-container.address {
    width: 45%;
  }
  .input-container.add-location {
    width: 5%;
    align-self: center;
    cursor: pointer;
  }
}
.location.added {
  margin: 0;
  .input-container {
    margin: 0.5rem 0;
  }
  .input-container.remove-location {
    width: 5%;
    align-self: center;
    cursor: pointer;
  }
}
.error-msg {
  color: $warn_red;
  font-size: 14px;
  p {
    margin: 0;
  }
}
.error-msg.address-error {
  width: 100%;
}
</style>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
#benefits-new {
  .v-select {
    width: 100%;
  }
  .vs__dropdown-toggle {
    color: $bg_1;
    border: none;
    border-bottom: 1px solid $wg_1;
    border-radius: 0;
  }
  #select-search-input {
    width: 0%;
    border: none;
  }
  .vs__dropdown-option:hover {
    background-color: $wg_3;
    color: $black;
  }
  .vs__dropdown-option--selected {
    background-color: $b_2;
    color: $white;
  }
  .vs__dropdown-option--highlight {
    background-color: $wg_3;
    color: $black;
  }
  .vs__actions {
    cursor: pointer;
  }
  .quill-editor .ql-editor {
    min-height: 20vh;
  }
}
</style>
