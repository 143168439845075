import axios from "axios";

export default class synctool {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getMetricStats() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/users/getActiveUsers?plainText=true`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async downloadMetricStats() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/users/getActiveUsers`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
