<template>
  <div id="user-new">
    <header>
      <h1 v-if="controlNumber">{{$t('users.manage.edit.title')}}</h1>
      <h1 v-else>{{$t('users.manage.new.title')}}</h1>
      <p>{{ $t("events.newEvent.requireFields") }}</p>
      <div class="actions">
        <router-link class="cancel-btn" :to="{name: 'User Manage'}">Cancelar</router-link>
        <button class="save-btn" @click="validateForm">{{$t('users.manage.new.save')}}</button>
      </div>
    </header>
    <div class="form-container">
      <div v-for="(field, index) in formData" :key="index" class="input-container">
        <label
          v-if="!field.hidden"
          :for="index">{{$t(`users.manage.table.headings.${index}`)}}
        </label>
        <input
          v-if="field.type === 'text' && !field.hidden"
          type="text"
          :name="index"
          :id="index"
          v-model="formData[index].value"
        />
        <input
          v-if="field.type === 'text' && field.hidden"
          type="text"
          :name="index"
          :id="index"
          v-model="formData[index].value"
          style="display: none"
        />
        <input
          v-if="field.type === 'number'"
          type="number"
          :name="index"
          :id="index"
          v-model.number="formData[index].value"
        />
        <input
          v-if="field.type === 'email'"
          type="email"
          :name="index"
          :id="index"
          v-model="formData[index].value"
        />
        <input
          v-if="field.type === 'date'"
          type="date"
          :name="index"
          :id="index"
          v-model="formData[index].value"
        />
        <select
          v-if="field.type === 'select'"
          :name="index"
          :id="index"
          v-model="formData[index].value"
        >
          <option :value="null">{{$t('users.manage.new.option_null')}}</option>
          <option
            v-for="(option, optIndex) in field.options"
            :key="optIndex"
            :value="option"
          >{{$t(`users.manage.new.${index}.${option}`)}}</option>
        </select>
        <!-- <div v-if="$v.formData.$each[index].$error" class="error-msg">
          <p v-if="$v.formData.$each[index].value.required === false">
            {{$t("users.manage.new.errors.required", { field: $t(`users.manage.table.headings.${index}`)})}}
          </p>
        </div>-->
        <div v-if="$v.formData[index]" class="error-msg">
          <div v-if="$v.formData[index].$error">
            <p
              v-if="$v.formData[index].value.required === false"
            >{{$t("users.manage.new.errors.required", { field: $t(`users.manage.table.headings.${index}`)})}}</p>
            <p
              v-if="$v.formData[index].value.numeric === false"
            >{{$t("users.manage.new.errors.numeric", { field: $t(`users.manage.table.headings.${index}`)})}}</p>
            <p
              v-if="$v.formData[index].value.email === false"
            >{{$t("users.manage.new.errors.email", { field: $t(`users.manage.table.headings.${index}`)})}}</p>
            <p
              v-if="$v.formData[index].value.minLength === false || $v.formData[index].value.maxLength === false"
            >{{$t("users.manage.new.errors.eq_chars", { field: $t(`users.manage.table.headings[${index}]`)})}}</p>
          </div>
        </div>
      </div>
      <div class="input-container">
        <label for="dateTerms" v-if ="termsAccepted === true">{{$t(`users.manage.table.headings.dateTerms`)}} Firmados el:</label>
        <label for="dateTerms" v-if ="termsAccepted === false">{{$t(`users.manage.table.headings.dateTerms`)}} Revocados el:</label>
        <input
          v-model="dateTerms"
          id="dateTerms"
          readonly
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  required,
  numeric,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import API_SERVICE from "@/lib/api/Users.js";

const api = new API_SERVICE();
const dateFormat = (value) => {
  if (!value) return true;
  return moment(value, "YYYY-MM-DD", true).isValid();
};

export default {
  props: {
    controlNumber: {
      required: false,
    },
  },
  data: function () {
    return {
      dateTerms: null,
      termsAccepted: false,
      formData: {
        controlNumber: {
          type: "text",
          value: null,
        },
        companyEmail: {
          type: "email",
          value: null,
        },
        name: {
          type: "text",
          value: null,
        },
        lastName: {
          type: "text",
          value: null,
        },
        mothersLastName: {
          type: "text",
          value: null,
        },
        gender: {
          type: "select",
          options: ["Masculino", "Femenino"],
          value: null,
        },
        birthdate: {
          type: "date",
          value: null,
        },
        status: {
          type: "select",
          options: ["Activo", "Dado de baja"],
          value: null,
        },
        workingShift: {
          type: "text",
          value: null,
        },
        dailyWorkingShift: {
          type: "text",
          value: null,
        },
        substitutionWorkingShift: {
          type: "text",
          value: null,
        },
        directBossControlNumber: {
          type: "text",
          value: null,
        },
        admissionDate: {
          type: "date",
          value: null,
        },
        maritalStatus: {
          type: "text",
          value: null,
        },
        funcion: {
          type: "text",
          value: null,
        },
        idUo: {
          type: "text",
          value: null,
        },
        numberOfChildren: {
          type: "number",
          value: null,
        },
        personalCellphoneNumber: {
          type: "text",
          value: null,
        },
        type: {
          type: "select",
          options: [
            "PdC",
            "Directo",
            "OMK",
            "MK",
            "PdC Temporal",
            "Directo Temporal",
            "EMA",
            "Ext",
            "Practicantes",
            "Impatriados",
            "Aprendiz"
          ],
          value: null,
        },
        typeDescription: {
          type: "text",
          value: null,
        },
        uo: {
          type: "text",
          value: null,
        },
        uoDescription: {
          type: "text",
          value: null,
        },
        userType: {
          type: "select",
          value: null,
          hidden: false,
          options: [
            "collaborator",
            "personal_vwsp",
            "trainee_utp",
            "practicing"
          ],
        }
      },
    };
  },
  validations: {
    formData: {
      // $each: {
      //   value: {
      //     required
      //   }
      // },
      controlNumber: {
        value: {
          required,
          numeric,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
      },
      companyEmail: {
        value: {
          required,
          email,
        },
      },
      name: {
        value: {
          required,
        },
      },
      lastName: {
        value: {
          required,
        },
      },
      gender: {
        value: {
          required,
        },
      },
      birthdate: {
        value: {
          required,
          dateFormat,
        },
      },
      status: {
        value: {
          required,
        },
      },
      admissionDate: {
        value: {
          required,
          dateFormat,
        },
      },
      numberOfChildren: {
        value: {
          numeric,
        },
      },
      type: {
        value: {
          required,
        },
      },
      userType: {
        value: {
          required,
        },
      },
    },
  },
  beforeMount: async function () {
    if (this.controlNumber) await this.getUser();
  },
  methods: {
    getUser: async function () {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getUser(this.controlNumber);
        this.dateTerms = resp.dateAcceptedTerms;
        if (resp.acceptedTermsV2 !== 'v0' && resp.acceptedPrivacyV2 !== 'v0'){
          this.termsAccepted = true;
        }
        this.mapToForm(resp);
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        console.log(error)
        this.$store.commit("global/SET_LOADING", false);
      }
    },
    validateForm: async function () {
      this.$v.$touch();
      if (this.$v.$error) return;
      await this.saveUser();
    },
    saveUser: async function () {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const form = this.mapForm();
        // form.userType = 'collaborator';
        await api.saveUser(this.controlNumber, form);
        this.$store.commit("global/SET_LOADING", false);
        this.$router.push({
          name: "User Manage",
        });
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
    mapToForm: function (userData) {
      for (const field in userData) {
        if (this.formData.hasOwnProperty(field)) {
          if (field === "birthdate" || field === "admissionDate") {
            this.formData[field].value = moment(userData[field], "DD/MM/YY", true).format("YYYY-MM-DD");
          } else if(field === 'gender' && userData[field].length > 0) {
            this.formData[field].value = userData[field].charAt(0).toUpperCase() + userData[field].slice(1);
          } else if (userData[field] === "-") {
            this.formData[field].value = null;
          }
          else {
            this.formData[field].value = userData[field];
          }
        }
      }
    },
    mapForm: function () {
      const obj = {};
      for (const field in this.formData) {
        if (this.formData.hasOwnProperty(field)) {
          if (this.formData[field].type === "date") {
            obj[field] = moment(this.formData[field].value).format("DD/MM/YY");
          } else if (this.formData[field].type === "number" && !this.formData[field].value) {
            obj[field] = 0;
          } else if (this.formData[field].value) {
            obj[field] = this.formData[field].value;
          } else {
            obj[field] = "-";
          }
        }
      }
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  margin: 1rem 0;
  text-align: right;
}
.actions > .cancel-btn {
  margin-right: 1rem;
  text-align: right;
  color: $black;
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.actions > .save-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  border: 1px solid $black;
  padding: 0.5rem 2rem;
}
.form-container {
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem auto;
}
.input-container {
  width: 30%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}
.input-container > label {
  font-weight: bold;
}
.error-msg {
  color: $warn_red;
  font-size: 14px;
}
.error-msg p {
  margin: 0;
}
.date-accepted {
  width: 30%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
</style>
