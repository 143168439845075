<template>
  <div id="blog-new">
    <div class="header">
      <div class="title">
        <h1>{{ $t("news.new.title") }}</h1>
        <p>{{ $t("news.new.directions") }}</p>
        <p class="red-text">{{ $t("news.list.alert") }}</p>
        <p class="bolded">{{ $t("news.list.requiredFields") }}</p>
      </div>
      <div class="publish">
        <div class="controllers">
          <router-link
            class="cancel-btn"
            :to="{ name: 'Blog List' }"
          >{{$t('news.details.modal.cancel')}}</router-link>
          <button
            type="button"
            class="btn btn-dark control publish-btn"
            :disabled="disabledSubmit"
            @click="validateForm"
          >{{ $t("news.new.publish") }}</button>
        </div>
      </div>
    </div>
    <!-- Form -->
    <div class="form-container">
      <!-- Category -->
      <div class="input-container">
        <label>{{ $t("news.new.category") }}(*)</label>
        <vSelect
          v-if="categories"
          inputId="select-search-input"
          :loading="!categories"
          v-model="$v.selectedCategory.$model"
          taggable
          :placeholder="$t('news.new.category_ph')"
          :options="categories"
          :clearable="false"
          @option:created="addCategory"
        ></vSelect>
        <div class="error-msg">
          <span v-if="$v.selectedCategory.$error">{{ $t("newAlert.errors.body") }}</span>
        </div>
      </div>
      <!-- Target -->
      <div class="input-container">
        <label>{{ $t("news.new.target") }}: (*)</label>
        <div v-if="$v.target.$error" class="error-msg">{{ $t("news.new.errors.target") }}</div>
        <div class="ft-input">
          <!-- Public checkbox -->
          <div class="public-checkbox">
            <input type="checkbox" id="public-checkbox" v-model="target.publicNews" />
            <label for="public-checkbox">{{ $t("news.new.public_ph") }}</label>
          </div>
          <!-- Private checkbox -->
          <div class="public-checkbox">
            <input type="checkbox" id="private-checkbox" v-model="target.privateNews" />
            <label for="private-checkbox">{{ $t("news.new.private_ph") }}</label>
          </div>
        </div>
      </div>
      <!-- File input - img -->
      <div class="input-container">
        <label>
          {{ $t("news.new.coverImage") }}
          <span v-if="target.publicNews || target.privateNews">(*)</span>
        </label>
        <p v-if="!target.publicNews && !target.privateNews">{{ $t("news.new.imageInst") }}</p>
        <!-- <p v-if="$v.target.$error">Error</p> -->
        <div v-if="target.publicNews" class="public-img">
          <p>
            <strong>{{ $t("news.new.cover_port") }}(*)</strong>
          </p>
          <FileUploader
            v-if="!fileLoading"
            :id="'public'"
            :placeholder="$t('news.new.cover_ph')"
            :type="'image/*'"
            :validate="'image/'"
            :label="$t('news.new.browse')"
            :fileUrl="imgUrl.publicUrl"
            @setFile="setFile($event, 'public')"
          />
          <!-- <div class="error-msg">
            <p v-if="!$v.cover.publicCover.imgSize">{{ $t("news.new.errors.cover_port") }}</p>
          </div>-->
        </div>
        <div v-if="target.privateNews" class="private-img">
          <p>
            <strong>{{ $t("news.new.cover_land") }} (*)</strong>
          </p>
          <FileUploader
            v-if="!fileLoading"
            :id="'private'"
            :placeholder="$t('news.new.cover_ph')"
            :type="'image/*'"
            :validate="'image/'"
            :label="$t('news.new.browse')"
            :fileUrl="imgUrl.privateUrl"
            @setFile="setFile($event, 'private')"
          />
          <!-- <div class="error-msg">
            <p v-if="!$v.cover.privateCover.imgSize">{{ $t("news.new.errors.cover_land") }}</p>
          </div>-->
        </div>
      </div>
      <!-- Top News -->
      <div class="input-container">
        <label>{{ $t("news.new.top") }}</label>
        <div class="public-checkbox">
          <input type="checkbox" name="top-news" id="top-news" v-model="top" />
          <label for="top-news">{{ $t("news.new.top_ph") }}</label>
        </div>
        <div class="error-msg">
          <p v-if="topError">
            <strong>{{ $t("news.new.errors.top_error") }}</strong>
          </p>
        </div>
      </div>
      <div v-if="target.privateNews" class="input-container">
        <label>{{ $t("news.new.commentable") }}</label>
        <div class="public-checkbox">
          <input type="checkbox" name="commentable" id="commentable" v-model="commentable" />
          <label for="commentable">{{ $t("news.new.commentable_ph") }}</label>
        </div>
      </div>
      <!-- Tabs -->
      <div class="tabs">
        <ul>
          <li :class="['tab', lang == 'es' ? 'active' : '']" @click="changeLang('es')">
            {{ $t("news.new.spanish") }}&nbsp;
            <i
              v-if="isSuccessTab(language.es)"
              class="fas fa-check-circle success"
            ></i>
            <i v-else class="fas fa-exclamation-triangle danger"></i>
          </li>
          <li :class="['tab', lang == 'en' ? 'active' : '']" @click="changeLang('en')">
            {{ $t("news.new.english") }}&nbsp;
            <i
              v-if="isSuccessTab(language.en)"
              class="fas fa-check-circle success"
            ></i>
            <i v-else class="fas fa-exclamation-triangle danger"></i>
          </li>
        </ul>
        <div class="lang-form">
          <div class="lang-form__half-input">
            <!-- Title -->
            <div class="input-container">
              <label for="title">{{ $t("news.new.form_title") }} (*)</label>
              <input
                type="text"
                id="title"
                name="title"
                :placeholder="$t('news.new.form_title_ph')"
                v-model="$v.post[lang].title.$model"
              />
              <div class="error-msg">
                <span v-if="$v.post[lang].title.$error">{{ $t("news.new.errors.title") }}</span>
              </div>
            </div>
            <!-- Subtitle -->
            <div class="input-container">
              <label for="subtitle">{{ $t("news.new.subtitle") }} (*)</label>
              <input
                type="text"
                id="subtitle"
                name="subtitle"
                :placeholder="$t('news.new.subtitle_ph')"
                v-model="$v.post[lang].subtitle.$model"
              />
              <div class="error-msg">
                <span v-if="$v.post[lang].subtitle.$error">{{ $t("news.new.errors.subtitle") }}</span>
              </div>
            </div>
          </div>

          <!-- new componet to support audio & video files -->
          <div class="full-width">
            <template v-for="language in languages">
              <MediaInput
                :id="getMediaInputId"
                v-if="language === lang"
                :media="post[language].media"
                :key="post[language].media.key"
                @onChangeMedia="onChangeMedia"
              />
            </template>
          </div>
          <!-- Wysiwyg -->
          <div class="wysiwyg-container">
            <label>{{ $t("news.new.body") }} (*)</label>
            <div class="error-msg">
              <span v-if="$v.post[lang].body.$error">{{ $t("news.new.errors.body") }}</span>
            </div>
            <div class="wysiwyg">
              <Wysiwyg
                :id="'esp'"
                :class="!fileLoading && lang === 'es' ? '' : 'disappear'"
                ref="esp"
                :placeholder="$t('news.new.body_ph')"
                :articleBody="post['es'].delta"
                :includeMediaContent="false"
                v-on:updateBody="$v.post['es'].body.$model = $event"
              ></Wysiwyg>
              <Wysiwyg
                :id="'eng'"
                :class="!fileLoading && lang === 'en' ? '' : 'disappear'"
                ref="eng"
                :placeholder="$t('news.new.body_ph')"
                :articleBody="post['en'].delta"
                :includeMediaContent="false"
                v-on:updateBody="$v.post['en'].body.$model = $event"
              ></Wysiwyg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Importamos JQuery
const $ = require("jquery");
// Lo declaramos globalmente
window.$ = $;
import { required } from "vuelidate/lib/validators";
import API_Service from "@/lib/api/News.js";
import S3_Handler from "@/lib/api/s3_handler";
import File_Helper from "@/lib/file_helper.js";
import {
  mediaTypes,
  media,
  mediaPath,
  mediaPosition,
  mediaContentType,
  hasContent,
  isValidBody,
  language
} from "@/lib/constants.js";
import MediaInput from '@/components/mediaInput/MediaInput';

const FileUploader = () => import("@/components/FileUploader");
const Wysiwyg = () => import("@/components/Wysiwyg");

export default {
  components: {
    FileUploader,
    Wysiwyg,
    MediaInput
  },
  props: {
    id: {
      required: false
    }
  },
  data: function() {
    return {
      fileLoading: true,
      isUploading: false,
      categories: null,
      selectedCategory: null,
      top: false,
      commentable: null,
      target: {
        publicNews: false,
        privateNews: false
      },
      cover: {
        publicCover: null,
        privateCover: null
      },
      imgUrl: {
        publicUrl: null,
        privateUrl: null
      },
      lang: "es",
      language,
      languages: Object.keys(language),
      post: {
        es: {
          id: null,
          title: null,
          subtitle: null,
          body: null,
          delta:  {
            ops: []
          },
          media: {
            key: `es-${new Date().getTime()}`,
            audio: { key: '', url: '' },
            video: { key: '', url: '' },
            location: ''
          },
        },
        en: {
          id: null,
          title: null,
          subtitle: null,
          body: null,
          delta:  {
            ops: []
          },
          media: {
            key: `en-${new Date().getTime()}`,
            audio: { key: '', url: '' },
            video: { key: '', url: '' },
            location: ''
          }
        }
      },
      topError: false,
      mediaInputKey: new Date().getTime(),
      hasAudio: {
        es: false,
        en: false
      },
      hasVideo: {
        es: false,
        en: false
      }
    };
  },
  computed: {
    getMediaInputId () {
      return `${this.lang}-${new Date().getTime()}`;
    },
    disabledSubmit () {
      const post = this.post;
      const target = this.target;
      const selectedCategory = this.selectedCategory;
      const cover = this.cover;

      const hasBody = isValidBody(post.es.body) && isValidBody(post.en.body);

      return Boolean( 
          !post.es.title || !post.es.subtitle
          || !post.en.title || !post.en.subtitle
          || !hasBody
          || !this.hasAllAudio
          || !this.hasAllVideo
          || (!target.privateNews && !target.publicNews) ||
          !selectedCategory || (!cover.publicCover && !cover.privateCover)
      );
    },
    hasAllAudio () {
      let hasAllAudio = true;

      if(this.hasAudio.es || this.hasAudio.en) {
        hasAllAudio = this.hasAudio.es && this.hasAudio.en;
      }

      return hasAllAudio;
    },
    hasAllVideo () {
      let hasAllVideo = true;

      if(this.hasVideo.es || this.hasVideo.en) {
        hasAllVideo = this.hasVideo.es && this.hasVideo.en;
      }

      return hasAllVideo;
    }
  },
  validations: {
    selectedCategory: {
      required
    },
    target: {
      isRequired: function() {
        if (!this.target.privateNews && !this.target.publicNews) return false;
        return true;
      }
    },
    cover: {
      publicCover: {
        isRequired: function(value) {
          if (!this.target.publicNews) return true;
          if (!value) return false;
          return true;
        },
        imgSize: async function(value) {
          if (!this.target.publicNews) return true;
          if (!value) return false;
          if (typeof value == "string") return true;
          let resp = await this.getImgDimensions(value);
          if (resp.width < resp.height) return true;
          return false;
        }
      },
      privateCover: {
        isRequired: function(value) {
          if (!this.target.privateNews) return true;
          if (!value) return false;
          return true;
        },
        imgSize: async function(value) {
          if (!this.target.privateNews) return true;
          if (!value) return false;
          if (typeof value == "string") return true;
          let resp = await this.getImgDimensions(value);
          if (resp.width > resp.height) return true;
          return false;
        }
      }
    },
    post: {
      es: {
        title: {
          required
        },
        subtitle: {
          required
        },
        body: {
          isRequired: function(data) {
            if (isValidBody(data)) {
              return true
            } else {
              return false;
            }
          }
        }
      },
      en: {
        title: {
          required
        },
        subtitle: {
          required
        },
        body: {
          isRequired: function(data) {
            if (isValidBody(data)) {
              return true
            } else {
              return false;
            }
          }
        }
      }
    }
  },
  watch: {
    "target.publicNews": function(newVal) {
      if (!newVal) {
        if (this.imgUrl.publicUrl) {
          this.cover.publicCover = this.imgUrl.publicUrl;
        } else {
          this.cover.publicCover = null;
        }
      }
    },
    "target.privateNews": function(newVal) {
      if (!newVal) {
        this.commentable = null;
        if (this.imgUrl.privateUrl) {
          this.cover.privateCover = this.imgUrl.privateUrl;
        } else {
          this.cover.privateCover = null;
        }
      } else {
        this.commentable = this.commentable || false;
      }
    }
  },
  beforeMount: async function() {
    await this.loadCategories();
    if (this.id) {
      await this.getPost();
    } else {
      this.fileLoading = false;
    }
  },
  methods: {
    loadCategories: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      try {
        const response = await api.getCategories();
        this.categories = response;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    getPost: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      try {
        const response = await api.getEditPost(this.id);
        this.selectedCategory = response[0].category;
        this.cover.publicCover = response[0].banner;
        this.cover.privateCover = response[0].cover;
        this.imgUrl.publicUrl = response[0].banner;
        this.imgUrl.privateUrl = response[0].cover;
        this.target.publicNews = response[0].external;
        this.target.privateNews = response[0].internal;
        this.top = response[0].top;
        this.commentable = response[0].enableComments;
        this.post.es.id = response[0].id;
        this.post.es.title = response[0].title;
        this.post.es.subtitle = response[0].subtitle;
        this.post.es.body = response[0].body;
        this.post.es.delta = response[0].body;
        this.post.en.id = response[1].id;
        this.post.en.title = response[1].title;
        this.post.en.subtitle = response[1].subtitle;
        this.post.en.body = response[1].body;
        this.post.en.delta = response[1].body;
        this.fileLoading = false;

        this.post.es.media = response[0].media ? response[0].media : { ...media };
        this.post.en.media = response[1].media ? response[1].media : { ...media };

        this.post.es.media.key = `${language.es}-${new Date().getTime()}`;
        this.post.en.media.key = `${language.en}-${new Date().getTime()}`;

        this.hasAudio[language.es] = hasContent(this.post.es.media.audio);
        this.hasAudio[language.en] = hasContent(this.post.en.media.audio);

        this.hasVideo[language.es] = hasContent(this.post.es.media.video);
        this.hasVideo[language.en] = hasContent(this.post.en.media.video);

        this.$v.$touch();
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    addCategory: function(category) {
      if (!this.categories.includes(category)) {
        this.categories.push(category);
      }
    },
    changeLang: function(lang) {
      this.lang = lang;
      if (this.post[this.lang].body) {
        this.post[this.lang].delta = this.post[this.lang].body;
      } else if (this.post[this.lang].delta) {
        this.post[this.lang].delta = this.post[this.lang].delta;
      } else {
        this.post[this.lang].delta = {};
        if (this.lang === "es") {
          var spanish = $("#esp .ql-editor");
          spanish.html("");
        } else {
          var english = $("#eng .ql-editor");
          english.html("");
        }
      }
    },
    setFile: function(file, target) {
      if (target == "public") {
        this.cover.publicCover = file;
      } else {
        this.cover.privateCover = file;
      }
    },
    validateForm: async function() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.isUploading = true;
      if (this.id) {
        await this.updatePost();
      } else {
        await this.uploadPost();
      }
    },
    uploadPost: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      const s3 = new S3_Handler();
      try {
        let publicResp = null;
        let privateResp = null;
        const timestamp = Date.now();
        const slug = `post_${timestamp}`;
        if (this.cover.publicCover) {
          const publicName = `${slug}.${this.cover.publicCover.name
            .split(".")
            .pop()}`;
          publicResp = await s3.uploadFile(publicName, this.cover.publicCover);
        }
        if (this.cover.privateCover) {
          const privateName = `${slug}.${this.cover.privateCover.name
            .split(".")
            .pop()}`;
          privateResp = await s3.uploadFile(
            privateName,
            this.cover.privateCover
          );
        }

        await this.uploadMedia(language.es);
        await this.uploadMedia(language.en);

        const requests = [
          api.uploadPost({
            slug: slug,
            locale: "es",
            title: this.post.es.title,
            subtitle: this.post.es.subtitle,
            body: this.post.es.body,
            author: this.$store.getters["session/author"],
            category: this.selectedCategory.toLowerCase(),
            banner: publicResp ? publicResp.Location : null,
            cover: privateResp ? privateResp.Location : null,
            external: this.target.publicNews,
            internal: this.target.privateNews,
            top: this.top,
            enableComments: this.commentable,
            media: this.post.es.media
          }),
          api.uploadPost({
            slug: slug,
            locale: "en",
            title: this.post.en.title,
            subtitle: this.post.en.subtitle,
            body: this.post.en.body,
            author: this.$store.getters["session/author"],
            category: this.selectedCategory.toLowerCase(),
            banner: publicResp ? publicResp.Location : null,
            cover: privateResp ? privateResp.Location : null,
            external: this.target.publicNews,
            internal: this.target.privateNews,
            top: this.top,
            enableComments: this.commentable,
            media: this.post.en.media
          })
        ];
        await Promise.all(requests);
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        this.$router.push({
          name: "Blog List"
        });
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        if (error.status === 409) this.topError = true;
        throw error;
      }
    },
    updatePost: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      const s3 = new S3_Handler();
      try {
        let publicResp = null;
        let privateResp = null;
        const timestamp = Date.now();
        const slug = `post_${timestamp}`;
        if (
          this.cover.publicCover &&
          typeof this.cover.publicCover != "string"
        ) {
          const publicName = `${slug}.${this.cover.publicCover.name
            .split(".")
            .pop()}`;
          publicResp = await s3.uploadFile(publicName, this.cover.publicCover);
        }
        if (
          this.cover.privateCover &&
          typeof this.cover.privateCover != "string"
        ) {
          const privateName = `${slug}.${this.cover.privateCover.name
            .split(".")
            .pop()}`;
          privateResp = await s3.uploadFile(
            privateName,
            this.cover.privateCover
          );
        }

        await this.uploadMedia(language.es);
        await this.uploadMedia(language.en);

        const requests = [
          api.updatePost(
            {
              title: this.post.es.title,
              subtitle: this.post.es.subtitle,
              body: this.post.es.body,
              // author: this.$store.getters["session/userControl"],
              category: this.selectedCategory.toLowerCase(),
              banner: publicResp ? publicResp.Location : this.imgUrl.publicUrl,
              cover: privateResp
                ? privateResp.Location
                : this.imgUrl.privateUrl,
              external: this.target.publicNews,
              internal: this.target.privateNews,
              top: this.top,
              enableComments: this.commentable,
              media: this.post.es.media
            },
            this.id,
            this.post.es.id
          ),
          api.updatePost(
            {
              title: this.post.en.title,
              subtitle: this.post.en.subtitle,
              body: this.post.en.body,
              // author: this.$store.getters["session/userControl"],
              category: this.selectedCategory.toLowerCase(),
              banner: publicResp ? publicResp.Location : this.imgUrl.publicUrl,
              cover: privateResp
                ? privateResp.Location
                : this.imgUrl.privateUrl,
              external: this.target.publicNews,
              internal: this.target.privateNews,
              top: this.top,
              enableComments: this.commentable,
              media: this.post.en.media
            },
            this.id,
            this.post.en.id
          )
        ];
        await Promise.all(requests);
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        this.$router.push({
          name: "Blog List"
        });
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        if (error.status === 409) this.topError = true;
        throw error;
        // throw error;
      }
    },
    getImgDimensions: async function(file) {
      const helper = new File_Helper();
      try {
        const resp = await helper.getImageDimension(file);
        return resp;
      } catch (error) {
        throw error;
      }
    },
    isSuccessTab(lang) {
      let isSuccess = this.$v.post[lang].$anyDirty && !this.$v.post[lang].$anyError;
      if (isSuccess) {
        isSuccess = isValidBody(this.post[lang].body);
      }

      if(isSuccess) {
        //check for media
        let videoOk = true;
        let audioOk = true;
        
        const hasVideoCopy = {...this.hasVideo};
        delete hasVideoCopy[lang];
        const videoValues = Object.values(hasVideoCopy);
        
        if (!this.hasVideo[lang]) {
          videoOk = !videoValues.includes(true)
        }

        const hasAudioCopy = {...this.hasAudio};
        delete hasAudioCopy[lang];
        const audioValues = Object.values(hasAudioCopy);
        
        if (!this.hasAudio[lang]) {
          audioOk = !audioValues.includes(true)
        }
        
        isSuccess = videoOk && audioOk;
      }
      
      return isSuccess;
    },
    uploadMedia: async function(lang) {
      const audio = this.post[lang].media.audio;
      const video = this.post[lang].media.video;
      const fileName = `news-${lang}-${Date.now()}`;
      try {
        if (audio.file) {
          const audioPath = `${mediaPath.audio}/${fileName}.${audio.file.name.split(".").pop()}`;
          const audioResponse = await this.uploadMediaToS3(audioPath, audio.file, mediaContentType.audio);
          if (audioResponse && audioResponse.Key) {
            this.post[lang].media.audio = { key: audioResponse.Key };
          }
          delete this.post[lang].media.audio.file;
        }

        if (video.file) {
          const videoPath = `${mediaPath.video}/${fileName}.${video.file.name.split(".").pop()}`;
          const videoResponse = await this.uploadMediaToS3(videoPath, video.file, mediaContentType.video);
          if (videoResponse && videoResponse.Key) {
            this.post[lang].media.video = { key: videoResponse.Key };
          }
          delete this.post[lang].media.video.file;
        }
        
        this.post[lang].media.location = this.post[lang].media.location
          ? this.post[lang].media.location
          : mediaPosition.top;
      } catch (error) {
        this.$store.commit("global/SET_ALERT", {
          msg: this.$t('components.mediaInput.errors.uploadToS3'),
          show: true,
          type: "error"
        });
        console.error(error);
        throw error;
      }
    },
    uploadMediaToS3: async function (fileName, file, contentType) {
      const s3 = new S3_Handler();
      const response = await s3.uploadMediaFile(fileName, file, contentType);
      return response;
    },
    onChangeMedia: function (data) {
      const mediaFile = data.data;

      switch (data.type) {
        case mediaTypes.audio:
          this.post[this.lang].media.audio = {
            key: '',
            file: mediaFile
          }
          this.hasAudio[this.lang] = Boolean(mediaFile);
        break;
        case mediaTypes.video:
          this.post[this.lang].media.video = {
            key: '',
            file: mediaFile
          }
          this.hasVideo[this.lang] = Boolean(mediaFile);
        break;
        case mediaPosition.key:
          this.post[this.lang].media.location = mediaFile.top ? mediaPosition.top : mediaPosition.bottom;
        break;     
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.red-text {
  color: #bb0a30;
  font-weight: bold;
  font-size: 1rem;
}
.publish {
  display: flex;
  align-items: center;
}
.controllers {
  display: flex;
  align-items: center;
}
.cancel-btn {
  margin-right: 1rem;
  color: $black;
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.publish-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  padding: 0.5rem 2rem;
}
.form-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border: 1px solid $wg_2;
  padding: 1rem 3rem;
  margin: 2rem 0;
}
.form-container > .input-container {
  margin-right: 2rem;
}
.input-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  margin: 20px 0px;

  label {
    @extend %audi-b;
  }

  .ft-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .ft-input > .public-checkbox > * {
    margin-right: 10px;
  }
  .ft-input > .public-checkbox label {
    @extend %audi-l;
    margin-bottom: 0;
  }
  .public-img,
  .private-img {
    width: 100%;
    margin-bottom: 1rem;

    p {
      margin-bottom: 0;
    }
  }

  .public-checkbox > * {
    font-weight: lighter;
    margin-right: 1rem;
  }
}

.tabs {
  width: 100%;
  margin: 2rem 0;
}
.tabs ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
  position: relative;
  z-index: 9;
}
.tabs .tab {
  @extend %audi-ext-b;
  display: inline-block;
  padding: 0.5rem 2rem;
  border: 1px solid $wg_1;
  background-color: $w_1;
  cursor: pointer;

  &.active {
    background-color: $white;
    border-bottom-color: $white;
  }
}
.lang-form {
  border: 1px solid $wg_1;
  position: relative;
  top: -1px;
  padding: 1rem 2rem;
  background-color: $white;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &__half-input {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content:space-between;
    .input-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 18px;
      margin: $global_padding 0 0 0;
    }
  }
  .full-width {
    width: 70%;
  }
}

.lang-form .input-container {
  width: 45%;
}
.lang-form .wysiwyg-container {
  width: 100%;
}
.lang-form .wysiwyg-container label {
  width: 95%;
  @extend %audi-b;
  font-size: 18px;
}
.error-msg {
  color: $warn_red;
  font-size: 14px;
}
.danger {
  color: #bb0a30;
}
.success {
  color: #217346;
}
</style>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
#blog-new {
  .v-select {
    width: 100%;
  }
  .vs__dropdown-toggle {
    color: $bg_1;
    border: none;
    border-bottom: 1px solid $wg_1;
    border-radius: 0;
  }
  #select-search-input {
    width: 0%;
    border: none;
  }
  .vs__dropdown-option:hover {
    background-color: $wg_3;
    color: $black;
  }
  .vs__dropdown-option--selected {
    background-color: $b_2;
    color: $white;
  }
  .vs__dropdown-option--highlight {
    background-color: $wg_3;
    color: $black;
  }
  .vs__actions {
    cursor: pointer;
  }
  .quill-editor .ql-editor {
    min-height: 50vh;
  }
  .disappear {
    display: none !important;
  }
}
</style>
