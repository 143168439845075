<template>
  <div id="coupons-faq">
    <h1>{{$t('coupons.title')}}</h1>
    <h2>{{$t('coupons.contact.title')}}</h2>
    <p>{{$t('coupons.contact.directions')}}</p>
    <!-- Contact Link -->
    <div class="error-msg">
      <span v-if="$v.contactLinks.$error">{{$t("coupons.contact.errors.general")}}</span>
    </div>
    <div class="contact-links">
      <div class="input-container">
        <label for="web-link">{{$t('coupons.contact.web')}}</label>
        <input
          type="url"
          name="web-link"
          id="web-link"
          :placeholder="$t('coupons.contact.web')"
          v-model="$v.contactLinks.webLink.$model"
        />
      </div>
      <div class="input-container">
        <label for="android-link">{{$t('coupons.contact.android')}}</label>
        <input
          type="url"
          name="android-link"
          id="androd-link"
          :placeholder="$t('coupons.contact.android')"
          v-model="$v.contactLinks.androidLink.$model"
        />
      </div>
      <div class="input-container">
        <label for="-link">{{$t('coupons.contact.ios')}}</label>
        <input
          type="url"
          name="ios-link"
          id="ios-link"
          :placeholder="$t('coupons.contact.ios')"
          v-model="$v.contactLinks.iosLink.$model"
        />
      </div>
    </div>
    <!-- Wysiwyg -->
    <h2>{{$t('coupons.FAQ.title')}}</h2>
    <p>{{$t('coupons.FAQ.directions')}}</p>
    <div class="input-container">
      <!-- <label>{{$t("newAlert.alertBody")}}</label> -->
      <div class="wysiwyg">
        <Wysiwyg
          v-if="!wysiwygLoading"
          :placeholder="$t('coupons.FAQ.placeholder')"
          :articleBody="delta"
          v-on:updateBody="body = $event"
        ></Wysiwyg>
      </div>
      <div class="error-msg">
        <span v-if="$v.body.$error">{{$t("coupons.FAQ.errors.bodyRequired")}}</span>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-dark control publish-btn"
      :disabled="isUploading"
      @click="validateForm"
    >{{$t('map.transport.FAQ.publish')}}</button>
  </div>
</template>

<script>
import COUPONS_API from "@/lib/api/Coupons.js";
import { required } from "vuelidate/lib/validators";
const Wysiwyg = () => import("@/components/Wysiwyg");

export default {
  components: {
    Wysiwyg
  },
  data: function() {
    return {
      wysiwygLoading: true,
      delta: null,
      body: null,
      isUploading: false,
      contactLinks: {
        webLink: null,
        androidLink: null,
        iosLink: null
      }
    };
  },
  validations: {
    body: {
      required
    },
    contactLinks: {
      webLink: {
        required
      },
      androidLink: {
        required
      },
      iosLink: {
        required
      }
    }
  },
  beforeMount: async function() {
    await this.getLinks();
    await this.getFAQ();
  },
  methods: {
    getLinks: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new COUPONS_API();
      try {
        const linksReq = [
          api.getLink("webLink"),
          api.getLink("androidLink"),
          api.getLink("iosLink")
        ];
        const linksResp = await Promise.all(linksReq);
        this.contactLinks.webLink = linksResp[0].title;
        this.contactLinks.androidLink = linksResp[1].title;
        this.contactLinks.iosLink = linksResp[2].title;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        // throw error;
      }
    },
    getFAQ: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new COUPONS_API();
      try {
        const response = await api.getFAQ();
        this.delta = response.body;
        this.body = response.body;
        this.wysiwygLoading = false;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.wysiwygLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        this.delta = null;
        throw error;
      }
    },
    validateForm: async function() {
      this.isUploading = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.isUploading = false;
        return;
      }
      await this.postFAQ();
    },
    postFAQ: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new COUPONS_API();
      try {
        const author = this.$store.getters["session/author"];
        const linksReq = [
          api.postLink(this.contactLinks.webLink, "webLink", author),
          api.postLink(this.contactLinks.androidLink, "androidLink", author),
          api.postLink(this.contactLinks.iosLink, "iosLink", author)
        ];
        await Promise.all(linksReq);
        await api.postFAQ(this.body, author);
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        await this.getFAQ();
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#coupons-faq {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 4rem;

  h2 {
    margin-top: 2rem;
  }

  .input-container {
    width: 100%;
  }

  .error-msg {
    color: $warn_red;
    font-size: 14px;
  }

  .publish-btn {
    align-self: flex-end;
    background: #000;
    border-radius: 0px;
    padding: 0.5rem 1rem;
  }
}

.contact-links {
  margin-bottom: 2rem;
  display: flex;
}
.contact-links .input-container {
  margin-right: 2rem;
}
</style>

<style lang="scss">
#coupons-faq {
  .quill-editor .ql-editor {
    height: 50vh;
  }
}
</style>