import axios from "axios";

export default class Mobility {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  
  }


  async getDrivers(queries) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/drivers/realtime-report`,
        data: queries
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async downloadDriversReport(queries)
  {
    try{
      const response = await axios({
        method: "POST",
        url: `${this.API_LINK}/drivers/realtime-report/download`,
        data: queries
      });
    
      return response.data;
    }
    catch(error){
      throw error;
    }

    }

    async getTrips(queries)
    {
      try{
        const response = await axios({
          method: "POST",
          url: `${this.API_LINK}/trips/realtime-report`,
          data: queries
        });
       
        return response.data;
      }
      catch(error){
        throw error;
      }
  
      }

      async  downloadTripsReport(queries)
      {
        try{
          const response = await axios({
            method: "POST",
            url: `${this.API_LINK}/trips/realtime-report/download`,
            data: queries
          });
          return response.data;
        }
        catch(error){
        throw error;
        }
    
        }

        async getDriversList(queries)
        {
          try {
            const response = await  axios({
              method:"POST",
              url: `${this.API_LINK}/drivers/list`,
              data: queries
            });
            return response.data;
          }catch(error) {
            throw error;
          }
          }

          async createDriver(queries)
          {
            try {
              const response = await  axios({
                method:"POST",
                url: `${this.API_LINK}/mobility/driver/new`,
                data: queries
              });
              return response.data;
            }catch(error) {
              throw error;
            }
            }


            async updateDriver(queries)
            {
              try {
                const response = await  axios({
                  method:"PATCH",
                  url: `${this.API_LINK}/drivers`,
                  data: queries
                });
                return response.data;
              }catch(error) {
                throw error;
              }
              }
            
 
              async saveUser(data) {
                const url = `${this.API_LINK}/users/`;
                try {
                  const response = await axios({
                    method:  "patch",
                    url: url,
                    data: data
                  });
                  return response;
                } catch (error) {
                  throw error;
                }
              }      

              
              async createVehicle(data) {
                const url = `${this.API_LINK}/cars/`;
                try {
                  const response = await axios({
                    method:  "POST",
                    url: url,
                    data: data
                  });
                  return response;
                } catch (error) {
                  throw error;
                }
              } 
               async updateVehicle(data) {
                const url = `${this.API_LINK}/cars/`;
                try {
                  const response = await axios({
                    method:  "PATCH",
                    url: url,
                    data: data
                  });
                  return response;
                } catch (error) {
                  throw error;
                }
              }


    




  async getPlaces() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/mobility/places`
      });
      return response.data;
    } catch (error) {
      throw error; 
    }
  }

  async getAdminUsers() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/admin/admin`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  /* Configurado Antes */
  async getUsers(queries) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/users/searchAndFilter`,
        data: queries
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getAllVehicles(queries){
    try{
      const response = await axios({
        method:"post",
        url: `${this.API_LINK}/cars/list`,
        data: queries
      });
      return response.data;
    }catch(error){
    throw error;
    }
    
  }
}