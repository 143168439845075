<template>
  <div class="driversEdit">
      <div class="row"> 
                      <div class="col-md-12">
                        <div class="row" v-if ="nuevo === true">
                        <h1 class="labelBold">
                          {{$t("mobilityOnDemand.vehiclesMobility.new")}}
                        </h1>
                        </div>
                        <div class="row" v-if ="nuevo === false">
                        <h1 class="labelBold">
                          {{$t("mobilityOnDemand.vehiclesMobility.modify")}}
                        </h1>
                        </div>
                       </div>
      </div>
       <div class="row"> 
            <div class="col-md-9">

            </div>
                  
                           
                          <div class="col-md-3">
                                <button class="btn-cancel" @click="link">
                                   {{ $t("mobilityOnDemand.editDrivers.back") }}
                                </button>
                                <button class="btn-confirm" @click="showModal">
                                    {{ $t("mobilityOnDemand.editDrivers.save") }}
                                </button>
                          </div>
                         
       
        </div>
       
    <div class="row" v-if ="nuevo === true">
    
                    
                            <!-- model -->
                            <div class="col-md-4">
                            <label class="labelBold mt-2">
                                {{ $t("mobilityOnDemand.vehiclesMobility.model") }}
                            </label>
                            <input type="text" v-model="model" />
                            </div>
                                    <!-- car_plate -->
                                    <div class="col-md-4">
                                    <label class="labelBold mt-2">
                                        {{ $t("mobilityOnDemand.vehiclesMobility.carPlate")}}
                                    </label>
                                    <input type="tel" v-model="car_plate"/>
                                    </div>


                                       <div class="col-md-4">
                                             <label for="status" class="labelBold">
                                                {{ $t("mobilityOnDemand.vehiclesMobility.status") }}
                                               </label>
                                               <br/>
                                              <div >
                                               <select class="form-control mt-2" id ="status1" v-model="selected" >
                                                          <option v-for="option in options"
                                                          v-bind:key="option.id"
                                                          v-bind:value="option.id">
                                                          {{option.name}}
                                                          </option>
                                                    </select>
                                              </div>
                                       </div>
                               
    </div>
        <div class="row" v-if ="nuevo === false">
    
                      <!-- id -->
                      <div class="col-md-4">
                      <label class="labelBold mt-2">
                          {{ $t("mobilityOnDemand.vehiclesMobility.id") }}
                      </label>
                      <input type="text" v-model="id" disabled/>
                      </div>
                            <!-- model -->
                            <div class="col-md-4">
                            <label class="labelBold mt-2">
                                {{ $t("mobilityOnDemand.vehiclesMobility.model") }}
                            </label>
                            <input type="text" v-model="model" disabled/>
                            </div>
                                    <!-- car_plate -->
                                    <div class="col-md-4">
                                    <label class="labelBold mt-2">
                                        {{ $t("mobilityOnDemand.vehiclesMobility.carPlate")}}
                                    </label>
                                    <input type="text" v-model="car_plate" />
                                    </div>
                                    



                                       <div class="col-md-4">
                                             <label for="status" class="labelBold">
                                                {{ $t("mobilityOnDemand.vehiclesMobility.status") }}
                                               </label>
                                               <br/>
                                              <div >
                                               <select class="form-control mt-2" id ="status2" v-model="selected" >
                                                          <option v-for="option in options"
                                                          v-bind:key="option.id"
                                                          v-bind:value="option.id">
                                                          {{option.name}}
                                                          </option>
                                                    </select>
                                              </div>
                                       </div>
                                             
   
                               
    </div>
  
     <b-modal centered ref="my-modal" hide-footer hide-header  tabindex="-1" >
   
      <div class="aui-headline-3" >
     
         <b-button size="lg" class="pull-right" style="height:80px; width:60px;font-size:30px; background-color:#ffffcc; color:#FFCD00" variant="outline-gray" @click="hideModal()"><h1>X</h1></b-button>
        <div class="row">
          <div class="alertBg col-md-12">
            <h4 >  {{ $t("mobilityOnDemand.editDrivers.confirmation") }}</h4>
          
               <div class="RedText"  v-if="nuevo==true">
                                           {{ $t("mobilityOnDemand.vehiclesMobility.create") }}
                                        </div>
                                          <div class="alertText" v-else-if="nuevo==false">
                                               {{ $t("mobilityOnDemand.vehiclesMobility.alert") }}
                                              </div>
           
            </div>
            

        </div>

       <br/>
         <p>
               <label class="labelBold mt-2">
                {{ $t("mobilityOnDemand.vehiclesMobility.model") }}
               </label>: {{model}}
          </p>
            <p>
               <label class="labelBold mt-2">
                {{ $t("mobilityOnDemand.vehiclesMobility.carPlate") }}
               </label>: {{car_plate}}
          </p>

          <label class="labelBold mt-2">
                                                {{ $t("mobilityOnDemand.vehiclesMobility.status") }}:
          </label>
            <div >
                                               <select class="form-control" id ="status1" v-model="selected" disabled >
                                                          <option v-for="option in options"
                                                          v-bind:key="option.id"
                                                          v-bind:value="option.id">
                                                          {{option.name}}
                                                          </option>
                                                    </select>
                                              </div>
                                            
                 <p></p>
        <div class="row">
      <div class="col-md-4">

      </div>
        <div class="col-md-3">
            <button
          class="btn-cancel"
          @click="hideModal()"

        >
        {{ $t("mobilityOnDemand.editDrivers.cancel") }}
                  </button>
                    </div>
                    <div class="col-md-3">
                      <button
                    class="btn-confirm"
                    @click="saveVehicle()"

                  >
                 {{ $t("mobilityOnDemand.editDrivers.confirm") }}
                  
                  </button>
                  </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import API_SERVICE from "@/lib/api/MobilityOnDemand.js";
import store from '@/vuex/store';
const api = new API_SERVICE();
export default {
    components: {
    store
  },
  props: {
    carplate: {
      type: String
    },
    idCar:{
     type: String
    },
    data: {
      type: Object
    },
    details: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    create1: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
        nuevo:false,
        selected:"",
        id:"",
        model:"",
        car_plate:"",
        status:"",
        controlNumber:""
    };
  },
  async beforeMount() {
    
    if(this.carplate) {
      await this.getDetailVehicle();
      this.fillData();
    }
    else{
      this.nuevo= true;
    }
  },
  methods: {
       showModal() {
        if(this.selected == "choose" || this.selected == ""|| this.model == "" || this.car_plate == "")
               {
                  this.$store.commit("global/SET_ALERT", {
                  msg: `${this.$t("mobilityOnDemand.vehiclesMobility.verify")}`,
                  show: true,
                  type: "error"
                });
               return;
               }
               else
                      {
                      this.$refs['my-modal'].show()
                      }
      },
          hideModal() {
            this.$refs['my-modal'].hide()
          },
 
          link() {
             this.$router.push({name: "Vehicles Mobility"});
          },
    getDetailVehicle: async function() {
      
      this.$store.commit("global/SET_LOADING", true);
      try {
        var jsonR = {limit: 1,page: 1,query:{"id":this.idCar}};
        const resp = await api.getAllVehicles(jsonR);
        console.log(resp);
        this.vehicle = resp.data;
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
         this.$store.commit("global/SET_ALERT", {
                  msg: `${"Detail :"+ error}`,
                  show: true,
                  type: "error"
                });
      } finally {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
    translateStatus(status) {
      if (status == "available"){
      status = this.$t("mobilityOnDemand.driversMobility.active")
      this.selected = "available";
      }
      else if(status == "unavailable"){
       status = this.$t("mobilityOnDemand.driversMobility.inactive")
        this.selected = "unavailable";
      }
     
       else {
        status
      }
        return status
    },
    fillData: function() {
      try {
        this.id = this.vehicle[0].id;
        this.model = this.vehicle[0].model;
        this.car_plate = this.vehicle[0].car_plate;
        this.status = this.translateStatus(this.vehicle[0].status);
  

      } catch (error) {
          this.$store.commit("global/SET_ALERT", {
                  msg: `${"Error in fillData :"+ error}`,
                  show: true,
                  type: "error"
                });
        
      }
    },

      async saveVehicle() {
       

       
        this.$store.commit("global/SET_LOADING", true);
        try {
          
          
          if (this.nuevo) {
            var jsonR = {"model" :this.model,"status":this.selected,"car_plate":this.car_plate};

           const resp = await api.createVehicle(jsonR);

            this.$store.commit("global/SET_LOADING", false);
            store.commit('global/SET_RESP', "success"); 
               this.$store.commit("global/SET_ALERT", {
                 msg: `${this.$t("mobilityOnDemand.vehiclesMobility.newVehicle")}`+" :"+this.car_plate,
                 show: true,
                 type: "success"
               });
             this.$router.push({name: "Vehicles Mobility"});

         }
         else if(this.nuevo == false){
                 


      
            var jsonR = {"car_id": this.id,"status":this.selected,"car_plate":this.car_plate};
            const resp = await api.updateVehicle(jsonR);
                 this.$store.commit("global/SET_LOADING", false);
                 this.$store.commit("global/SET_ALERT", {
                 msg: `${this.$t("mobilityOnDemand.vehiclesMobility.updateVehicle")}`+ " :"+this.car_plate,
                 show: true,
                 type: "success"
               });
             this.$router.push({
            name: "Vehicles Mobility"
             });
            
         }
        }
         catch (error) {
                 
                 this.$store.commit("global/SET_LOADING", false);
                 this.$store.commit("global/SET_ALERT", {
                 msg: `${'Error trying to create driver: ', error}`,
                 show: true,
                 type: "error"
               });
               this.hideModal();
          
        }
      
    },

  },
  computed: {
     options(){
        return[
                {id:"choose",name: this.$t('mobilityOnDemand.choose')} ,
                {id:"available",name: this.$t("mobilityOnDemand.vehiclesMobility.active")} ,
                {id:"unavailable",name: this.$t("mobilityOnDemand.vehiclesMobility.inactive")}
              ]},
     
  
  }
};
</script>
<style media="screen">


.alertBg {
  border-left: 10px solid #ffeb3b;
  text-align: center;
  background: #fff;
 
}
.RedText {
  color: #ff465a;
  text-align: center;
  font:bold;
}

 .alertText {
  color: #FFCD00;
  text-align: center;
  font:bold;

}
	@media (min-width: 600px) {
		#modal-sample-morphing-layer .aui-modal-dialog__body {
			/* Define a width for this specific dialog: */
			width: 93.75%;
			max-width: 460px;
		}
	}
</style>

<style lang="scss" scoped>

@font-face {
  font-family: "audi-extended";
  src: url('~@/assets/fonts/AudiType-ExtendedNormal.ttf');
  font-weight: bold;
}
.return {
  color: black;
  cursor: pointer;
}
.return:hover {
  text-decoration: underline !important;
  color: black;
}
.labelBold {
  font-weight: bold;
  color: #000;
}

.driversEdit {
  background-color: $white;
  padding: 2rem 4rem;
}
.btn-download {
  padding: 2rem 2rem;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 0px;
}

.btn-confirm {
  margin-left: 1rem;
  padding:1rem 1.5rem;
  background: #000;
  color: #fff;
  border: 1px solid #000;
  border-radius: 0px;
  
}
.btn-cancel {

  padding:1rem 1.5rem;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 0px;
 
}

.aui-headline-3{
  font-family: 'audi-extended';
  font-weight: 500;
  letter-spacing: -0.015em;
  padding: 2rem 2rem;
  }



</style>

