<template>
  <div id="rhAssessor-edit">
    <!-- Back Button -->
    <router-link class="back-btn" :to="{ name: 'RH Assessor List' }">
      <i class="fas fa-angle-left"></i>&nbsp;
      <strong>{{$t('rhAssessor.edit.back_btn')}}</strong>
    </router-link>
    <!-- Header -->
    <header class="header">
      <div class="title">
        <h1>
          {{$t("rhAssessor.list.title")}}:
          <span v-if="category">{{category.title}}</span>
        </h1>
        <p>{{$t('rhAssessor.edit.directions')}}</p>
      </div>
      <!-- publish Button -->
    </header>
    <!-- Wysiwyg -->
    <div class="input-container">
      <!-- <label>{{$t("newAlert.alertBody")}}</label> -->
      <div class="error-msg">
        <span v-if="$v.category.body.$error">{{$t("rhAssessor.edit.errors.body")}}</span>
      </div>
      <div class="wysiwyg">
        <Wysiwyg
          v-if="!wysiwygLoading"
          :placeholder="$t('rhAssessor.edit.body_ph')"
          :articleBody="category.delta"
          v-on:updateBody="category.body = $event"
        ></Wysiwyg>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-dark publish-btn"
            :disabled="isUploading"
            @click="validateForm"
          >{{$t('rhAssessor.edit.publish')}}</button>
          <!-- <button
            type="button"
            @click="downloadQrFile"
            class="btn btn-dark publish-btn"
            :disabled="isUploading"
            v-if="category.qrLocation"
          >{{$t("rhAssessor.edit.getQR")}}</button> -->
          <a
            v-if="category.qrLocation"
            :href="signedURL"
            class="btn btn-dark publish-btn"
            download
            target="_blank"
          >{{ $t("rhAssessor.edit.getQR") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RHAssessor from "@/lib/api/RHAssessor.js";
import S3_Handler from "@/lib/api/s3_handler";
const Wysiwyg = () => import("@/components/Wysiwyg");
const s3 = new S3_Handler();

export default {
  components: {
    Wysiwyg
  },
  props: {
    id: {
      required: true
    }
  },
  data: function() {
    return {
      category: {},
      wysiwygLoading: true,
      isUploading: false,
      signedURL: null
    };
  },
  validations: {
    category: {
      body: {
        isRequired: function(data) {
          if (data) {
            if (data.hasOwnProperty("ops")) {
              if (data.ops.length == 1 && data.ops[0].insert === "\n") {
                return false;
              } else {
                return true;
              }
            }
            return false;
          }
          return false;
        }
      }
    }
  },
  beforeMount: async function() {
    await this.getCategory();
    await this.getFile();
    this.wysiwygLoading = false;
  },
  methods: {
    getCategory: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new RHAssessor();
      try {
        const response = await api.getCategory(this.id);
        this.category = response;
        this.category.delta = response.body;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    validateForm: async function() {
      this.isUploading = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.isUploading = false;
        return;
      }
      await this.updateCategory();
    },
    updateCategory: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new RHAssessor();
      try {
        const data = {
          body: this.category.body,
          author: this.$store.getters["session/userControl"],
          title: this.category.title
        };
        await api.saveCategory(this.id, data);
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        this.$router.push({
          name: "RH Assessor List"
        });
      } catch (error) {
        this.isUploading = false;
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    downloadQrFile: async function() {
      this.$store.commit('global/SET_LOADING', true);
      try {
        const api = new RHAssessor();
        const resp = await api.downsloadQR(this.id);
        let anchor = document.createElement('a');
        anchor.href = resp.qrLocation;
        anchor.target = '_blank';
        anchor.click();
        this.$store.commit('global/SET_LOADING', false);
      } catch (error) {
        console.log(error);
        this.$store.commit('global/SET_LOADING', false);
      }
    },
    getFile: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const urlResp = s3.getSignedUrl(this.category.qrLocation);
        this.signedURL = urlResp;
      } catch (error) {
        console.log(error);
        this.$store.commit("global/SET_LOADING", false);
      } finally {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.back-btn {
  font-size: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
  color: $black;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  display: inline-block;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.header {
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.header .publish-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  padding: 0.5rem 2rem;
}
.input-container {
  margin: 1rem 4rem;
}

.error-msg {
  color: $warn_red;
  font-size: 14px;
}
.publish-btn {
  align-self: flex-end;
  background: #000;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
}

</style>

<style lang="scss">
#rhAssessor-edit {
  .quill-editor .ql-editor {
    height: 50vh;
  }
}

.download-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  padding: 0.5rem 2rem;
  align-self: flex-end;
}

.download-btn:hover {
  color: $white;
}
</style>
