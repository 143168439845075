var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-manage"}},[_c('header',[_c('h1',[_vm._v(_vm._s(_vm.$t('users.manage.table.title')))]),_c('router-link',{attrs:{"to":{
      name: 'User New'
    }}},[_c('button',{staticClass:"new-btn"},[_vm._v(_vm._s(_vm.$t('users.manage.table.new')))])])],1),_c('div',{staticClass:"file-uploader"},[_c('fileUpload',{on:{"updateTable":_vm.updateTable}})],1),_c('v-server-table',{ref:"userTable",attrs:{"columns":_vm.table_options.columns,"options":_vm.table_options.options},scopedSlots:_vm._u([{key:"actions",fn:function(props){return [_c('router-link',{staticClass:"edit-btn",attrs:{"to":{
        name: 'User Edit',
        params: {
          controlNumber: props.row.controlNumber
        }
      }}},[_c('i',{staticClass:"far fa-edit"})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }