<template>
  <div id="app-links">
    <h1>{{$t('apps.links.title')}}</h1>
    <div class="form-container">
      <div class="ios">
        <h2>{{$t('apps.links.ios')}}</h2>
        <div class="input-container">
          <label for="iosLink">{{$t("apps.links.form.url")}}</label>
          <input
          type="url"
          name="iosLink"
          id="iosLink"
          :placeholder="$t('apps.links.form.url_ph')"
          v-model="$v.ios.link.$model">
          <div v-if="$v.ios.link.$error" class="error-msg">
            <p v-if="!$v.ios.link.required">{{$t("apps.links.errors.url_required")}}</p>
            <p v-if="!$v.ios.link.url">{{$t("apps.links.errors.url_valid")}}</p>
          </div>
        </div>
        <div class="input-container">
          <label for="iosVersion">{{$t("apps.links.form.version")}}</label>
          <input
          type="text"
          name="iosVersion"
          id="iosVersion"
          :placeholder="$t('apps.links.form.version_ph')"
          v-model="$v.ios.version.$model">
          <div v-if="$v.ios.version.$error" class="error-msg">
            <p v-if="!$v.ios.version.required">{{$t("apps.links.errors.version_required")}}</p>
            <p v-if="!$v.ios.version.versionValid || !$v.ios.version.numeric">{{$t("apps.links.errors.version_valid")}}</p>
          </div>
        </div>
        <button
        class="save-btn"
        @click="validateForm('ios')">{{$t("apps.links.form.save")}}</button>
      </div>
      <div class="android">
        <h2>{{$t('apps.links.android')}}</h2>
        <div class="input-container">
          <label for="androidLink">{{$t("apps.links.form.url")}}</label>
          <input
          type="url"
          name="androidLink"
          id="androidLink"
          :placeholder="$t('apps.links.form.url_ph')"
          v-model="$v.android.link.$model">
          <div v-if="$v.android.link.$error" class="error-msg">
            <p v-if="!$v.android.link.required">{{$t("apps.links.errors.url_required")}}</p>
            <p v-if="!$v.android.link.url">{{$t("apps.links.errors.url_valid")}}</p>
          </div>
        </div>
        <div class="input-container">
          <label for="androidVersion">{{$t("apps.links.form.version")}}</label>
          <input
          type="text"
          name="androidVersion"
          id="androidVersion"
          :placeholder="$t('apps.links.form.version_ph')"
          v-model="$v.android.version.$model">
          <div v-if="$v.android.version.$error" class="error-msg">
            <p v-if="!$v.android.version.required">{{$t("apps.links.errors.version_required")}}</p>
            <p v-if="!$v.android.version.versionValid || !$v.android.version.numeric">{{$t("apps.links.errors.version_valid")}}</p>
          </div>
        </div>
        <button
        class="save-btn"
        @click="validateForm('android')">{{$t("apps.links.form.save")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, url, decimal } from "vuelidate/lib/validators";
import API_SERVICE from "@/lib/api/Apps.js";
const api = new API_SERVICE();
const versionValid = (value) => value >= 0;

export default {
  data: function() {
    return {
      ios: {
        link: null,
        version: null
      },
      android: {
        link: null,
        version: null
      }
    };
  },
  validations: {
    ios: {
      link: {
        required,
        url
      },
      version: {
        required,
        decimal,
        versionValid
      }
    },
    android: {
      link: {
        required,
        url
      },
      version: {
        required,
        decimal,
        versionValid
      }
    }
  },
  beforeMount: async function() {
    await this.getLinks();
  },
  methods: {
    getLinks: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getLinks();
        resp.versions.forEach(app => {
          this[app.deviceType].link = app.link;
          this[app.deviceType].version = app.version;
        });
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    validateForm: async function(platform) {
      this.$v[platform].$touch();
      if (this.$v[platform].$error) return;
      await this.saveLink(platform);
    },
    saveLink: async function(platform) {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const appInfo = {};
        appInfo.link = this[platform].link;
        appInfo.version = Number(this[platform].version);
        await api.saveLink(platform, appInfo);
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 2rem 0;
}
.ios, .android {
  margin-right: 2rem;
}
.input-container {
  @extend %audi;
  font-size: 16px;
  margin: 1rem 0;
}
.input-container > label {
  @extend %audi-b;
}
.save-btn {
  @extend %audi;
  color: $white;
  background-color: $black;
  padding: 10px 20px;
  border: 1px solid $black;
  border-radius: 0px;
}
.error-msg {
  color: $warn_red;
  font-size: 14px;
}
.error-msg > p {
  margin: 0;
}
</style>