<template>
  <main id="bookingTransport" class="animated fadeIn">
    <router-view :key="componentKey" @reloadComponent="reloadComponent">
    </router-view>
  </main>
</template>

<script>
export default {
  data() {
    return {
      componentKey: new Date().getTime()
    }
  },
  methods: {
    reloadComponent () {
      this.componentKey = new Date().getTime();
    }
  }
};
</script>

<style lang="scss"  >

.VueTables__table .actions-column {
    position: sticky;
    right: 0;
    text-align: center;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #f2f2f2;
      top: 0;
      right: 0;
      border-collapse: collapse;
    }
  }
  .VueTables__table .actions-column > * {
    position: relative;
    z-index: 1;
  }
  .table-striped tbody tr:nth-of-type(odd) > .actions-column {
    &:after {
      background-color: #e6e6e6;
    }
  }
  .VueTables__table .id-column {
    max-width: 100px;
  }
  [class$="filter-wrapper"] {
    min-width: 80px;
    max-width: fit-content;
  }
 

  .VueTables thead  { 
            position: sticky;
            top: 0;
            z-index: 10;
            background-color: #fff;
        }
        .table-responsive { 
            height:600px;
            overflow:scroll;
        }
.VueTables .dropdown {
  margin: 1rem 0;
}
.VueTables__columns-dropdown {
  float: left !important;
}

.VueTables__columns-dropdown > button {
  color: #000 !important;
  background-color: transparent !important;
  border: 1px solid #000 !important;
  border-radius: 0 !important;
  padding: 5px 10px !important;
}
.VueTables .dropdown .dropdown-menu {
  padding: 1rem;
}
.VueTables .dropdown .dropdown-menu a {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.VueTables .dropdown .dropdown-menu a > input {
  margin-right: 0.5rem;
}

</style>