import i18n from "@/i18n";

export const mediaTypes = {
  audio: 'audio',
  video: 'video'
};

export const media = {
  audio: {
    key: '',
    url: ''
  },
  video: {
    key: '',
    url: ''
  },
  location: ''
};

export const mediaPath = {
  video: 'videos',
  audio: 'audios'
}

export const mediaPosition = {
  key: 'position',
  top: 'top',
  bottom: 'bottom'
}

export const mediaContentType = {
  video: 'video/mp4',
  audio: 'audio/mp3'
}

export const hasContent = (mediaContent) => {
  return Boolean(mediaContent.key || mediaContent.file);
}

export const isValidBody = (alertBody) => {
  const bodyObject = alertBody && alertBody.ops ? alertBody.ops : [];
  let hasBody = bodyObject.length > 1;

  if (hasBody === false) {
    const contentBody = bodyObject.length ? bodyObject[0] : {};
    const text = contentBody.insert ? contentBody.insert.replace(/\n/g, '') : "";
    hasBody = text.trim() !== "";
  }
  return hasBody;
}

export const language = {
  es: 'es',
  en: 'en'
};

export const locales = {
  es: 'es-MX',
  en: 'en-US'
}

export const wildcard = '*';

export const errorCodes = {
  openToMove: {
    OU_ALREADY_EXISTS: 'OPEN_TO_MOVE_OU_ALREADY_EXISTS',
    SUB_ALREADY_EXISTS: 'OPEN_TO_MOVE_OU_ALREADY_EXISTS'
  }
}

export const actions = {
  create: 'create'
}

export const status = {
  active: 'active',
  inactive: 'inactive'
}

export const openToMoveStatus = [
  {
    id: 1,
    key: "delivered",
    name: i18n.t('openToMove.application.status.delivered')
  },
  {
    id: 2,
    key: "seen",
    name: i18n.t('openToMove.application.status.seen')
  },
  {
    id: 3,
    key: "deleted",
    name: i18n.t('openToMove.application.status.deleted')
  }
];

export const openToMoveLicenseType = [
  {
    id: 1,
    key: "license",
    name: i18n.t('openToMove.application.licenseType.license')
  },
  {
    id: 2,
    key: "license_in_process",
    name: i18n.t('openToMove.application.licenseType.licenseInProgress')
  },
  {
    id: 3,
    key: "none",
    name: i18n.t('openToMove.application.licenseType.none')
  }
];

export const apiVersion = {
  v1: 'v1',
  v2: 'v2'
};

export const openToMoveConfig = {
  v1: {
    columns: {
      checkbox: 'checkbox',
      userName: 'userName',
      userLastName: 'userLastName',
      userMothersLastName: 'userMothersLastName',
      userControlNumber: 'userControlNumber',
      createdAt: 'createdAt',
      ou: 'ou',
      ouSub: 'ouSub',
      experience: 'experience',
      reasons: 'reasons',
      license: 'license',
      licenseType: 'licenseType',
      resume: 'resume',
      status: 'status',
      action: 'action'
    }
  },
  v2: {
    columns: {
      employeeType: 'employeeType',
      contractType: 'contractType',
      lengthEmployment: 'lengthEmployment',
      ouInterest: 'ouInterest',
      ouSubInterest: 'ouSubInterest',
      internationalMobility: 'internationalMobility',
      languages: 'languages',
      softwareExperience: 'softwareExperience',
      currentExperience: 'currentExperience'
    }
  }
}


export const openToMoveEmployeeTypeOptions = [
  {
    value: 'Directo',
    text: 'Directo'
  },
  {
    value: 'Indirecto',
    text: 'Indirecto'
  }
]

export const openToMoveContractTypeOptions = [
  {
    value: 'Temporal',
    text: 'Temporal'
  },
  {
    value: 'Indeterminado',
    text: 'Indeterminado'
  }
]


export const booleanOptions = [
  {
    value: true,
    text: i18n.t('global.yes')
  },
  {
    value: false,
    text: i18n.t('global.no')
  }
]

export const openToMoveCSV = {
  lengthEmployment: i18n.t('openToMove.application.tableOpenToMove.headings.lengthEmployment'),
  id: i18n.t('openToMove.application.tableOpenToMove.headings.id'),
  name: i18n.t('openToMove.application.tableOpenToMove.headings.userName'),
  lastName: i18n.t('openToMove.application.tableOpenToMove.headings.userLastName'),
  mothersLastName: i18n.t('openToMove.application.tableOpenToMove.headings.userMothersLastName'),
  createdAt: i18n.t('openToMove.application.tableOpenToMove.headings.createdAt'),
  controlNumber: i18n.t('openToMove.application.tableOpenToMove.headings.userControlNumber'),
  experience: i18n.t('openToMove.application.tableOpenToMove.headings.experience'),
  licenseType: i18n.t('openToMove.application.tableOpenToMove.headings.licenseType'),
  ou: i18n.t('openToMove.application.tableOpenToMove.headings.ou'),
  ouSub: i18n.t('openToMove.application.tableOpenToMove.headings.ouSub'),
  reasons: i18n.t('openToMove.application.tableOpenToMove.headings.reasons'),
  status: i18n.t('openToMove.application.tableOpenToMove.headings.status'),
  contractType: i18n.t('openToMove.application.tableOpenToMove.headings.contractType'),
  employeeType: i18n.t('openToMove.application.tableOpenToMove.headings.employeeType'),
  ouInterest: i18n.t('openToMove.application.tableOpenToMove.headings.ouInterest'),
  ouSubInterest: i18n.t('openToMove.application.tableOpenToMove.headings.ouSubInterest'),
  internationalMobility: i18n.t('openToMove.application.tableOpenToMove.headings.internationalMobility'),
  languages: i18n.t('openToMove.application.tableOpenToMove.headings.languages'),
  softwareExperience: i18n.t('openToMove.application.tableOpenToMove.headings.softwareExperience'),
}

export const lengthEmploymentOptions = [
  {
    value: '>1',
    text: '>1'
  },
  {
    value: '<1',
    text: '<1'
  },
  {
    value: '1',
    text: '1'
  }
]

export const languageOptions = [
  {
    value: i18n.t('global.languages.en'),
    text: i18n.t('global.languages.en')
  },
  {
    value: i18n.t('global.languages.de'),
    text: i18n.t('global.languages.de')
  }
]
export const integrityComplianceKey = {
  main: 'main',
  integrity: 'integrity',
  compliance: 'compliance',
  whistleblowingSystem: 'whistleblowingSystem',
  riskManagement: 'riskManagement'
}

export const integrityKey = {
  intro: 'intro',
  events: 'events',
  groupEssentials: 'groupEssentials',
  groupValues: 'groupValues',
  integrityForum: 'integrityForum',
  coffeeDate: 'coffeeDate',
  statistics: 'statistics',
  sanctions: 'sanctions',
  donations: 'donations'
}

export const compliceKey = {
  intro: 'intro',
  code_of_conduct: 'code_of_conduct',
  anticorruption: 'anticorruption',
  prevention_of_money_laundering: 'prevention_of_money_laundering',
  human_rights: 'human_rights',
  BPDD: 'BPDD'
}
export const yearOptions = () => {
  const currentYear = new Date().getFullYear();
  return [currentYear, currentYear - 1]
}

export const directorsLength = 3

export const ICfilesName = {
  intro: 'Inicio',
  integrity: {
    groupEssentials: 'Esenciales de grupo',
    groupValues: 'Valores de Audi',
    events: 'Eventos',
    integrityForum: 'Foro de integridad',
    coffeeDate: 'Tarde de café',
    sanctions: 'Sanciones',
    donations: 'Donaciones'
  },
  compliance: {
    code_of_conduct: 'Código de Conducta',
    anticorruption: 'Anticorrupción: Regalos e invitaciones',
    prevention_of_money_laundering: 'Prevención de lavado de dinero',
    human_rights: 'Derechos humanos',
    BPDD: 'BPDD'
  },
  riskManagement: 'Gestión de riesgos',
  whistleblowingSystem: {
    name: 'SistemaDenuncias',
    report: '¿Cómo hacer mi reporte?'
  }
}

export const maxSemesters = 2;
