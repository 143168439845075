<template>
    <div class="media">
        <div class="media__audio" v-if="requireAudio">
            <div class="media__audio--input">
                <label>{{ $t("components.mediaInput.audioTitle") }}</label>
                <FileUploader
                    :id="getAudioFileId"
                    :placeholder="$t('components.mediaInput.audioPlaceholder')"
                    type="audio/mpeg, audio/mp3"
                    validate="audio/mpeg"
                    :label="$t('global.browse')"
                    :fileUrl="audio.url"
                    :allowDelete="audio.allowDelete"
                    @setFile="setFile($event, mediaTypes.audio)"
                />
            </div>

            <div class="media__audio--preview" v-if="audio.preview">
                <audio controls :key="audio.key">
                    <source :src="audio.preview" type="audio/mpeg"/>
                    {{ $t('components.mediaInput.audioNotSupported') }}
                </audio>
            </div>
        </div>

        <div class="media__video" v-if="requireVideo">
            <div class="media__video--input">
                <label>{{$t("components.mediaInput.videoTitle")}}</label>
                <FileUploader
                    :id="getVideoFileId"
                    :placeholder="$t('components.mediaInput.videoPlaceholder')"
                    type="video/mp4"
                    validate="video/mp4"
                    :label="$t('global.browse')"
                    :fileUrl="video.url"
                    :allowDelete="video.allowDelete"
                    @setFile="setFile($event, mediaTypes.video)"
                />
            </div>

            <div class="media__video--preview" v-if="video.preview">
                <video width="60%" height="100%" controls  :key="video.key">
                    <source :src="video.preview" type="video/mp4">
                    {{ $t('components.mediaInput.videoNotSupported') }}
                </video>
            </div>
        </div>

        <div class="media__position" v-if="requirePosition">
            <label class="media__position--label"> {{ $t('components.mediaInput.position') }}</label>
            <div class="media__position--toggle">
                <span>{{ $t('components.mediaInput.positionTop') }}</span>
                <label class="switch switch-pill switch-outline-dark-alt mx-1">
                    <input
                        type="checkbox"
                        class="switch-input"
                        v-model="topPosition"
                        @click="onChangeTopPosition"
                    />
                    <span class="switch-slider"></span>
                </label>
               <span>{{ $t('components.mediaInput.positionBottom') }}</span>
            </div>

        </div>
    </div>
</template>

<script>

import { mediaTypes, mediaPosition } from "@/lib/constants.js";
const FileUploader = () => import("@/components/FileUploader");


export default {
    name: 'MediaInput',
    props: {
        id: {
            type: String,
            default: 'mediaInputId'
        },
        media: {
            type: Object,
            default: () => {
                return {
                    audio: { key: '', url: '' },
                    video: { key: '', url: '' },
                    location: ''
                }
            }
        },
        requireVideo: {
            type: Boolean,
            default: true
        },
        requireAudio: {
            type: Boolean,
            default: true
        },
        requirePosition: {
            type: Boolean,
            default: true
        }
    },
    components: {
        FileUploader
    },
    computed: {
        getAudioFileId () {
            const id = this.id ? this.id : 'mediaInput';
            return `${id}-audioFile`;
        },
        getVideoFileId () {
            const id = this.id ? this.id : 'mediaInput';
            return `${id}-videoFile`;
        }
    },
    data () {
        return {
            mediaTypes,
            mediaPositions: [
                {
                    label: this.$t("components.mediaInput.positionTop"),
                    value: "top"
                },
                {
                    label: this.$t("components.mediaInput.positionBottom"),
                    value: "bottom"
                },
            ],
            topPosition: false,
            audio: {
                key: new Date().getTime(),
                file: null,
                preview: null,
                url: null,
                allowDelete: false
            },
            video: {
                key: new Date().getTime(),
                file: null,
                preview: null,
                url: null,
                allowDelete: false
            }
        }
    },
    mounted () {
        this.setMedia(this.media.audio, mediaTypes.audio);
        this.setMedia(this.media.video, mediaTypes.video);
        this.setPosition(this.media.location);
    },
    methods: {
        setFile (file, type) {
            try {
                const preview = file ? URL.createObjectURL(file) : null;
                this[type] = {
                    key: new Date().getTime(),
                    url: file && file.name ? file.name : null,
                    file,
                    preview
                }                
                this.$emit('onChangeMedia',{ data: file, type });
            } catch (error) {
                console.error(error);
                this.$store.commit("global/SET_ALERT", {
                    msg: this.$t('components.mediaInput.errors.preview', {type}),
                    show: true,
                    type: "error"
                });
            }
        },
        onChangeTopPosition () {
            this.$emit('onChangeMedia',
            { 
                data: {
                    top: this.topPosition
                },
                type: mediaPosition.key
            });
        },
        setMedia (newMediaObj, type) {
            const file = newMediaObj.file ? newMediaObj.file : this[type].file;
            const url = newMediaObj.url ? newMediaObj.url : this[type].url;
            const preview = newMediaObj.preview ? newMediaObj.preview : url;
            if (file) {
                this.setFile(file, type);
                this[type].url = file.name ? file.name : null;
                this[type].allowDelete = true;
            } else {
                this[type] = {
                    key: new Date().getTime(),
                    file,
                    preview, 
                    url,
                    allowDelete: Boolean(url)
                };
            }
        },
        setPosition (newPosition) {
           this.topPosition = Boolean(newPosition === mediaPosition.bottom);
        }
    },
    beforeUnmount () {
        if (this.video.file && this.video.preview) {
            URL.revokeObjectURL(this.video.preview);
        }
        if (this.audio.file && this.audio.preview) {
            URL.revokeObjectURL(this.audio.preview);
        }
    }
}

</script>

<style lang="scss" scoped>
    .media {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 18px;
        margin: $global_padding 0px;
        &__audio, &__video {
            width: 100%;
            &--preview {
                display: flex;
                height: 100%;
                width: 100%;
                margin-top: $global_padding - 4px;
                video {
                    max-height: 250px;
                }
            }
        }
        &__video {
            &--input {
                margin-top: $global_padding;
            }
        }
        &__position {
            width: 100%;
            display: flex;
            margin-top: $global_padding;
            &--toggle {
                display: flex;
                margin-left: $global_padding;
            }
        }
        label {
            @extend %audi-b;
        }
        .error-msg {
            color: $warn_red;
            font-size: 14px;
        }
    }
</style>