import axios from "axios";

export default class Legal {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getGeneral(legal) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/generals/${legal}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async postGeneral(body, legal) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/generals`,
        data: {
          name: legal,
          title: legal === 'Legal-Terms' ? 'Términos y condiciones' : 'Política de privacidad',
          author: " ",
          body: body
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async putGeneral (dataToUpdate, legal) {
    try {
      const { body, publish } = dataToUpdate;
      const response = await axios({
        method: "put",
        url: `${this.API_LINK}/bin/generals/${legal}`,
        data: {
          name: legal,
          title: legal === 'Legal-Terms' ? 'Términos y condiciones' : 'Política de privacidad',
          author: " ",
          body: body,
          publish
        }
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
}
