import { render, staticRenderFns } from "./BookingTransport.vue?vue&type=template&id=4950f7a3&"
import script from "./BookingTransport.vue?vue&type=script&lang=js&"
export * from "./BookingTransport.vue?vue&type=script&lang=js&"
import style0 from "./BookingTransport.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports