import axios from "axios";

export default class MenuAPI {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getMenu() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/menu`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async uploadMenu(bucket, key) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/menu/syncDiningMenu`,
        data: {
          srcBucket: bucket,
          src: key
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async postMenuFile(url, fileName) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/files/`,
        data: {
          fileName: fileName,
          source: url
        }
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }
  async getMenuFiles() {
    try {
      const requests = [
        axios({
          method: "get",
          url: `${this.API_LINK}/bin/files/prod_menu`
        })
      ];
      const response = await Promise.all(requests);
      return response.map(resp => resp.data);
    } catch (error) {
      throw error;
    }
  }
}
