<template>
  <div id="points-redeem">
    <h1>{{$t('ideas.points.redeem.title')}}</h1>
    <!-- Search control number -->
    <div class="form-container control-number">
      <div class="input-container">
        <label for="controlNum">{{$t("ideas.points.redeem.form.controlNum")}}</label>
        <input
        type="text"
        id="controlNum"
        name="controlNum"
        :placeholder="$t('ideas.points.redeem.form.controlNum_ph')"
        v-model.number="$v.controlNumber.$model">
        <div v-if="$v.controlNumber.$error || userNotFound" class="error-msg">
          <p v-if="!$v.controlNumber.required">{{$t("ideas.points.redeem.errors.controlNum_required")}}</p>
          <p v-if="!$v.controlNumber.numeric">{{$t("ideas.points.redeem.errors.controlNum_numeric")}}</p>
          <p v-if="userNotFound">{{$t("ideas.points.redeem.errors.notFound")}}</p>
        </div>
      </div>
      <button
      type="button"
      class="search-btn"
      @click="validateControlNum">{{$t('ideas.points.redeem.form.search')}}</button>
    </div>
    <!-- Redeem points -->
    <div v-if="pointsForm" class="form-container">
      <div v-if="user" class="user-info">
        <p><strong>{{$t("ideas.points.redeem.form.user_name")}}:</strong> {{userData.name}}</p>
        <p><strong>{{$t("ideas.points.redeem.form.user_cn")}}:</strong> {{userData.controlNumber}}</p>
        <p><strong>{{$t("ideas.points.redeem.form.user_points")}}:</strong> {{userData.points}}</p>
      </div>
      <div class="input-container">
        <label for="points">{{$t("ideas.points.redeem.form.points")}}</label>
        <input
        type="text"
        id="points"
        name="points"
        :placeholder="$t('ideas.points.redeem.form.points_ph')"
        v-model.number="$v.points.$model">
        <div v-if="$v.points.$error" class="error-msg">
          <p v-if="!$v.points.required">{{$t("ideas.points.redeem.errors.points_required")}}</p>
          <p v-if="!$v.points.numeric">{{$t("ideas.points.redeem.errors.points_numeric")}}</p>
          <p v-if="!$v.points.isValid">
            {{pointsErrorMsg}}
          </p>
        </div>
      </div>
      <button
      type="button"
      class="redeem-btn"
      @click="validatePoints">{{$t('ideas.points.redeem.form.redeem')}}</button>
    </div>
    <!-- Modal -->
    <Modal
    v-if="modalActive"
    v-on:closeModal="closeModal">
      <!-- <template #header>
        <h6 class="modal-title">{{$t('ideas.top.list.modal.title')}}</h6>
      </template> -->
      <template>
        <p v-if="appLang == 'en'">Are you sure you want to redeem <strong>{{points}}</strong> points to Control Number: <strong>{{controlNumber}}</strong>?</p>
        <p v-else>¿Seguro que quieres canjear <strong>{{points}}</strong> {{points == 1 ? "punto" : "puntos"}} al Número de Control: <strong>{{controlNumber}}</strong>?</p>
      </template>
      <template #footer>
        <div class="modal-controls">
          <span
          class="modal--cancel-btn"
          @click="closeModal">{{$t('ideas.points.redeem.modal.cancel')}}</span>
          <button
          type="button"
          class="modal--delete-btn"
          :disabled="isLoading"
          @click="redeemPoints">{{$t('ideas.points.redeem.modal.redeem')}}</button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { required, numeric, between } from "vuelidate/lib/validators";
import API_SERVICE from "@/lib/api/Ideas.js";
const Modal = () => import("@/components/Modal");
const api = new API_SERVICE();

export default {
  components: {
    Modal
  },
  data: function() {
    return {
      controlNumber: null,
      pointsForm: false,
      points: null,
      modalActive: false,
      user: null,
      userNotFound: false
    };
  },
  computed: {
    appLang: function() {
      return this.$store.getters["settings/currentLang"];
    },
    isLoading: function() {
      return this.$store.getters["global/isLoading"];
    },
    userData: function(){
      if (this.user) return {
        name: `${this.user.name} ${this.user.lastName} ${this.user.mothersLastName}`,
        controlNumber: this.user.controlNumber,
        points: this.user.score.ideas ? this.user.score.ideas : 0
      }
      return null;
    },
    pointsErrorMsg: function() {
      if (!this.userData) return "";
      if (this.appLang == 'en' && this.userData.points === 0) return "You can't redeem points to this user";
      if (this.appLang == 'es' && this.userData.points === 0) return "No puedes canjear puntos para este usuario";
      if (this.appLang == 'en' && this.userData.points > 0) return `Points should be between 1 and user's points`;
      if (this.appLang == 'es' && this.userData.points > 0) return `Los puntos deben ser entre 1 y los puntos del usuario`;
    }
  },
  watch: {
    controlNumber: function(newVal) {
      if (this.userNotFound) this.userNotFound = false;
    }
  },
  validations: {
    controlNumber: {
      required,
      numeric
    },
    points: {
      required,
      numeric,
      isValid: function(val) {
        if (val === "" || val === null) return true;
        if (val < 1 || val > this.userData.points) return false;
        return true;
      }
    }
  },
  methods: {
    validateControlNum: async function() {
      this.$v.controlNumber.$touch();
      if (this.$v.controlNumber.$error) return;
      this.$store.commit("global/SET_LOADING", true);
      this.pointsForm = false;
      this.user = null;
      try {
        const resp = await api.getUserData(this.controlNumber);
        this.user = resp;
        this.pointsForm = true;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        if (error.response.status == 404) {
          this.userNotFound = true;
        } else {
          throw error;
        }
      }
    },
    validatePoints: function() {
      this.$v.points.$touch();
      if (this.$v.points.$error) return;
      this.modalActive = true;
    },
    closeModal: function() {
      this.modalActive = false;
    },
    redeemPoints: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        await api.redeemPoints(this.userData.controlNumber, this.points);
        this.closeModal();
        this.controlNumber = null;
        this.points = null;
        this.pointsForm = false;
        this.user = null;
        this.$v.$reset();
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  width: 90%;

  @media (min-width: 769px) {
    width: 45%;
  }
  @media (min-width: 1216px) {
    width: 25%;
  }
  @media (min-width: 1408px) {
    width: 25%;
  }
}
.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  margin: 20px 0px;

  label {
    @extend %audi-b;
  }
}
.form-container.control-number {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.search-btn {
  @extend %audi;
  color: $black;
  background-color: transparent;
  border: 1px solid $black;
  padding: 5px 10px;
}
.redeem-btn {
  @extend %audi-b;
  color: $white;
  background-color: $black;
  padding: 10px 20px;
  border: 1px solid $black;
  border-radius: 0px;
}

.modal-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.modal-controls > * {
  margin-left: 15px;
  font-size: 16px;
  cursor: pointer;
}
.modal--cancel-btn {
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    text-decoration: underline;
  }
}
.modal--delete-btn {
  @extend %audi-b;
  color: $white;
  background-color: $black;
  border-color: $black;
  padding: 10px 20px;
  border: 1px solid $black;
  border-radius: 0px;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    background-color: $warn_red;
    border-color: $warn_red;
  }
}

.error-msg {
  color: $warn_red;
  font-size: 14px;
  p {
    margin: 0;
  }
}
</style>