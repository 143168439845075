<template>
  <div id="points-score">
    <h1>{{$t('ideas.points.score.title')}}</h1>
    <div class="table">
      <table v-if="score" class="score">
        <thead>
          <tr>
            <th>{{$t("ideas.points.score.table.actions")}}</th>
            <th>{{$t("ideas.points.score.table.points")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(action, index) in $v.score.$each.$iter" :key="index">
            <th>{{action.$model[getActionLabel]}}</th>
            <td>
              <!-- <i class="far fa-edit"></i> -->
              <input
              type="number"
              :name="`action${action.$model.id}`"
              :id="`action${action.$model.id}`"
              v-model.number="action.points.$model">
              <div v-if="action.points.$error" class="error-msg">
                <p v-if="!action.points.required">{{$t("ideas.points.score.errors.points_required")}}</p>
                <p v-if="!action.points.numeric">{{$t("ideas.points.score.errors.points_numeric")}}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <button
      class="publish-btn"
      @click="validateForm">{{$t("ideas.points.score.update")}}</button>
    </div>
  </div>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import API_SERVICE from "@/lib/api/Ideas.js";
import MOCK_DATA from "@/mockData/Points.json";
const api = new API_SERVICE();

export default {
  data: function(){
    return {
      score: null
    };
  },
  computed: {
    getLang: function() {
      return this.$store.getters["settings/currentLang"];
    },
    getActionLabel: function() {
      if (this.score && this.getLang == "en") return "nameEn";
      return "nameEs";
    }
  },
  validations: {
    score: {
      $each: {
        points: {
          required,
          numeric
        }
      }
    }
  },
  beforeMount: async function() {
    await this.getScore();
  },
  methods: {
    getScore: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getScore();
        if (resp.length) this.score = resp;
        else {
          await this.setScore(MOCK_DATA.score);
          await this.getScore();
        }
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    validateForm: async function() {
      this.$v.$touch();
      if (this.$v.$error) return;
      await this.setScore();
    },
    setScore: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        await api.setScore(this.score);
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  margin: 2rem 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.table .score {
  margin: 1rem;
}
.error-msg {
  color: $warn_red;
  font-size: 14px;
  p {
    margin: 0;
  }
}
.publish-btn {
  @extend %audi-b;
  color: $white;
  background-color: $black;
  padding: 10px 20px;
  border: 1px solid $black;
  border-radius: 0px;
  align-self: flex-end;
}
</style>