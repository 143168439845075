<template>
  <div id="blog-list">
    <header class="header">
      <div class="title">
        <h1>{{ $t("news.list.title") }}</h1>
        <p>
          {{ $t("news.list.directions1") }}
          <br />
          {{ $t("news.list.directions2") }}
        </p>
      </div>
      <div class="controllers">
        <button
          type="button"
          class="btn btn-dark control new-btn"
          @click="$router.push({ name: 'Blog New' })"
        >
          {{ $t("news.list.new") }}
        </button>
      </div>
    </header>
    <!-- List table -->
    <v-client-table
      v-if="newsList"
      :columns="table_options.columns"
      :options="table_options.options"
      :data="newsList"
    >
      <template v-slot:updated_at="props">{{
        props.row.updated_at | formatDate(appLang)
      }}</template>
      <template v-slot:top="props">
        <div class="starred">
          <i v-if="props.row.top" class="fas fa-star"></i>
        </div>
      </template>
      <template v-slot:report="props">
        <div>
          <span v-if="props.row.hasCommentsReported" class="report-warning">
            <i class="fas fa-exclamation-triangle"></i> Reporte
          </span>
        </div>
      </template>
      <template v-slot:actions="props">
        <div class="actions">
          <!-- Edit -->
          <router-link
            :to="{
              name: 'Blog Edit',
              params: {
                id: props.row.slug
              }
            }"
          >
            <i class="icons-black far fa-edit"></i>
          </router-link>
          <!-- Delete -->
          <i
            class="icons-black far fa-trash-alt"
            @click="openModal(props.row)"
          ></i>
          <!-- Details -->
          <router-link
            :to="{
              name: 'Blog Details',
              params: {
                id: props.row.slug
              }
            }"
          >
            <i class="icons-black far fa-eye"></i>
          </router-link>
          <router-link
            v-if="props.row.likes > 0"
            :to="{
              name: 'Blog Likes',
              params: {
                id: props.row.slug
              }
            }"
          >
            <i class="icons-black fas fa-heart"></i>
          </router-link>
        </div>
      </template>
    </v-client-table>
    <!-- Modal -->
    <Modal v-if="modalActive" @closeModal="closeModal">
      <template #header>
        <h6 class="modal-title">{{ $t("news.list.modal.title") }}</h6>
      </template>
      <template>
        <strong>{{ $t("news.list.modal.body") }}</strong>
        <p>{{ postToDelete.title }}</p>
      </template>
      <template #footer>
        <div class="controls">
          <span class="control cancel-btn" @click="closeModal">{{
            $t("news.list.modal.cancel")
          }}</span>
          <button
            type="control button"
            class="btn btn-dark control delete-btn"
            :disabled="isLoading"
            @click="deletePost"
          >
            {{ $t("news.list.modal.delete") }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ClientTable } from "vue-tables-2";
import API_Service from "@/lib/api/News.js";
const Modal = () => import("@/components/Modal");

export default {
  components: {
    ClientTable,
    Modal
  },
  data: function() {
    return {
      newsList: null,
      modalActive: false,
      postToDelete: null,
      isLoading: false
    };
  },
  computed: {
    table_options: function() {
      return {
        columns: [
          "title",
          "updated_at",
          "top",
          "likes",
          "comments",
          "report",
          "visited",
          "actions"
        ],
        options: {
          texts: {
            filter: `${this.$t("components.vue_tables.filter")}:`,
            filterPlaceholder: this.$t("components.vue_tables.filter_ph")
          },
          headings: {
            title: "Título",
            updated_at: "Fecha",
            top: "Destacado",
            likes: "Reacciones",
            comments: "Comentarios",
            report: "Reportes",
            visited: "Visto",
            actions: "Acciones"
          },
          sortable: [
            "title",
            "updated_at",
            "top",
            "likes",
            "comments",
            "report",
            "visited"
          ],
          customSorting: {
            report: function(ascending) {
              return function(a, b) {
                if (ascending) {
                  return !a.hasCommentsReported ? 1 : -1;
                }
                return a.hasCommentsReported ? 1 : -1;
              };
            }
          },
          filterable: ["title", "date"],
          sortIcon: {
            base: "fas",
            up: "fa-sort-up",
            down: "fa-sort-down",
            is: "fa-sort"
          },
          columnsClasses: {
            title: "title-column",
            report: "report-column",
            actions: "actions-column",
            updated_at: "updatedAt-column"
          },
          resizableColumns: false
        }
      };
    },
    appLang: function() {
      return this.$store.getters["settings/currentLang"];
    }
  },
  beforeMount: async function() {
    await this.getNewsList();
  },
  methods: {
    getNewsList: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new API_Service();
      try {
        const response = await api.getNewsList(this.appLang);
        this.newsList = response;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    openModal: function(post) {
      this.postToDelete = post;
      this.modalActive = true;
    },
    closeModal: function() {
      this.postToDelete = null;
      this.modalActive = false;
    },
    deletePost: async function() {
      this.$store.commit("global/SET_LOADING", true);
      this.isLoading = true;
      const api = new API_Service();
      try {
        const getIdsReq = [
          api.getPostIds(this.postToDelete.slug, "es"),
          api.getPostIds(this.postToDelete.slug, "en")
        ];
        const postIds = await Promise.all(getIdsReq);
        const deleteRequests = postIds.map(postId => {
          // console.log(postId);
          if (postId) {
            return api.deletePost(this.postToDelete.slug, postId);
          }
        });
        await Promise.all(deleteRequests);
        this.isLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        this.closeModal();
        this.getNewsList();
      } catch (error) {
        this.isLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#blog-list {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .new-btn {
    background-color: $black;
    color: $white;
    border-radius: 0;
    padding: 0.5rem 2rem;
  }
  .report-warning {
    padding: 5px 10px;
    border: 1px solid $bg_2;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    > * {
      margin-right: 5px;
    }
  }
  .actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .actions > * {
    cursor: pointer;
  }
  .actions > .edit-btn {
    color: $black;

    &:hover {
      color: $black;
    }
  }

  .modal-title {
    margin: 0;
  }
  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .controls .control {
    margin-left: 15px;
    font-size: 16px;
    cursor: pointer;
  }
  .control.cancel-btn {
    transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

    &:hover {
      text-decoration: underline;
    }
  }
  .control.delete-btn {
    @extend %audi-b;
    color: $white;
    background-color: $black;
    border-color: $black;
    padding: 10px 20px;
    border-radius: 0px;
    transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

    &:hover {
      background-color: $warn_red;
      border-color: $warn_red;
    }
  }

  .starred {
    display: flex;
    justify-content: center;
  }
}
</style>

<style lang="scss">
#blog-list {
  .VueTables__table > thead th {
    vertical-align: middle;
    width: auto;
  }
  .VueTables__table > thead th.title-column {
    width: 35%;
  }
  .VueTables__table > thead th.actions-column {
    width: 20%;
  }
  .VueTables__table > tbody td {
    vertical-align: middle;
  }
  .VueTables__table > tbody td.report-column div {
    display: flex;
    justify-content: center;
  }
}
</style>
<style>
.icons-black {
  color: #000;
  font-size: 16px;
  cursor: pointer;
}
#blog-list .VueTables__table > thead th.VueTables__sortable {
  width: 13% !important;
}
.form-inline label {
  display: initial;
}
</style>
