<template>
  <div class="trips">
    <div class="row bg-white">
      <div class="col-md-12 top py-2">
        <router-link
          class="return"
          :to="{
            name: 'Trips',
            params: {
              travelStatus: this.travelStatus
            }
          }"
        >
          <i class="fas fa-chevron-left"></i>
          {{ $t("events.newEvent.back") }}
        </router-link>
      </div>
      <div class="col-md-12 px-5 py-3 d-flex justify-content-between">
        <h1 class="labelBold">
          {{ this.$t(`qrAccess.travels.details.${travelStatus}`) }}
        </h1>
        <button class="btn-download mr-5" @click="downloadTable">
          {{ $t("events.listEvents.donwloadBtn") }}
        </button>
      </div>
      <div class="col-md-6 px-5 mt-3">
        <h5 class="labelBold">{{ $t("qrAccess.travels.details.travel") }}</h5>
        <label class="mt-3 labelBold">{{
          $t("qrAccess.travels.details.status")
        }}</label>
        <p> {{ this.$t(`qrAccess.travels.details.tripStatus.${travelStatus}`) }}</p>
        <label class="mt-2 labelBold">{{
          $t("qrAccess.travels.details.idTravel")
        }}</label>
        <p>{{travel.id}}</p>
        <label class="mt-2 labelBold">{{
          $t("qrAccess.travels.details.shift")
        }}</label>
        <p>{{travel.workingShift}}</p>
        <label class="mt-2 labelBold">{{
          $t("qrAccess.travels.details.route")
        }}</label>
        <p>{{travel.busRouteName}}</p>
        <label class="mt-2 labelBold">{{
          $t("qrAccess.travels.details.busNumber")
        }}</label>
        <p>{{travel.busNumber}}</p>
        <label class="mt-2 labelBold">{{
          $t("qrAccess.travels.details.address")
        }}</label>
        <p>{{travel.busDirection}}</p>
        <!-- Trip  -->
        <div class="moreInfo" v-if="onGoingOrDone">
          <label class="mt-2 labelBold">{{
            $t("qrAccess.travels.details.locationCoordinates")
          }}</label>
          <p>{{travel.latitude}}, {{travel.longitude}}</p>
          <label class="mt-2 labelBold">{{
            $t("qrAccess.travels.details.dateAndTimeApproach")
          }}</label>
          <p>{{travel.checkinDate}}</p>
          <label class="mt-2 labelBold">{{
            $t("qrAccess.travels.details.dateAndTimeTravel")
          }}</label>
          <p>{{travel.travelStartDate}}</p>
          <label class="mt-2 labelBold" v-if="tripDone">{{
            $t("qrAccess.travels.details.dateAndTimeTravelEnd")
          }}</label>
          <p v-if="tripDone">{{travel.travelEndDate}}</p>
          <label class="mt-2 labelBold" v-if="tripDone">{{
            $t("qrAccess.travels.details.travelDuration")
          }}</label>
          <p v-if="tripDone">{{travel.travelDuration}}</p>
        </div>
      </div>
      <div class="col-md-6 px-5 mt-3 mb-5">
        <h5 class="labelBold">{{ $t("qrAccess.travels.details.driver") }}</h5>
        <div class="normalTrip" v-if="!onGoingOrDone">
          <label class="mt-3 labelBold">{{
            $t("qrAccess.travels.details.driverName")
          }}</label>
          <p>{{travel.driverFullName}}</p>
          <label class="mt-2 labelBold">{{
            $t("qrAccess.travels.details.driverId")
          }}</label>
          <p>{{travel.controlNumber}}</p>
          <label class="mt-2 labelBold">{{
            $t("qrAccess.travels.details.company")
          }}</label>
          <p>{{travel.companyName}}</p>
          <label class="mt-2 labelBold">{{
            $t("qrAccess.travels.details.idProvider")
          }}</label>
          <p>{{travel.providerNumber}}</p>
          <!-- <label class="mt-2 labelBold">{{
            $t("qrAccess.travels.details.employeeNumber")
          }}</label>
          <p>0000 0000</p> -->
          <label class="mt-2 labelBold">{{
            $t("qrAccess.travels.details.phone")
          }}</label>
          <p>{{travel.personalCellphoneNumber}}</p>
        </div>
        <div v-if="onGoingOrDone">
          <label class="mt-3 labelBold">{{
            $t("qrAccess.travels.details.driverName")
          }}</label>
          <p>{{travel.driverFullName}}</p>
          <label class="mt-2 labelBold">{{
            $t("qrAccess.travels.details.driverId")
          }}</label>
          <p>{{travel.controlNumber}}</p>
          <div class="seePassangers">
            <router-link
              class="edit-btn"
              :to="{
                name: 'Trip Driver Detail',
                params: {
                  id: this.id,
                  data: travel,
                  details: this.details,
                  tripDone: this.tripDone,
                  onGoingOrDone: this.onGoingOrDone,
                }
              }"
            >
              <p>{{ $t("qrAccess.travels.details.moreInfoDriver") }}  ></p>
            </router-link>
          </div>
        </div>
        <!-- Pasejeros -->
        <div class="pasajeros" v-if="onGoingOrDone">
          <h5 class="labelBold mt-5 mb-2">
            {{ $t("qrAccess.travels.details.passengers") }}
          </h5>
          <label class="mt-3 labelBold">{{
            $t("qrAccess.travels.details.numberPassangerApproach")
          }}</label>
          <p>{{travel.totalPassengers}}</p>
          <div class="seePassangers">
            <router-link
              class="edit-btn"
              :to="{
                name: 'Trip Passengers',
                params: {
                  id: this.id,
                  data: travel,
                  travelStatus: this.travelStatus,
                  details: this.details,
                  onGoingOrDone: this.onGoingOrDone,
                  tripDone: this.tripDone
                }
              }"
            >
              <p>{{ $t("qrAccess.travels.details.listPassegners") }}  ></p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import API_SERVICE from "@/lib/api/QRAccess.js";
  const moment = require("moment");
  const api = new API_SERVICE();
  export default {
    props: {
      id: {
        type: Number
      },
      travelStatus: {
        type: String
      },
      data: {
        type: Object
      },
      onGoingOrDone: {
        type: Boolean,
        default: false
      },
      tripDone: {
        type: Boolean,
        default: false
      },
      details: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        travel: {}
      };
    },
    async beforeMount() {
      await this.getDetailTravel();
      this.fillData();
    },
    // mounted() {
    // },
    methods: {
      getDetailTravel: async function() {
        // let currentLanguage = localStorage.getItem("lang");
        this.$store.commit("global/SET_LOADING", true);
        try {
          const resp = await api.detailTravel(this);
          this.travel = resp.travel;
        } catch (error) {
          console.log(error);
          this.$store.commit("global/SET_LOADING", false);
        } finally {
          this.$store.commit("global/SET_LOADING", false);
        }
      },
      fillData: function(){
        this.travel.checkinDate = moment(this.travel.checkinDate).format('DD/MM/YYYY HH:mm A');
        this.travel.travelStartDate = moment(this.travel.travelStartDate).format('DD/MM/YYYY HH:mm A');
        this.travel.travelEndDate = moment(this.travel.travelEndDate).format('DD/MM/YYYY HH:mm A');
      },
      downloadTable: async function(event) {
        console.log('Download trip details')
        return true;
        const filterParams = this.$refs.driversTable.query;
        this.$store.commit("global/SET_LOADING", true);
        try {
          const resp = await api.downloadBusDriversTable(filterParams);
          let anchor = document.createElement("a");
          anchor.href = resp.url;
          anchor.target = "_blank";
          anchor.click();
          this.$store.commit("global/SET_LOADING", false);
        } catch (error) {
          console.log(error);
          this.$store.commit("global/SET_LOADING", false);
        }
      }
    },
  };
</script>

<style>
  .trips .btn-download {
    padding: 0.7rem 2rem;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 0px;
  }
  .return {
    color: black;
    cursor: pointer;
  }
  .return:hover {
    text-decoration: underline !important;
    color: black;
  }
  .labelBold {
    font-weight: bold;
    color: #000;
  }
  .trips .seePassangers {
    width: 70%;
    text-align: right;
    font-weight: bold;
    color: #000;
  }
  #qraccess a {
    font-family: 'audi-extended', sans-serif;
    font-weight: normal;
    color: #000;
  }
</style>
