<template>
  <div id="ideas-edit">
    <header>
      <router-link
      class="back-btn"
      :to="{ name: 'Ideas List' }">
        <i class="fas fa-angle-left"></i>&nbsp;<strong>{{$t('ideas.top.edit.back')}}</strong>
      </router-link>
      <h1>{{$t("ideas.top.edit.title")}}</h1>
    </header>
    <div class="form-container">
      <div class="input-container">
        <label for="status">{{$t("ideas.top.edit.form.status")}}</label>
        <input
        v-if="idea"
        type="text"
        name="status"
        id="status"
        v-model="$v.idea.status.$model">
        <div v-if="$v.$error" class="error-msg">
          <p v-if="$v.idea.status.$error">{{$t("ideas.top.edit.errors.status_required")}}</p>
        </div>
      </div>
      <div class="input-container">
        <label>{{$t("ideas.top.edit.form.top")}}</label>
        <div class="top-check">
          <input
          v-if="idea"
          type="checkbox"
          name="top"
          id="top"
          v-model="idea.top">
          <label for="top">{{$t("ideas.top.edit.form.top_ph")}}</label>
        </div>
      </div>
      <button
      class="update-btn"
      @click="validateForm">{{$t("ideas.top.edit.form.update")}}</button>
    </div>
    <div v-if="idea" class="more-info">
      <h2>{{$t("ideas.top.edit.data.more")}}</h2>
      <div class="idea-data">
        <div
        v-for="(field, index) in ideaFields"
        :key="index"
        class="data-field">
          <label :for="idea[field]">{{$t(`ideas.top.edit.data.${field}`)}}</label>
          <div
          v-if="field == 'problemImage' || field == 'solutionImage'">
            <img
            :src="idea[field]"
            :alt="field">
          </div>
          <div
          v-else-if="field == 'isPossible' || field == 'needsExperiment'"
          class="boolean-field">
            <i
            v-if="idea[field]"
            class="fas fa-check"></i>
            <i
            v-else
            class="fas fa-times"></i>
          </div>
          <div
          v-else-if="field == 'createdAt' || field == 'updatedAt'">
            {{idea[field] | formatDate(appLang)}}
          </div>
          <p v-else>
            {{idea[field]}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import API_SERVICE from "@/lib/api/Ideas.js";
const api = new API_SERVICE();

export default {
  props: {
    id: {
      required: false
    }
  },
  data: function() {
    return {
      idea: null,
      ideaFields: [
        "id",
        "author",
        "responsible",
        "problem",
        "solution",
        "benefit",
        "isPossible",
        "needsExperiment",
        "problemImage",
        "solutionImage",
        "createdAt",
        "updatedAt"
      ]
    };
  },
  computed: {
    appLang: function() {
      return this.$store.getters["settings/currentLang"];
    }
  },
  validations: {
    idea: {
      status: {
        required
      }
    }
  },
  beforeMount: async function() {
    await this.getIdea();
  },
  methods: {
    getIdea: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getIdea(this.id);
        this.idea = resp;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    validateForm: async function() {
      this.$v.$touch();
      if (this.$v.$error) return;
      await this.updateIdea();
    },
    updateIdea: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        await api.updateIdea(this.id, this.idea);
        this.$store.commit("global/SET_LOADING", false);
        this.$router.push({
          name: "Ideas List"
        });
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.back-btn {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  color: $black;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  display: inline-block;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.form-container { 
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2rem 0;
}
.input-container {
  @extend %audi;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
.input-container > label {
  @extend %audi-b;
}
.top-check > * {
  font-weight: lighter;
  margin-right: 1rem;
}
.update-btn {
  align-self: center;
  background-color: $black;
  color: $white;
  border: 1px solid $black;
  border-radius: 0;
  padding: 0.5rem 2rem;
  margin-left: 1rem;
}

.more-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 16px;
}
.more-info > h2 {
  width: 100%;
}
.idea-data {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.data-field {
  width: 45%;
  margin: 0.75rem 0;
}
.data-field > label {
  @extend %audi-b;
  margin-bottom: 0.25rem;
}
.data-field > p {
  margin: 0;
}
.boolean-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.error-msg {
  color: $warn_red;
  font-size: 14px;
}
</style>