import BENEFITS from "@/mockData/BenefitsList.json";
import axios from "axios";

export default class BenefitsAPI {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getBenefitsList() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/map/benefits/`
      });
      return response.data.benefits;
    } catch (error) {
      throw error;
    }
  }

  async getCategories() {
    try {
      // const response = BENEFITS;
      // return response.categories;
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/map/benefits/getCategories`
      });
      return response.data.categories;
    } catch (error) {
      throw error;
    }
  }

  async getBenefits(id) {
    try {
      // const response = BENEFITS.data.filter(benefit => benefit.id == id);
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/map/benefits/${id}`
      });
      // console.log(response);
      // return response[0];
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async uploadBenefit(benefit) {
    try {
      // console.log(benefit);
      // benefit.id = BENEFITS.data[BENEFITS.data.length - 1].id + 1;
      // console.log(benefit);
      // benefit.id = benefit.id + 1;
      // console.log(benefit.id);
      // BENEFITS.data.push(benefit);
      try {
        const response = await axios({
          method: "post",
          url: `${this.API_LINK}/map/benefits/`,
          data: benefit
        });
        return response;
      } catch (error) {
        throw error;
      }
    } catch (error) {
      throw error;
    }
  }
  async deleteBenefit(id) {
    try {
      // const data = BENEFITS.data.filter(benefit => benefit.id != id);
      // BENEFITS.data = data;
      try {
        const response = await axios({
          method: "delete",
          url: `${this.API_LINK}/map/benefits/${id}`
        });
        return response;
      } catch (error) {
        throw error;
      }
    } catch (error) {
      throw error;
    }
  }
  
  async getEmail() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/map/benefits/email`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async saveEmail(email) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/map/benefits/email`,
        data: {
          email: email
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async updateEmail(email, emailID) {
    try {
      const response = await axios({
        method: "patch",
        url: `${this.API_LINK}/map/benefits/email/${emailID}`,
        data: {
          email: email
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async deleteEmail(emailID) {
    try {
      const response = await axios({
        method: "delete",
        url: `${this.API_LINK}/map/benefits/email/${emailID}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}