<template>
  <div class="serviceLineDetails bg-white p-3">
    <div class="row mb-3">
      <div class="col-md-12 top">
        <router-link
          class="return"
          :to="{
            name: 'Service Line List'
          }"
        >
          <i class="fas fa-chevron-left"></i>
          {{ $t("events.newEvent.back") }}
        </router-link>
        <h1 class="mt-2">{{ $t("serviceLine.aplicationDetail") }}</h1>
      </div>
    </div>

    <div class="row p-5">
      <div class="col-md-4 pl-5">
        <label class="bolded">{{
          $t("serviceLine.table.headings.folio")
        }}</label>
        <p>{{ serviceObj.folio }}</p>

        <label class="bolded mt-3">{{
          $t("serviceLine.table.headings.fullName")
        }}</label>
        <p>{{ serviceObj.fullName }}, {{ serviceObj.controlNumber }}</p>

        <label class="bolded mt-3">{{
          $t("serviceLine.table.headings.requestedService")
        }}</label>
        <p>{{ serviceObj.requestedService }}</p>

        <label class="bolded mt-3">{{
          $t("serviceLine.locationReport")
        }}</label>
        <p>{{ serviceObj.fullLocation }}</p>

        <label class="bolded mt-3">{{
          $t("serviceLine.table.headings.status")
        }}</label>
        <p>{{ serviceObj.formatStatus }}</p>

        <label class="bolded mt-3">{{
          $t("serviceLine.table.headings.reportDate")
        }}</label>
        <p>{{ serviceObj.formatDateReport }}</p>
      </div>
      <div class="col-md-8 pl-5 right-col">
        <div class="right-col-text">
          <h6 class="bolded">{{ $t("serviceLine.reportDetails") }}</h6>
          <label class="bolded mt-3">{{ $t("serviceLine.phone") }}:</label>
          <p>{{ serviceObj.phone }}</p>

          <label class="bolded mt-1">{{ $t("serviceLine.email") }}:</label>
          <p>{{ serviceObj.email }}</p>

          <label label class="bolded mt-3"
            >{{ $t("serviceLine.incidentDescription") }}:</label
          >
          <p>{{ serviceObj.incidentDescription }}</p>

          <label label class="bolded mt-3"
            >{{ $t("serviceLine.additionalInformation") }}:</label
          >
          <p>{{ serviceObj.additionalIncidentInformation }}</p>
        </div>
        <!-- Pintar Imagen -->
        <div v-if="!serviceObj.image" class="gray-serviceLine">
          <span>No Image</span>
        </div>

        <div v-else class="img-background">
          <img @click="openModal()" :src="serviceObj.image" />
        </div>

        <div class="line-gray"></div>

        <label class="bolded mt-4">{{ $t("serviceLine.solutionDate") }}</label>
        <p v-if="serviceObj.status === 'open' || serviceObj.status === 'waitingForApproval'">
          {{ $t("serviceLine.noSolutionDate") }}
        </p>
        <p v-else>{{ serviceObj.solutionDate }}</p>
        <label v-if="serviceObj.status === 'closed'" class="bolded mt-4">{{
          $t("serviceLine.detComSol")
        }}</label>
        <p v-if="serviceObj.status === 'closed'">
          {{ $t("serviceLine.report") }} {{ serviceObj.requestedService }}
          {{ $t("serviceLine.withFolio") }} {{ serviceObj.folio }}
          {{ $t("serviceLine.solved") }}:
        </p>
        <p v-if="serviceObj.status === 'closed'">
          {{ serviceObj.serviceLineComment }}
        </p>
      </div>
    </div>
    <Modal v-if="modalActive" @closeModal="closeModal">
      <template #header></template>
      <template>
        <div class="imgModal">
          <img :src="serviceObj.image" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import API_SERVICE from "@/lib/api/serviceLine.js";
import S3_Handler from "@/lib/api/s3_handler";
const api = new API_SERVICE();
const s3 = new S3_Handler();
const Modal = () => import("@/components/Modal");

import moment from "moment";
export default {
  components: {
    Modal
  },
  data() {
    return {
      serviceObj: null,
      modalActive: false
    };
  },
  async beforeMount() {
    await this.getServiceInformation();
  },
  methods: {
    openModal: function() {
      this.modalActive = true;
    },
    closeModal: function() {
      this.modalActive = false;
    },
    async getServiceInformation() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        let serviceId = this.$route.params.id;
        const response = await api.getServiceById(serviceId);
        let reportDateFormat = moment(response.service.createdAt).format(
          "DD/MM/YY, HH:mm a"
        );
        let solutionDateFormat = moment(response.service.updatedAt).format(
          "DD/MM/YY, HH:mm a"
        );
        if (response.service.image) {
          const signedUrl = s3.getSignedUrl(response.service.image);
          response.service.image = signedUrl;
        } else {
          response.service.image = "";
        }
        if (!response.service.generalLocation) {
          response.service.generalLocation = "";
        }
        if (!response.service.secondaryLocation) {
          response.service.secondaryLocation = "";
        }
        if (!response.service.technicalLocation) {
          response.service.technicalLocation = "";
        }
        if (!response.service.code) {
          response.service.code = "";
        }
        response.service.formatDateReport = reportDateFormat;
        response.service.solutionDate = solutionDateFormat;
        response.service.fullLocation = `${response.service.code} ${
          response.service.generalLocation
        } ${response.service.secondaryLocation} ${
          response.service.technicalLocation
        }`;
        switch (response.service.status) {
          case "open":
            response.service.formatStatus = "Open"; //this.$t("serviceLine.open")
            break;
          case "closed":
            response.service.formatStatus = "Closed"; //this.$t("serviceLine.closed")
            break;

          case "waitingForApproval":
            response.service.formatStatus = "Waiting for Approval"; //this.$t("serviceLine.closed")
            break;
        }
        this.serviceObj = response.service;
      } catch (error) {
        console.log(error);
        this.$store.commit("global/SET_LOADING", false);
      } finally {
        this.$store.commit("global/SET_LOADING", false);
      }
    }
  }
};
</script>

<style>
.return {
  color: black;
}
.return:hover {
  color: black;
}
.bolded {
  font-weight: bold;
}
.right-col p {
  margin: 0;
}
.right-col {
  position: relative;
}
.line-gray {
  background: #c4c4c4;
  height: 2px;
  margin-top: 6rem;
}
.gray-serviceLine {
  width: 20rem;
  height: 200px;
  background: #c4c4c4;
  position: absolute;
  right: 10px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-background {
  position: absolute;
  right: 10px;
  top: 0;
}
.img-background img {
  width: 20rem;
  height: 200px;
  cursor: pointer;
}
.right-col-text {
  width: 50%;
}
.serviceLineDetails .modal-container {
  max-width: 45rem !important;
  max-height: 60rem !important;
}
.serviceLineDetails .modal-container footer {
  display: none;
}
.imgModal img {
  width: 35rem;
  height: 25rem;
}
</style>
