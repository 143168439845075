<template>
  <div id="blog-details">
    <!-- Back Button -->
    <router-link class="back-btn" :to="{ name: 'Blog List' }">
      <i class="fas fa-angle-left"></i>&nbsp;
      <strong>{{$t('news.details.back_btn')}}</strong>
    </router-link>
    <!-- Header -->
    <header class="header">
      <div class="title">
        <h1>{{$t('news.details.titleLike')}}</h1>
      </div>
    </header>
    <!-- Post Details -->
    <section v-if="likesObj" class="post-details">
      <header class="main-data">
        <div class="data">
          <!-- Post title -->
          <!-- <h2>{{postLangContent.title}}</h2> -->
          <h2>{{likesObj.title}}</h2>

          <!-- Report warning -->
          <!-- v-if="postHasReports" -->
          <div class="report-warning">
            <i class="fas fa-exclamation-triangle"></i>&nbsp;
            <span>{{$t('news.details.report_warning')}}</span>
          </div>
          <!-- Created -->
          <!--  -->
          <div class="post-data">
            <strong>{{$t("news.details.post.date")}}</strong>
            : {{likesObj.created_at | formatDate(appLang)}}
          </div>
          <!-- Likes -->
          <!--  -->
          <div class="post-data">
            <strong>{{$t("news.details.post.likes")}}</strong>
            : {{likesObj.likes}}
          </div>
        </div>
        <!-- Edit Post -->
        <router-link
          class="edit-link"
          :to="{
          name: 'Blog Edit',
          params: {
            id: id
          }
        }"
        >
          <strong>{{$t("news.details.post.edit")}}</strong>&nbsp;
          <i class="fas fa-angle-right"></i>
        </router-link>
      </header>
      <div class="comment-list">
        <ul>
          <li v-for="(like, index) in likesObj.userLikes" :key="index">
            <div class="comment-data">
              <p>
                {{like.name}} {{like.last_name}} {{like.mothers_last_name}}
                <br />
                {{$t('screenshots.table.headings.controlNumber')}}: {{like.control_number}}
                <br />
                {{$t("news.details.likeDate")}}: {{like.created_at | formatDate(appLang)}}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import API_Service from "@/lib/api/News.js";
const api = new API_Service();
export default {
  props: {
    id: {
      required: true,
    },
  },
  data: function () {
    return {
      post: null,
      likesObj: null,
      commentToDelete: null,
      modalActive: false,
      isLoading: false,
    };
  },
  computed: {
    appLang: function () {
      return this.$store.getters["settings/currentLang"];
    },
  },
  watch: {
    appLang: async function (newValue) {
      await this.getLikes();
    },
  },
  async beforeMount() {
    await this.getLikes();
  },
  methods: {
    async getLikes() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const response = await api.getLikes(this.id, this.appLang);
        console.log("====================================");
        console.log(response.news[0]);
        console.log("====================================");
        this.likesObj = response.news[0];
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  color: $black;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  display: inline-block;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.header {
  display: flex;
  justify-content: space-between;
}
.header .download-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  padding: 0.5rem 2rem;
}
.post-details {
  margin: 3rem 0;
  padding: 2rem 4rem;
  border: 1px solid $wg_2;

  .main-data {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .data .report-warning {
    background-color: $w_1;
    border: 1px solid $wg_2;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin: 1rem 0;
  }
  .edit-link {
    text-transform: uppercase;
    color: $black;
    transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

    &:hover {
      color: $black;
    }
  }

  .comment-list {
    margin-top: 2rem;
  }
  .comment-list > ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .comment-list > ul > li {
    padding: 1rem 0;
    border-bottom: 1px solid $wg_2;

    &:last-child {
      border-bottom: none;
    }

    p {
      margin: 0;
    }

    .comment-body {
      grid-area: body;
    }
    .comment-data {
      grid-area: data;
    }
    .comment-data > p {
      margin-bottom: 0.5rem;
    }
    .comment-data > .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .comment-data > .icons > i {
      cursor: pointer;
    }
    .comment-data > .icons span {
      display: flex;
      align-items: center;
      background-color: $w_1;
      border: 1px solid $wg_2;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }
}

.modal-title {
  margin: 0;
}
.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.controls .control {
  margin-left: 15px;
  font-size: 16px;
  cursor: pointer;
}
.control.cancel-btn {
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    text-decoration: underline;
  }
}
.control.delete-btn {
  @extend %audi-b;
  color: $white;
  background-color: $black;
  border-color: $black;
  padding: 10px 20px;
  border-radius: 0px;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);

  &:hover {
    background-color: $warn_red;
    border-color: $warn_red;
  }
}
</style>