import axios from "axios";

export default class t4iAPI {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getCategories() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/t4i/`
      });
      return response.data.t4i;
    } catch (error) {
      throw error;
    }
  }
  
  async getCategory(categoryId) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/t4i/${categoryId}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async saveCategory(categoryId, data) {
    const url = `${this.API_LINK}/t4i/` + (categoryId ? `${categoryId}` : '');
    const method = categoryId? "patch" : "post";
    try {
      const response = await axios({
        method: method,
        url: url,
        data: data
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteCategory(categoryId) {
    try {
      const response = await axios({
        method: "delete",
        url: `${this.API_LINK}/t4i/${categoryId}/`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}