var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tripPassengers"},[_c('div',{staticClass:"row bg-white"},[_c('div',{staticClass:"col-md-12 top py-2 px-4"},[_c('router-link',{staticClass:"return",attrs:{"to":{
          name: 'Trip Details',
          params: {
            id: this.id,
            travelStatus: _vm.travelDetails.travelStatus,
            data: _vm.travelDetails,
            details: true,
            onGoingOrDone: true,
            tripDone: _vm.travelDetails.travelStatus === 'travelFinished' ? true : false
          }
        }}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v("\n        "+_vm._s(_vm.$t("events.newEvent.back"))+"\n      ")])],1),_c('div',{staticClass:"col-md-12 px-4 py-3"},[_c('h1',[_vm._v(_vm._s(_vm.$t("qrAccess.travels.passengersList.title")))])]),_c('div',{staticClass:"col-md-4 px-4 py-4 travelDetails"},[_c('label',{staticClass:"labelBold"},[_vm._v("\n        "+_vm._s(_vm.$t("qrAccess.travels.passengersList.travel"))+"\n      ")]),_c('p',[_vm._v("#"+_vm._s(_vm.travelDetails.id)+", "+_vm._s(_vm.travelDetails.busDirection)+", "+_vm._s(_vm.$t("qrAccess.travels.passengersList.busNumber"))+" "+_vm._s(_vm.travelDetails.busNumber))]),_c('p',[_vm._v(_vm._s(_vm.travelDetails.busRouteName))])]),_c('div',{staticClass:"col-md-3 py-4 travelDetails"},[_c('label',{staticClass:"labelBold"},[_vm._v("\n        "+_vm._s(_vm.$t("qrAccess.travels.passengersList.driver"))+"\n      ")]),_c('p',[_vm._v(_vm._s(_vm.travelDetails.driverFullName))]),_c('p',[_vm._v(_vm._s(_vm.travelDetails.controlNumber))])]),_c('div',{staticClass:"col-md-5 d-flex justify-content-end align-items-center"},[_c('button',{staticClass:"btn-download mr-5",on:{"click":_vm.downloadTable}},[_vm._v("\n        "+_vm._s(_vm.$t("events.listEvents.donwloadBtn"))+"\n      ")])]),_c('div',{staticClass:"col-md-12"},[_c('v-server-table',{ref:"passengersTable",attrs:{"columns":_vm.table_options.columns,"options":_vm.table_options.options},scopedSlots:_vm._u([{key:"actions",fn:function(props){return [_c('div',{staticClass:"actionsDrivers"},[_c('router-link',{staticClass:"edit-btn",attrs:{"to":{
                name: 'Trip Passenger Detail',
                params: {
                  id: props.row.id,
                  travelId: props.row.travelId,
                  data: props.row
                }
              }}},[_c('i',{staticClass:"far fa-eye"})])],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }