<template>
  <div class="tripPassengers">
    <div class="row bg-white">
      <div class="col-md-12 top py-2 px-4">
        <router-link
          class="return"
          :to="{
            name: 'Trip Details',
            params: {
              id: this.id,
              travelStatus: travelDetails.travelStatus,
              data: travelDetails,
              details: true,
              onGoingOrDone: true,
              tripDone: travelDetails.travelStatus === 'travelFinished' ? true : false
            }
          }"
        >
          <i class="fas fa-chevron-left"></i>
          {{ $t("events.newEvent.back") }}
        </router-link>
      </div>
      <div class="col-md-12 px-4 py-3">
        <h1>{{ $t("qrAccess.travels.passengersList.title") }}</h1>
      </div>
      <div class="col-md-4 px-4 py-4 travelDetails">
        <label class="labelBold">
          {{ $t("qrAccess.travels.passengersList.travel") }}
        </label>
        <p>#{{travelDetails.id}}, {{travelDetails.busDirection}}, {{ $t("qrAccess.travels.passengersList.busNumber") }} {{travelDetails.busNumber}}</p>
        <p>{{travelDetails.busRouteName}}</p>
      </div>
      <div class="col-md-3 py-4 travelDetails">
        <label class="labelBold">
          {{ $t("qrAccess.travels.passengersList.driver") }}
        </label>
        <p>{{travelDetails.driverFullName}}</p>
        <p>{{travelDetails.controlNumber}}</p>
      </div>
      <div class="col-md-5 d-flex justify-content-end align-items-center">
        <button
          @click="downloadTable"
          class="btn-download mr-5">
          {{ $t("events.listEvents.donwloadBtn") }}
        </button>
      </div>
      <div class="col-md-12">
        <v-server-table
          :columns="table_options.columns"
          :options="table_options.options"
          ref="passengersTable"
        >
          <!-- url="/people" -->
          <template v-slot:actions="props">
            <div class="actionsDrivers">
              <!-- Detail -->
              <router-link
                class="edit-btn"
                :to="{
                  name: 'Trip Passenger Detail',
                  params: {
                    id: props.row.id,
                    travelId: props.row.travelId,
                    data: props.row
                  }
                }"
              >
                <i class="far fa-eye"></i>
              </router-link>
            </div>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
  import API_SERVICE from '@/lib/api/QRAccess.js';
  const api = new API_SERVICE();
  let id;
  export default {
    props: {
      id: {
        type: Number
      },
      data: {
        type: Object
      }
    },
    data(){
      return {
        travelDetails: {}
      };
    },
    async beforeMount() {
      id = this.id;
      await this.getDetailTravel();
      // this.fillData();
    },
    methods: {
      getDetailTravel: async function() {
        // let currentLanguage = localStorage.getItem("lang");
        this.$store.commit('global/SET_LOADING', true);
        try {
          const resp = await api.detailTravel(this);
          this.travelDetails = resp.travel;
        } catch (error) {
          console.log(error);
          this.$store.commit('global/SET_LOADING', false);
        } finally {
          this.$store.commit('global/SET_LOADING', false);
        }
      },
      downloadTable: async function() {
        const filterParams = this.$refs.passengersTable;
        this.$store.commit('global/SET_LOADING', true);
        try {
          const resp = await api.downloadPassengersTable(filterParams, id);
          let anchor = document.createElement('a');
          anchor.href = resp.url;
          anchor.target = '_blank';
          anchor.click();
          this.$store.commit('global/SET_LOADING', false);
        } catch (error) {
          console.log(error);
          this.$store.commit('global/SET_LOADING', false);
        }
      }
    },
    computed: {
      passengersTable: function() {
        return this.$refs.passengersTable;
      },
      table_columns: function() {
        return [
          'id',
          'controlNumber',
          'passengerFullName',
          'userType',
          'companyName',
          'checkinDate',
          'checkinType',
          'uo',
          'actions'
        ];
      },
      table_headings: function() {
        if (this.table_columns) {
          const headings = {};
          this.table_columns.forEach(column => {
            headings[column] = this.$t(
              `qrAccess.travels.passengersList.table.headings.${column}`
            );
          });
          return headings;
        }
        return null;
      },
      table_filters: function() {
        return this.table_columns.filter(column => column !== 'actions' && column !== 'checkinDate');
      },
      table_options: function() {
        return {
          columns: this.table_columns,
          options: {
            columnsDropdown: true,
            filterByColumn: true,
            headings: this.table_headings,
            filterable: this.table_filters,
            sortable: [
              'id',
              'controlNumber',
              'passengerFullName',
              'userType',
              'companyName',
              'checkinDate',
              'checkinType',
              'uo',
            ],
            sortIcon: {
              base: 'fas',
              up: 'fa-sort-up',
              down: 'fa-sort-down',
              is: 'fa-sort'
            },
            resizableColumns: true,
            perPageValues: [25, 50, 100],
            perPage: 25,
            texts: {
              filterBy: '{column}',
              columns: this.$t('users.search.table.column_toggler')
            },

            requestAdapter: function(data) {
              const queries = {};
              queries.page = data.page - 1;
              queries.limit = data.limit;
              queries.query = data.query;
              queries.id = id;
              queries.ascending = data.ascending;
              queries.orderBy = data.orderBy;
              queries.uo = data.uo;
              return queries;
            },
            requestFunction: async function(data) {
              this.$store.commit('global/SET_LOADING', true);
              try {
                const resp = await api.getAllPassengers(data);
                this.$store.commit('global/SET_LOADING', false);
                return resp;
              } catch (error) {
                this.$store.commit('global/SET_LOADING', false);
                throw error;
              }
            },
            responseAdapter: function(response) {
              return {
                data: response.passengers,
                count: response.total
              };
            }
          }
        };
      }
    }
  };
</script>

<style >
  .return {
    color: black;
    cursor: pointer;
  }
  .return:hover {
    text-decoration: underline !important;
    color: black;
  }
  .labelBold {
    font-weight: bold;
    color: #000;
  }
  .tripPassengers .travelDetails p {
    margin: 0;
  }
  .btn-download {
    padding: 0.7rem 2rem;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 0px;
  }
  .edit-btn {
    color: $black;
  }
  .fa-eye {
      color: black;
  }
</style>
<style lang="scss">
.tripPassengers {
  .pull-right {
    float: none;
  }
  .VueTables .dropdown {
    margin: 1rem 0;
  }
  .VueTables .dropdown > button {
    @extend %audi;
    color: $black;
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 0;
    padding: 5px 10px;
  }
  .VueTables .dropdown .dropdown-menu {
    padding: 1rem;
    a {
      color: $black;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    a > input {
      margin-right: 0.5rem;
    }
  }
  .VueTables__table.table {
    min-width: 100%;
    width: max-content;
  }
  .VueTables__table .actions-column {
    position: sticky;
    right: 0;
    text-align: center;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #f2f2f2;
      top: 0;
      right: 0;
      border-collapse: collapse;
    }
  }
  .VueTables__table .actions-column > * {
    position: relative;
    z-index: 1;
  }
  .table-striped tbody tr:nth-of-type(odd) > .actions-column {
    &:after {
      background-color: #e6e6e6;
    }
  }
  .VueTables__table .id-column {
    max-width: 100px;
  }
  [class$="filter-wrapper"] {
    min-width: 80px;
    max-width: fit-content;
  }
}
</style>
