<template>
  <div id="user-manage">
    <header>
      <h1>{{$t('users.extern.table.title')}}</h1>
      <router-link :to="{
        name: 'New Extern User'
      }">
        <button class="new-btn">{{$t('users.extern.table.new')}}</button>
      </router-link>
      <button
        @click="downloadReport"
        class="btn-download"
      >{{ $t("events.listEvents.donwloadBtn") }}</button>
    </header>
    <v-server-table
      :columns="table_options.columns"
      :options="table_options.options"
      ref="userTable"
    >
      <template #actions="props">
        <div class="actionsExterns">
          <router-link
            class="far edit-btn"
            :to="{
            name: 'User Extern Edit',
            params: {
              controlNumber: props.row.controlNumber
            }
          }"
          >
            <i class="far fa-edit"></i>
          </router-link>
          <i @click="deleteExternUser(props)" class="far fa-trash-alt"></i>
        </div>
      </template>
    </v-server-table>
  </div>
</template>

<script>
import API_SERVICE from "@/lib/api/Users.js";
const fileUpload = () => import("./UserFileUpload.vue");
const api = new API_SERVICE();

export default {
  components: {
    fileUpload,
  },
  data: function () {
    return {};
  },
  computed: {
    appLang: function () {
      return this.$store.getters["settings/currentLang"];
    },
    userTable: function () {
      return this.$refs.userTable;
    },
    table_columns: function () {
      return [
        "controlNumber",
        "name",
        "lastName",
        "mothersLastName",
        "personalCellphoneNumber",
        "companyName",
        "providerNumber",
        "actions"
      ];
    },
    table_headings: function () {
      if (this.table_columns) {
        const headings = {};
        this.table_columns.forEach((column) => {
          headings[column] = this.$t(`users.extern.table.headings.${column}`);
        });
        return headings;
      }
      return null;
    },
    table_filters: function () {
      return this.table_columns.filter((column) => column !== "actions");
    },
    table_options: function () {
      return {
        columns: this.table_columns,
        options: {
          columnsDropdown: true,
          filterByColumn: true,
          headings: this.table_headings,
          filterable: this.table_filters,
          sortable: [],
          perPage: 10,
          resizableColumns: false,
          perPageValues: [10, 25, 50, 100],
          columnsClasses: {
            actions: "actions-column",
          },
          texts: {
            filterBy: "{column}",
            columns: this.$t("users.extern.table.column_toggler"),
          },
          hiddenColumns: [
            // "admissionDate",
            // "maritalStatus",
            // "funcion",
            // "idUo",
            // "numberOfChildren",
            // "personalCellphoneNumber",
            // "type",
            // "typeDescription",
            // "uo",
            // "uoDescription",
          ],
          requestAdapter: function (data) {
            const queries = {};
            queries.page = data.page - 1;
            queries.limit = data.limit;
            queries.query = data.query;
            queries.cmsUserFilter = 'extern';
            return queries;
          },
          requestFunction: async function (data) {
            this.$store.commit("global/SET_LOADING", true);
            try {
              const resp = await api.getUsers(data);
              this.$store.commit("global/SET_LOADING", false);
              return resp;
            } catch (error) {
              this.$store.commit("global/SET_LOADING", false);
              throw error;
            }
          },
          responseAdapter: function (response) {
            return {
              data: response.data,
              count: response.total,
            };
          },
        },
      };
    },
  },
  methods: {
    updateTable: function () {
      this.userTable.refresh();
    },
    deleteExternUser: async function(event) {
      this.$store.commit("global/SET_LOADING", true);
      event.row.status = 'Dado de baja'
      console.log('event.row ', JSON.stringify(event.row, null, 4))
      // return true
      try {
        const resp = await api.saveUser(event.row.controlNumber, event.row);
        this.$refs.userTable.refresh();
        this.$store.commit("global/SET_LOADING", false);
        return resp;
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    async downloadReport() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.downloadUserReport('extern');
        let anchor = document.createElement("a");
        anchor.href = resp.url;
        anchor.target = "_blank";
        anchor.click();
      } catch (error) {
        console.log(error);
        this.$store.commit("global/SET_LOADING", false);
      } finally {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.new-btn {
  align-self: center;
  background-color: $black;
  color: $white;
  border: 1px solid $black;
  border-radius: 0;
  padding: 0.5rem 2rem;
  margin: 1rem 0;
}
.edit-btn {
  color: $black;
}
.spinner-border {
  border-color: $red;
  border-right-color: transparent;
}
.fa-check-circle {
  color: $warn_green;
}
.fa-times-circle {
  color: $warn_red;
}
.btn-download {
  padding: 1rem;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 0px;
  float: right;
}
</style>

<style lang="scss">
#user-manage {
  .pull-right {
    float: none;
  }
  .VueTables .dropdown {
    margin: 1rem 0;
  }
  .VueTables .dropdown > button {
    @extend %audi;
    color: $black;
    background-color: transparent;
    border: 1px solid $black;
    border-radius: 0;
    padding: 5px 10px;
  }
  .VueTables .dropdown .dropdown-menu {
    padding: 1rem;
    a {
      color: $black;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    a > input {
      margin-right: 0.5rem;
    }
  }
  .VueTables__table.table {
    min-width: 100%;
    width: max-content;
  }
  .VueTables__table .actions-column {
    position: sticky;
    right: 0;
    text-align: center;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #f2f2f2;
      top: 0;
      right: 0;
      border-collapse: collapse;
    }
  }
  .VueTables__table .actions-column > * {
    position: relative;
    z-index: 1;
  }
  .table-striped tbody tr:nth-of-type(odd) > .actions-column {
    &:after {
      background-color: #e6e6e6;
    }
  }
  .VueTables__table .id-column {
    max-width: 100px;
  }
  [class$="filter-wrapper"] {
    min-width: 80px;
    max-width: fit-content;
  }
  .actionsExterns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .actionsExterns > *, button {
    cursor: pointer;
  }
  .actionsExterns > .edit-btn {
    color: #000000;
    transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
    &:hover {
      color: #000000;
    }
  }
}
</style>
