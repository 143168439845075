<template>
  <div id="Code-edit">
    <!-- Back Button -->
    <router-link class="back-btn" :to="{ name: 'Code List' }">
      <i class="fas fa-angle-left"></i>&nbsp;
      <strong>{{$t('conduct.edit.back_btn')}}</strong>
    </router-link>
    <!-- Header -->
    <header class="header">
      <div class="title">
        <h1>
          {{$t("conduct.list.title")}}:
          <span v-if="category">{{category.title}}</span>
        </h1>
        <p>{{$t('conduct.edit.directions')}}</p>
      </div>
      <!-- publish Button -->
    </header>
    <!-- Wysiwyg -->
    <div class="input-container">
      <!-- <label>{{$t("newAlert.alertBody")}}</label> -->
      <div class="error-msg">
        <span v-if="$v.category.body.$error">{{$t("conduct.edit.errors.body")}}</span>
      </div>
      <div class="wysiwyg">
        <Wysiwyg
          v-if="!wysiwygLoading"
          :placeholder="$t('conduct.edit.body_ph')"
          :articleBody="category.delta"
          v-on:updateBody="category.body = $event"
        ></Wysiwyg>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-dark publish-btn"
            :disabled="isUploading"
            @click="validateForm"
          >{{$t('conduct.edit.publish')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CONDUCT_API from "@/lib/api/Conduct.js";
const Wysiwyg = () => import("@/components/Wysiwyg");

export default {
  components: {
    Wysiwyg
  },
  props: {
    id: {
      required: true
    }
  },
  data: function() {
    return {
      category: null,
      wysiwygLoading: true,
      isUploading: false
    };
  },
  validations: {
    category: {
      body: {
        isRequired: function(data) {
          if (data) {
            if (data.hasOwnProperty("ops")) {
              if (data.ops.length == 1 && data.ops[0].insert === "\n") {
                return false;
              } else {
                return true;
              }
            }
            return false;
          }
          return false;
        }
      }
    }
  },
  beforeMount: async function() {
    await this.getCategory();
    this.wysiwygLoading = false;
  },
  methods: {
    getCategory: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new CONDUCT_API();
      try {
        const response = await api.getCategory(this.id);
        this.category = response;
        this.category.delta = response.body;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    validateForm: async function() {
      this.isUploading = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.isUploading = false;
        return;
      }
      await this.updateCategory();
    },
    updateCategory: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new CONDUCT_API();
      try {
        const data = {
          body: this.category.body,
          author: this.$store.getters["session/userControl"],
          title: this.category.title
        };
        await api.saveCategory(this.id, data);
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        this.$router.push({
          name: "Code List"
        });
      } catch (error) {
        this.isUploading = false;
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.back-btn {
  margin-bottom: 2rem;
  cursor: pointer;
  color: $black;
  font-weight: normal;
  display: inline-block;
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.header {
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.header .publish-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  padding: 0.5rem 2rem;
}
.publish-btn {
  align-self: flex-end;
  background: #000;
  border-radius: 0px;
  padding: 0.5rem 1rem;
}
.input-container {
  margin: 1rem 4rem;
}

.error-msg {
  color: $warn_red;
  font-size: 14px;
}
</style>

<style lang="scss">
#t4i-edit {
  .quill-editor .ql-editor {
    height: 50vh;
  }
}
</style>