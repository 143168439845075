<template>
  <div id="users-admin">
    <header>
      <h1>{{ $t("users.admin.title") }}</h1>
    </header>
    <div class="user-table">
      <button class="create-btn">
        Agregar usuario
      </button>
      <button class="create-btn">
        Crear grupo
      </button>
      <v-client-table
        v-if="table_columns"
        :columns="table_columns"
        :options="table_options"
        :data="users"
        ref="userTable"
      >
        <template #actions>
          <div class="actions">
            <i class="far fa-edit"></i>
            <i class="far fa-trash-alt"></i>
          </div>
        </template>
      </v-client-table>
    </div>
  </div>
</template>

<script>
import API_SERVICE from "@/lib/api/Users.js";
const api = new API_SERVICE();

export default {
  data: function() {
    return {
      users: null
    };
  },
  computed: {
    table_columns: function() {
      if (this.users)
        return ["controlNumber", "companyEmail", "name", "lastName", "actions"];
      return null;
    },
    table_headings: function() {
      if (this.table_columns) {
        const headings = {};
        this.table_columns.forEach(column => {
          headings[column] = this.$t(`users.admin.table.headings.${column}`);
        });
        return headings;
      }
      return null;
    },
    table_options: function() {
      return {
        // columnsDropdown: true,
        // filterByColumn: true,
        headings: this.table_headings,
        filterable: ["controlNumber", "companyEmail", "name", "lastName"],
        sortable: [],
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        columnsClasses: {
          //   select: 'select-column',
          //   id: 'id-column'
          actions: "actions-column"
        },
        texts: {
          filterBy: "{column}"
          // columns: this.$t('users.search.table.column_toggler')
        }
      };
    }
  },
  beforeMount: async function() {
    await this.getUsers();
  },
  methods: {
    getUsers: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getAdminUsers();
        this.users = resp.data;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-btn {
  background-color: $black;
  color: $white;
  border: 1px solid $black;
  border-radius: 0;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
}
.actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
</style>

<style lang="scss">
#users-admin {
  .actions-column {
    width: 100px;
  }
  .form-inline label {
    display: initial;
  }
}
</style>
