import axios from "axios";
import IDEAS from "@/mockData/Ideas.json"

export default class Ideas {
	constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  // General
  async getGeneral() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/generals/Ideas-General`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async postGeneral(body, author) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/generals`,
        data: {
          name: "Ideas-General",
          title: "Información General",
          author: author,
          body: body
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Stats
  async getStatsFile() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/files/ideas_stats`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async postStatsFile(url) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/files/`,
        data: {
          fileName: "ideas_stats",
          source: url
        }
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  // Rewards
  async getRewardsFile() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/files/ideas_rewards`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async postRewardsFile(url) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/files/`,
        data: {
          fileName: "ideas_rewards",
          source: url
        }
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  // Status
  async getStatusFile() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/files/ideas_status`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async postStatusFile(url) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/files/`,
        data: {
          fileName: "ideas_status",
          source: url
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Campaign
  async getCampaigns() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/cip/campaignIdeas`
      });
      return response.data.campaigns;
    } catch (error) {
      throw error;
    }
  }
  async getCampaign(id) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/cip/campaignIdeas/${id}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async saveCampaign(form, id) {
    const method = id ? "patch" : "post";
    const url = `${this.API_LINK}/cip/campaignIdeas` + (id ? `/${id}` : "");
    try {
      const response = await axios({
        method: method,
        url: url,
        data: form
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async deleteCampaign(id) {
    try {
      const response = await axios({
        method: "delete",
        url: `${this.API_LINK}/cip/campaignIdeas/${id}`
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Points
  async getScore() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/cip/ideas/points`
      });
      return response.data.stepPoints;
    } catch (error) {
      throw error;
    }
  }
  async setScore(score) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/cip/ideas/points/updateMany`,
        data: {
          score: score
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getUserData(controlNum) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/users/${controlNum}`,
        params: {
          filter: "points"
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async redeemPoints(controlNum, points) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/cip/admin`,
        data: {
          controlNumber: controlNum,
          substract: points
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Ideas
  async getIdeas() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/cip/ideas`
      });
      return response.data.ideas;
    } catch (error) {
      throw error;
    }
  }
  async getIdea(id) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/cip/ideas/${id}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async updateIdea(id, body) {
    try {
      const response = await axios({
        method: "patch",
        url: `${this.API_LINK}/cip/ideas/${id}`,
        data: body
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getEmail() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/generals/Ideas-Alert`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async saveEmail(link) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/generals`,
        data: {
          name: "Ideas-Alert",
          title: link,
          author: ' ',
          body: link
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  async deleteIdea(id) {
    try {
      const response = await axios({
        method: "delete",
        url: `${this.API_LINK}/cip/ideas/${id}`
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
