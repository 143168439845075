import axios from "axios";
import NEWS_LIST from "@/mockData/NewsList.json"
import NEWS_POSTS from "@/mockData/NewsPosts.json"

export default class NewsAPI {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getCategories() {
    try {
      // const response = NEWS_POSTS;
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/blog/news/`,
        params: {
          onlyCategories: true
        }
      });
      return response.data.categories;
    } catch (error) {
      throw error;
    }
  }

  async getNewsList(lang) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/blog/news/`,
        headers: {
          'Accept-Language': lang
        }
      });
      return response.data.news;
    } catch (error) {
      throw error;
    }
    // try {
    //   const response = NEWS_LIST;
    //   return response.data;
    // } catch (error) {
    //   throw error;
    // }
  }

  async getEditPost(slug) {
    try {
      const requests = [
        axios({
          method: "get",
          url: `${this.API_LINK}/blog/news/${slug}`,
          headers: {
            'Accept-Language': "es"
          }
        }),
        axios({
          method: "get",
          url: `${this.API_LINK}/blog/news/${slug}`,
          headers: {
            'Accept-Language': "en"
          }
        }),
      ];
      const postResp = await Promise.all(requests);
      const post = postResp.map(resp => resp.data.news[0]);
      return post;
    } catch (error) {
      throw error;
    }
  }

  async getPostIds(slug, lang) {
    try {
      const postResp = await axios({
        method: "get",
        url: `${this.API_LINK}/blog/news/${slug}`,
        headers: {
          'Accept-Language': lang ? lang : ""
        }
      });
      const response = postResp.data.news[0];
      return response ? response.id : null;
    } catch (error) {
      throw error;
    }
  }

  async getPost(slug, lang) {
    try {
      const postResp = await axios({
        method: "get",
        url: `${this.API_LINK}/blog/news/${slug}`,
        headers: {
          'Accept-Language': lang ? lang : ""
        }
      });
      const post = postResp.data.news[0];
      const commentsResp = await axios({
        method: "get",
        url: `${this.API_LINK}/blog/comments`,
        params: {
          newId: post.id,
          slug: slug,
          admin: true
        }
      });
      const response = {
        ...post,
        ...commentsResp.data
      }
      return response;
    } catch (error) {
      throw error;
    }
  }

  async uploadPost(body) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/blog/news`,
        data: body
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updatePost(body, slug, id) {
    try {
      body.id = id;
      const response = await axios({
        method: "patch",
        // url: `${this.API_LINK}/blog/news/${slug}/${id}`,
        url: `${this.API_LINK}/blog/news/${slug}`,
        data: body
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async deletePost(slug, id) {
    try {
      const response = await axios({
        method: "delete",
        // url: `${this.API_LINK}/blog/news/${slug}/${id}`
        url: `${this.API_LINK}/blog/news/${slug}`,
        data: {
          id: id
        }
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }

  async deleteComment(commentId) {
    try {
      const response = await axios({
        method: "delete",
        url: `${this.API_LINK}/blog/comments/${commentId}`
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  }
  async getLikes(id, lang) {
    try {

      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/blog/news/${id}`,
        headers: {
          'Accept-Language': lang ? lang : ""
        }
      });

      return response.data;
    } catch (error) {
      throw error.response;
    }
  }
}
