<template>
  <div id="ideas-general">
    <h1>{{$t('ideas.general.title')}}</h1>
    <p>{{$t('ideas.general.directions')}}</p>
    <!-- Wysiwyg -->
    <div class="input-container">
      <div class="wysiwyg">
        <Wysiwyg
          v-if="!wysiwygLoading"
          :placeholder="$t('ideas.general.placeholder')"
          :articleBody="delta"
          v-on:updateBody="body = $event"
        ></Wysiwyg>
      </div>
      <div class="error-msg">
        <span v-if="$v.body.$error">{{$t("ideas.general.errors.body_required")}}</span>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-dark control publish-btn"
      :disabled="isUploading"
      @click="validateForm"
    >{{$t('ideas.general.publish')}}</button>
  </div>
</template>

<script>
import apiService from "@/lib/api/Ideas.js";
import { required } from "vuelidate/lib/validators";
const Wysiwyg = () => import("@/components/Wysiwyg");
const api = new apiService();

export default {
  components: {
    Wysiwyg
  },
  data: function() {
    return {
      wysiwygLoading: true,
      delta: null,
      body: null,
      isUploading: false
    };
  },
  computed: {},
  validations: {
    body: {
      required
    }
  },
  beforeMount: async function() {
    await this.getGeneral();
  },
  methods: {
    getGeneral: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new apiService();
      try {
        const response = await api.getGeneral();
        this.delta = response.body;
        this.body = response.body;
        this.wysiwygLoading = false;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.wysiwygLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        this.delta = null;
        throw error;
      }
    },
    validateForm: async function() {
      this.isUploading = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.isUploading = false;
        return;
      }
      await this.postGeneral();
    },
    postGeneral: async function() {
      this.$store.commit("global/SET_LOADING", true);
      const api = new apiService();
      try {
        await api.postGeneral(this.body, this.$store.getters["session/author"]);
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        await this.getGeneral();
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#ideas-general {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 4rem;

  .input-container {
    width: 100%;
  }

  .error-msg {
    color: $warn_red;
    font-size: 14px;
  }

  .publish-btn {
    align-self: flex-end;
    background: #000;
    border-radius: 0px;
    padding: 0.5rem 1rem;
  }
}
</style>

<style lang="scss">
#ideas-general {
  .quill-editor .ql-editor {
    height: 50vh;
  }
}
</style>
