import axios from "axios";

export default class CouponsAPI {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getLink(link) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/generals/Coupons-${link}`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getFAQ() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/generals/Coupons-FAQ`,
      })
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async postLink(link, type, author) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/generals`,
        data: {
          name: `Coupons-${type}`,
          title: link,
          author: author,
          body: link
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async postFAQ(body, author) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/generals`,
        data: {
          name: "Coupons-FAQ",
          title: "Preguntas Frecuentes",
          author: author,
          body: body
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
}