<template>
  <div id="care-phone">
    <h1>{{$t("care.title")}}</h1>
    <div class="input-container">
      <label for="phone">{{$t("care.phone")}}</label>
      <input
      type="tel"
      name="phone"
      id="phone"
      :placeholder="$t('care.phone_ph')"
      v-model="$v.form.phone.$model">
      <div v-if="$v.$error" class="error-msg">
        <p v-if="!$v.form.phone.required">{{$t("care.errors.phone_required")}}</p>
        <p v-if="!$v.form.phone.numeric">{{$t("care.errors.phone_numeric")}}</p>
        <p v-if="!$v.form.phone.minLength || !$v.form.phone.maxLength">{{$t("care.errors.phone_length")}}</p>
      </div>
    </div>
    <button
    class="update-btn"
    @click="validateForm">
      {{$t("care.save")}}
    </button>
  </div>
</template>

<script>
import { required, numeric, minLength, maxLength } from "vuelidate/lib/validators";
import API_SERVICE from "@/lib/api/Care.js";
const api = new API_SERVICE();

export default {
  data: function() {
    return {
      form: {
        phone: null
      }
    };
  },
  validations: {
    form: {
      phone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10)
      }
    }
  },
  beforeMount: async function() {
    await this.getPhone();
  },
  methods: {
    getPhone: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getPhone();
        this.form.phone = resp.title;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    validateForm: async function() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.updatePhone();
    },
    updatePhone: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        await api.savePhone(this.form.phone);
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#care-phone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
#care-phone > * {
  margin: 1rem 0;
}
.input-container {
  @extend %audi;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}
.input-container > label {
  @extend %audi-b;
}
.update-btn {
  @extend %audi;
  color: $white;
  background-color: $black;
  padding: 10px 20px;
  border: 1px solid $black;
  border-radius: 0px;
}
.error-msg {
  color: $warn_red;
  font-size: 14px;
}
.error-msg > p {
  margin: 0;
}
</style>