import axios from "axios";

export default class Care {
	constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getPhone() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/generals/Care-Phone`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async savePhone(phone) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/generals`,
        data: {
          name: "Care-Phone",
          title: phone,
          author: ' ',
          body: phone
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
}