import axios from "axios";
import MOCK_CALENDAR from "@/mockData/Calendar.json";

export default class calendarAPI {
  constructor() {
    this.API_LINK = process.env.VUE_APP_API_LINK;
  }

  async getCategories() {
    try {
      // const resp = MOCK_CALENDAR.categories;
      const resp = await axios({
        method: 'get',
        url: `${this.API_LINK}/events/categories`
      });
      return resp.data;
    } catch (error) {
      return error;
    }
  }

  async getEvents(type, month, year) {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/events/calendar`,
        params: {
          type: type,
          month: month,
          year: year
        }
      });
      return response.data.calendar;
    } catch (error) {
      throw error;
    }
  }

  async saveEvent(id, form) {
    form.title = " ";
    form.body = " ";
    try {
      const url = `${this.API_LINK}/events/calendar` + (id ? `/${id}` : "");
      const response = await axios({
        method: id ? "patch" : "post",
        url: url,
        data: form
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteEvent(id) {
    try {
      const response = await axios({
        method: "delete",
        url: `${this.API_LINK}/events/calendar/${id}`
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCalendarFile() {
    try {
      const response = await axios({
        method: "get",
        url: `${this.API_LINK}/bin/files/calendar`
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async postCalendarFile(url) {
    try {
      const response = await axios({
        method: "post",
        url: `${this.API_LINK}/bin/files/`,
        data: {
          fileName: "calendar",
          source: url
        }
      });
      return response;
    } catch (error) {
      throw error.response;
    }
  }
}