import { render, staticRenderFns } from "./IdeasList.vue?vue&type=template&id=5e8f7d6f&scoped=true&"
import script from "./IdeasList.vue?vue&type=script&lang=js&"
export * from "./IdeasList.vue?vue&type=script&lang=js&"
import style0 from "./IdeasList.vue?vue&type=style&index=0&lang=css&"
import style1 from "./IdeasList.vue?vue&type=style&index=1&id=5e8f7d6f&lang=scss&scoped=true&"
import style2 from "./IdeasList.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e8f7d6f",
  null
  
)

export default component.exports