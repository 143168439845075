<template>
  <div class="serviceLine">
    <h1>{{ $t("serviceLine.title") }}</h1>
    <p>{{ $t("serviceLine.subtitle") }}</p>
    <div class="row">
      <div class="col-md-12 text-right">
        <Json2Csv :json-data="csvData" :csv-title="`Service_Line_Data`">
          <button class="btn-download">
            {{ $t("events.listEvents.donwloadBtn") }}
          </button>
        </Json2Csv>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-server-table
          :columns="table_options.columns"
          :options="table_options.options"
          ref="serviceLineTable"
          id="serviceLineTable"
          class="mt-5"
        >
          <template #reportDate="props">
            <span>{{ props.row.reportDateFormat }}</span>
          </template>
          <template #actions="props">
            <router-link
              class="icon-black"
              :to="{
                name: 'Service Line Detail',
                params: { id: props.row.id }
              }"
            >
              <i class="far fa-eye"></i>
            </router-link>
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import API_SERVICE from "@/lib/api/serviceLine.js";
const api = new API_SERVICE();
import moment from "moment";
import Json2Csv from "vue-json-to-csv";

export default {
  components: {
    Json2Csv
  },
  data() {
    return {
      downloadData: [],
      csvData: null,
      dictionary: {
        nIdServiceLine: this.$t("serviceLine.table.headings.nIdServiceLine"),
        folio: this.$t("serviceLine.table.headings.folio"),
        fullName: this.$t("serviceLine.table.headings.fullName"),
        requestedService: this.$t(
          "serviceLine.table.headings.requestedService"
        ),
        reportDate: this.$t("serviceLine.table.headings.reportDate"),
        status: this.$t("serviceLine.table.headings.status"),
        email: this.$t("serviceLine.email"),
        phone: this.$t("serviceLine.phone"),
        controlNumber: this.$t("serviceLine.controlNum"),
        score: this.$t("serviceLine.score"),
        reportDateFormat: this.$t("serviceLine.reportDateFormat")
      }
    };
  },
  async beforeMount() {
    await this.downloadTable();
  },
  methods: {
    async downloadTable() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        let data = {
          all: true
        };
        const resp = await api.downloadServiceTable(data);
        for (let index = 0; index < resp.data.length; index++) {
          let formatDate = moment(resp.data[index].createdAt).format(
            "DD/MM/YY, HH:mm a"
          );
          resp.data[index].reportDateFormat = formatDate;

          switch (resp.data[index].status) {
                  case "open":
                    resp.data[index].status = "Open"; //this.$t("serviceLine.open")
                    break;
                  case "closed":
                    resp.data[index].status = "Closed"; //this.$t("serviceLine.closed")
                    break;

                  case "waitingForApproval":
                    resp.data[index].status = "Waiting for Approval"; //this.$t("serviceLine.closed")
                    break;
                }
        }
        this.downloadData = resp.data;

        this.itemsToDownload();
      } catch (error) {
        console.log(error);
        this.$store.commit("global/SET_LOADING", false);
      } finally {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
    itemsToDownload: function() {
      const itemsFields = [
        "nIdServiceLine",
        "folio",
        "fullName",
        "email",
        "phone",
        "controlNumber",
        "requestedService",
        "reportDateFormat",
        "status",
        "score"
      ];
      let downloadArray = this.downloadData.map(item => {
        let obj = {};
        itemsFields.forEach(field => {
          obj[this._translateHeader(field)] = item[field];
        });

        return obj;
      });
      this.csvData = downloadArray;
    },
    _translateHeader(header) {
      if (this.dictionary.hasOwnProperty(header)) {
        return this.dictionary[header];
      }
      return header;
    }
  },
  computed: {
    appLang: function() {
      return this.$store.getters["settings/currentLang"];
    },
    userTable: function() {
      return this.$refs.serviceLineTable;
    },
    table_columns: function() {
      return [
        "folio",
        "fullName",
        "requestedService",
        "reportDate",
        "code",
        "nIdServiceLine",
        "status",
        "score",
        "actions"
      ];
    },
    table_headings: function() {
      if (this.table_columns) {
        const headings = {};
        this.table_columns.forEach(column => {
          headings[column] = this.$t(`serviceLine.table.headings.${column}`);
        });
        return headings;
      }
      return null;
    },
    table_filters: function() {
      return this.table_columns.filter(
        column =>
          column !== "actions" &&
          column !== "reportDate" &&
          column !== "status" &&
          column !== "code" &&
          column !== "nIdServiceLine" &&
          column !== "score"
      );
    },
    table_options: function() {
      return {
        columns: this.table_columns,
        options: {
          columnsDropdown: false,
          filterByColumn: true,
          headings: this.table_headings,
          filterable: this.table_filters,
          sortable: [
            "folio",
            "fullName",
            "reportDate",
            "requestedService",
            "status"
          ],

          sortIcon: {
            base: "fas",
            up: "fa-sort-up",
            down: "fa-sort-down",
            is: "fa-sort"
          },
          resizableColumns: false,
          perPage: 10,
          texts: {
            filterBy: "{column}",
            columns: this.$t("users.search.table.column_toggler")
          },

          requestAdapter: function(data) {
            const queries = {};
            queries.page = data.page - 1;
            queries.limit = data.limit;
            queries.query = data.query;
            queries.orderBy = data.orderBy ? data.orderBy : "folio";
            queries.orderType = data.ascending ? "asc" : "desc";
            return queries;
          },
          requestFunction: async function(data) {
            this.$store.commit("global/SET_LOADING", true);
            try {
              const resp = await api.getServices(data);
              for (let index = 0; index < resp.data.length; index++) {
                let formatDate = moment(resp.data[index].createdAt).format(
                  "DD/MM/YY, HH:mm a"
                );
                resp.data[index].reportDateFormat = formatDate;
                if (!resp.data[index].code) {
                  resp.data[index].code = "";
                  if (resp.data[index].technicalLocation) {
                    resp.data[index].code = resp.data[index].technicalLocation;
                  }
                } else {
                  if (resp.data[index].technicalLocation) {
                    resp.data[index].code = `${resp.data[index].code} / ${
                      resp.data[index].technicalLocation
                    }`;
                  }
                }

                if (!resp.data[index].nIdServiceLine) {
                  resp.data[index].nIdServiceLine = "";
                }
                switch (resp.data[index].status) {
                  case "open":
                    resp.data[index].status = "Open"; //this.$t("serviceLine.open")
                    break;
                  case "closed":
                    resp.data[index].status = "Closed"; //this.$t("serviceLine.closed")
                    break;

                  case "waitingForApproval":
                    resp.data[index].status = "Waiting for Approval"; //this.$t("serviceLine.closed")
                    break;
                }
              }
              this.$store.commit("global/SET_LOADING", false);
              return resp;
            } catch (error) {
              this.$store.commit("global/SET_LOADING", false);
              throw error;
            }
          },
          responseAdapter: function(response) {
            return {
              data: response.data,
              count: +response.total
            };
          }
        }
      };
    }
  }
};
</script>

<style>
.pull-right > button {
  background-color: transparent !important;
  border-radius: 0px;
  border: 1px solid #000;
  margin-bottom: 1rem;
}
.table {
  text-align: center !important;
}
#VueTables_th--reportDate {
  width: 250px !important;
}
#VueTables_th--status {
  width: 150px !important;
}
.icon-black {
  color: #000;
}
.icon-black:hover {
  color: black;
  text-decoration: none;
}
.btn-download {
  padding: 0.7rem 2rem;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 0px;
}
.serviceLine tbody td {
  text-align: center !important;
}
.serviceLine thead th {
  text-align: center !important;
}
</style>
