<template>
  <div id="user-new">
    <header>
      <h1 v-if="controlNumber">{{$t('users.extern.edit.title')}}</h1>
      <h1 v-else>{{$t('users.extern.new.title')}}</h1>
      <p>{{ $t("events.newEvent.requireFields") }}</p>
      <div class="actions">
        <router-link class="cancel-btn" :to="{name: 'Extern'}">Cancelar</router-link>
        <button class="save-btn" @click="validateForm">{{$t('users.extern.new.save')}}</button>
      </div>
    </header>
    <div class="form-container">
      <div v-for="(field, index) in formData" :key="index" class="input-container">
        <label :for="index">{{$t(`users.extern.table.headings.${index}`)}}</label>
        <input
          v-if="field.type === 'text' && field.disabled"
          disabled="disabled"
          type="text"
          :name="index"
          :id="index"
          v-model="formData[index].value"
        />
        <select
          v-if="field.type === 'select'"
          :name="index"
          :id="index"
          v-model="formData[index].value"
        >
          <option :value="null">{{$t('users.extern.new.option_null')}}</option>
          <option
            v-for="(option, optIndex) in field.options"
            :key="optIndex"
            :value="option"
          >{{option}}</option>
        </select>
        <input
          v-if="field.type === 'text' && !field.disabled"
          type="text"
          :name="index"
          :id="index"
          v-model="formData[index].value"
        />
        <input
          v-if="field.type === 'number'"
          type="number"
          :name="index"
          :id="index"
          v-model.number="formData[index].value"
        />
        <input
          v-if="field.type === 'email'"
          type="email"
          :name="index"
          :id="index"
          v-model="formData[index].value"
        />
        <input
          v-if="field.type === 'date'"
          type="date"
          :name="index"
          :id="index"
          v-model="formData[index].value"
        />
        <div v-if="$v.formData[index]" class="error-msg">
          <div v-if="$v.formData[index].$error">
            <p
              v-if="$v.formData[index].value.required === false"
            >{{$t("users.extern.new.errors.required", { field: $t(`users.extern.table.headings.${index}`)})}}</p>
            <p
              v-if="$v.formData[index].value.numeric === false"
            >{{$t("users.extern.new.errors.numeric", { field: $t(`users.extern.table.headings.${index}`)})}}</p>
            <p
              v-if="$v.formData[index].value.email === false"
            >{{$t("users.extern.new.errors.email", { field: $t(`users.extern.table.headings.${index}`)})}}</p>
            <p
              v-if="$v.formData[index].value.minLength === false || $v.formData[index].value.maxLength === false"
            >{{$t("users.extern.new.errors.eq_chars", { field: $t(`users.extern.table.headings.${index}`)})}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  required,
  numeric,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import API_SERVICE from "@/lib/api/Users.js";

const api = new API_SERVICE();
const dateFormat = (value) => {
  if (!value) return true;
  return moment(value, "YYYY-MM-DD", true).isValid();
};

export default {
  props: {
    controlNumber: {
      required: false,
    },
  },
  data: function () {
    return {
      formData: {
        controlNumber: {
          type: "text",
          value: null,
          disabled: true
        },
        gender: {
          type: "select",
          options: ["Masculino", "Femenino"],
          value: null
        },
        name: {
          type: "text",
          value: null,
        },
        lastName: {
          type: "text",
          value: null,
        },
        mothersLastName: {
          type: "text",
          value: null,
        },
        companyName: {
          type: "text",
          value: null,
        },
        providerNumber: {
          type: "text",
          value: null,
        },
        personalCellphoneNumber: {
          type: "text",
          value: null,
        },
      },
      admissionDate: String
    };
  },
  validations: {
    formData: {
      companyName: {
        value: {
          required,
        },
      },
      name: {
        value: {
          required,
        },
      },
      providerNumber: {
        value: {
          required,
        },
      },
      lastName: {
        value: {
          required,
        },
      },
      personalCellphoneNumber: {
        value: {
          required,
        },
      },
      gender: {
        value: {
          required,
        },
      },
    },
  },
  beforeMount: async function () {
    if (this.controlNumber) await this.getUser();
  },
  methods: {
    getUser: async function () {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getUser(this.controlNumber);
        this.mapToForm(resp);
        this.admissionDate = resp.admissionDate;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
    validateForm: async function () {
      this.$v.$touch();
      if (this.$v.$error) return;
      await this.saveUser();
    },
    saveUser: async function () {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const form = this.mapForm();
        form.userType = 'extern';
        form.admissionDate = typeof this.admissionDate === 'string' ? this.admissionDate : moment().format('DD/MM/YY');
        form.status = 'Activo';
        form.type = 'Ext';
        await api.saveUser(this.controlNumber, form);
        this.$store.commit("global/SET_LOADING", false);
        this.$router.push({
          name: "Extern",
        });
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
    mapToForm: function (userData) {
      for (const field in userData) {
        if (this.formData.hasOwnProperty(field)) {
          if (field === "birthdate" || field === "admissionDate") {
            this.formData[field].value = moment(userData[field], "DD/MM/YY", true)
            this.formData[field].value = this.formData[field].value.format("YYYY-MM-DD");
          } else if (userData[field] === "-") {
            this.formData[field].value = null;
          } else {
            this.formData[field].value = userData[field];
          }
        }
      }
    },
    mapForm: function () {
      const obj = {};
      for (const field in this.formData) {
        if (this.formData.hasOwnProperty(field)) {
          if (this.formData[field].type === "date") {
            obj[field] = moment(this.formData[field].value).format("DD/MM/YY");
          } else if (this.formData[field].type === "number" && !this.formData[field].value){
            obj[field] = 0;
          } else if (this.formData[field].value) {
            obj[field] = this.formData[field].value;
          } else {
            obj[field] = "-";
          }
        }
      }
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  margin: 1rem 0;
  text-align: right;
}
.actions > .cancel-btn {
  margin-right: 1rem;
  text-align: right;
  color: $black;
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.75, 0.02, 0.5, 1);
  &:hover {
    color: $black;
    text-decoration: underline;
  }
}
.actions > .save-btn {
  background-color: $black;
  color: $white;
  border-radius: 0;
  border: 1px solid $black;
  padding: 0.5rem 2rem;
}
.form-container {
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem auto;
}
.input-container {
  width: 45%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}
.input-container > label {
  font-weight: bold;
}
.error-msg {
  color: $warn_red;
  font-size: 14px;
}
.error-msg p {
  margin: 0;
}
input#controlNumber {
    border-bottom: none;
}
</style>
