<template>
  <div id="ideas-stats">
    <header>
      <div class="title">
        <h1>{{$t('ideas.stats.title')}}</h1>
        <p>{{$t('ideas.stats.directions')}}</p>
      </div>
      <a
        v-if="signedURL"
        :href="signedURL"
        class="download-btn"
        download
        target="_blank"
      >{{$t('ideas.stats.download')}}</a>
    </header>
    <!-- File input - pdf -->
    <div class="input-container">
      <FileUploader
        :placeholder="$t('ideas.stats.filePH')"
        :type="'.pdf'"
        :label="$t('ideas.stats.browse')"
        :fileUrl="fileUrl"
        @setFile="setFile"
      />
      <div class="error-msg">
        <span v-if="$v.file.$error">{{$t("ideas.stats.errors.fileRequired")}}</span>
      </div>
    </div>
    <!-- Drag & drop file -->
    <!-- <div
    v-if="!file"
    class="drop-container"
    v-on:dragover.prevent
    v-on:drop.prevent="dropGetFile">
      {{$t('ideas.stats.dropFile')}}
    </div>-->
    <div class="uploadInfo">{{uploadStatus}}</div>
    <button
      type="button"
      class="btn btn-dark control publish-btn"
      :disabled="isLoading"
      @click="validateForm"
    >{{$t('ideas.stats.upload')}}</button>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import S3_Handler from "@/lib/api/s3_handler";
import IdeasAPI from "@/lib/api/Ideas";
const FileUploader = () => import("@/components/FileUploader");
const api = new IdeasAPI();
const s3 = new S3_Handler();

export default {
  components: {
    FileUploader,
  },
  data: function () {
    return {
      isLoading: false,
      file: null,
      fileUrl: null,
      uploadStatus: "",
      signedURL: null,
    };
  },
  validations: {
    file: {
      required,
    },
  },
  beforeMount: async function () {
    await this.getFile();
  },
  methods: {
    getFile: async function () {
      this.isLoading = true;
      this.$store.commit("global/SET_LOADING", true);
      try {
        const response = await api.getStatsFile();
        this.fileUrl = response.source;
        let urlResp = null;
        if (this.fileUrl) urlResp = s3.getSignedUrl(this.fileUrl);
        this.signedURL = urlResp;
        this.isLoading = false;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.isLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        throw error;
      }
    },
    setFile: function (file) {
      this.file = file;
      if (this.file) {
        this.uploadStatus = "Ready to upload";
      } else {
        this.uploadStatus = "";
      }
    },
    dropGetFile: function (event) {
      const file = event.dataTransfer.files[0];
      this.file = file;
      this.fileUrl = file.name;
      this.uploadStatus = "Ready to upload";
    },
    validateForm: async function () {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      await this.uploadFile();
    },
    uploadFile: async function () {
      this.isLoading = true;
      this.$store.commit("global/SET_LOADING", true);
      this.uploadStatus = "Uploading...";
      try {
        const timestamp = Date.now();
        const filename = `${timestamp}_ideas_stats.pdf`;
        const response = await s3.uploadFile(filename, this.file);
        await api.postStatsFile(response.Location);
        this.uploadStatus = "File uploaded";
        this.$store.commit("global/SET_LOADING", false);
        this.isLoading = false;
        this.file = null;
        this.fileUrl = null;
        this.$v.$reset();
        await this.getFile();
      } catch (error) {
        this.uploadStatus = "Error";
        this.$store.commit("global/SET_LOADING", false);
        this.isLoading = false;
        throw error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#ideas-stats {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 4rem;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .download-btn {
    background-color: $black;
    color: $white;
    border-radius: 0;
    padding: 0.5rem 2rem;
    align-self: center;
  }

  .input-container {
    width: 40%;
  }

  .error-msg {
    color: $warn_red;
    font-size: 14px;
  }

  .drop-container,
  .uploadInfo {
    width: 80%;
    height: 6rem;
    background-color: $w_2;
    border: 1px solid $wg_2;
    align-self: center;
    margin: 3rem 0;
    padding: 1rem;
  }
  .drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .uploadInfo {
    overflow-y: scroll;
  }

  .publish-btn {
    align-self: flex-end;
    background: #000;
    border-radius: 0px;
    padding: 0.5rem 1rem;
  }
}
</style>