export default class File_Helper {
  constructor() {}

  async getImageDimension(file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(evt) {
          const img = new Image();
          img.src = reader.result
          img.onload = function() {
            resolve({
              width: img.naturalWidth,
              height: img.naturalHeight
            });
          }
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}