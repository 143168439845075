<template>
  <div id="legal-terms">
    <h1>{{$t('legal.terms.title')}}</h1>
    <h6>{{$t('legal.terms.lastUpdate')}} {{lastUpdate}}  - ({{version}})</h6>
    <!-- <p>{{$t('legal.terms.directions')}}</p> -->
    <!-- Wysiwyg -->
    <div class="input-container">
      <div class="wysiwyg">
        <Wysiwyg
          v-if="!wysiwygLoading"
          :placeholder="$t('legal.terms.placeholder')"
          :articleBody="delta"
          v-on:updateBody="body = $event"
        ></Wysiwyg>
      </div>
      <div class="error-msg">
        <span v-if="$v.body.$error">{{$t("legal.terms.errors.body_required")}}</span>
      </div>
    </div>
    <button
      type="button"
      class="btn control publish-btn"
      :disabled="isUploading"
      @click="validateForm"
    >{{$t('map.transport.FAQ.publish')}}</button>
  </div>
</template>

<script>
import apiService from "@/lib/api/Legal.js";
import { required } from "vuelidate/lib/validators";
const Wysiwyg = () => import("@/components/Wysiwyg");
const api = new apiService();

export default {
  components: {
    Wysiwyg
  },
  data: function() {
    return {
      wysiwygLoading: true,
      delta: null,
      body: null,
      isUploading: false,
      lastUpdate: null,
      update: null,
      publish: null,
      version: null
    };
  },
  computed: {},
  validations: {
    body: {
      required
    }
  },
  beforeMount: async function() {
    await this.getGeneral();
  },
  methods: {
    getGeneral: async function() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const response = await api.getGeneral("Legal-Terms");
        this.delta = response.body;
        this.body = response.body;
        this.lastUpdate = response.lastPost;
        this.version = response.version;
        this.wysiwygLoading = false;
        this.$store.commit("global/SET_LOADING", false);
      } catch (error) {
        this.wysiwygLoading = false;
        this.$store.commit("global/SET_LOADING", false);
        this.delta = null;
        throw error;
      }
    },
    validateForm: async function() {
      this.isUploading = true;
      this.$v.$touch();
      if (this.$v.$error) {
        this.isUploading = false;
        return;
      }
      if (this.version){
        this.update = true;
        if (confirm('¿Desea enviar a los usuarios la nueva versión?')) {
          this.publish = true;
        }
      }
      await this.postGeneral(this.update, this.publish);
    },
    postGeneral: async function(update = false, publish = false) {
      this.$store.commit("global/SET_LOADING", true);
      const api = new apiService();
      try {
        if (update){
          await api.putGeneral({body: this.body, publish}, "Legal-Terms");
        } else {
          await api.postGeneral(this.body, "Legal-Terms");
        }
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        await this.getGeneral();
        this.publish = null;
      } catch (error) {
        this.$store.commit("global/SET_LOADING", false);
        this.isUploading = false;
        throw error;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#legal-terms {
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 4rem;

  .input-container {
    width: 100%;
    margin: 2rem 0;
  }

  .error-msg {
    color: $warn_red;
    font-size: 14px;
  }

  .publish-btn {
    align-self: flex-end;
    border-radius: 0px;
    background: #000;
    color: #fff;
  }
}
</style>

<style lang="scss">
#legal-terms {
  .quill-editor .ql-editor {
    height: 50vh;
  }
}
</style>
