<template>
  <div class="collaborator">
    <div class="row">
      <div class="col-md-3">
        <h1>{{ $t("metrics.collaborators.title") }}</h1>
        <p>{{ $t("metrics.collaborators.subtitle") }}</p>
        <p>{{ $t("metrics.collaborators.date") }}: {{consultingDate}}</p>
      </div>
      <div class="col-md-8">
        <button
          @click="downloadGraph"
          class="btn-download"
        >{{ $t("events.listEvents.donwloadBtn") }}</button>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-5">
        <div class="collaborator-card">
          <div class="collaborator-card-header">
            <img src="../../assets/img/icon/on.png" alt="ON Icon" />
            <h5 class="ml-2">{{ $t("metrics.collaborators.activeCollaborators") }}</h5>
          </div>
          <p>{{ $t("metrics.collaborators.activeCollaboratorsBody") }}</p>
          <div class="collaborator-card-body">
            <h4>{{ activeUsers }}</h4>
            <div class="red-line"></div>
          </div>
        </div>
        <div class="collaborator-card mt-5">
          <div class="collaborator-card-header">
            <img src="../../assets/img/icon/off.png" alt="OFF Icon" />
            <h5 class="ml-2">{{ $t("metrics.collaborators.inactiveCollaborators") }}</h5>
          </div>
          <p>{{ $t("metrics.collaborators.inactiveCollaboratorsBody") }}</p>
          <div class="collaborator-card-body">
            <h4>{{ inactiveUsers }}</h4>
            <div class="gray-line"></div>
          </div>
        </div>
      </div>
      <div class="col-md-7 chart">
        <h1>{{ $t("metrics.collaborators.collaboratorsType") }}</h1>
        <p class="subtitle">{{ $t("metrics.collaborators.collaboratorsTypeSubtitle") }}</p>
        <div class="chart-graph">
          <apexchart
            type="pie"
            width="100%"
            height="250"
            :options="graphic.chartOptions"
            :series="graphic.series"
          ></apexchart>
        </div>
        <div class="stats mt-4">
          <ul>
            <li>
              <div class="value">
                <div class="circle blue mr-1"></div>
                <p>{{$t("metrics.collaborators.chart.direct")}}:{{collaboratorsData.Directo}}</p>
              </div>
            </li>
            <li>
              <div class="value">
                <div class="circle gray mr-1"></div>
                <p>{{$t("metrics.collaborators.chart.tempDirect")}}:{{collaboratorsData["Directo Temporal"]}}</p>
              </div>
            </li>
            <li>
              <div class="value">
                <div class="circle pink mr-1"></div>
                <p>{{$t("metrics.collaborators.chart.mk")}}:{{collaboratorsData.MK}}</p>
              </div>
            </li>
            <li>
              <div class="value">
                <div class="circle green mr-1"></div>
                <p>{{$t("metrics.collaborators.chart.omk")}}:{{collaboratorsData.OMK}}</p>
              </div>
            </li>
            <li>
              <div class="value">
                <div class="circle yellow mr-1"></div>
                <p>{{$t("metrics.collaborators.chart.pdc")}}:{{collaboratorsData.PdC}}</p>
              </div>
            </li>
            <li>
              <div class="value">
                <div class="circle darkGray mr-1"></div>
                <p>{{$t("metrics.collaborators.chart.tempPdc")}}:{{collaboratorsData["PdC Temporal"]}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API_SERVICE from "@/lib/api/metrics.js";
import moment from "moment";
const api = new API_SERVICE();
export default {
  data() {
    return {
      activeUsers: 0,
      inactiveUsers: 0,
      collaboratorsData: [],
      graphic: {
        series: [44, 55, 20, 10, 5, 30],
        chartOptions: {
          chart: {
            width: "100%",
            type: "pie",
            height: "700px",
          },

          legend: {
            position: "right",
            offsetX: 100,
            offsetY: 30,
            fontFamily: "audi-extended",
            fontWeight: 700,
            markers: {
              width: 17,
              height: 17,
            },
          },
          labels: [
            this.$t("metrics.collaborators.chart.direct"),
            this.$t("metrics.collaborators.chart.tempDirect"),
            this.$t("metrics.collaborators.chart.mk"),
            this.$t("metrics.collaborators.chart.omk"),
            this.$t("metrics.collaborators.chart.pdc"),
            this.$t("metrics.collaborators.chart.tempPdc"),
          ],
          responsive: [
            {
              breakpoint: 1440,
              options: {
                chart: {
                  width: 400,
                },
                legend: {
                  position: "right",
                },
              },
            },
          ],
          colors: [
            "#36B6FE",
            "#C4C4C4",
            "#FF4895",
            "#12C464",
            "#FFAE50",
            "#4F4F4F",
          ],
          states: {
            hover: {
              filter: {
                type: "none",
              },
            },
          },
        },
      },
    };
  },
  async beforeMount() {
    await this.getGraphics();
  },
  methods: {
    getGraphics: async function () {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.getMetricStats();
        this.collaboratorsData = resp.data;
        this.activeUsers = resp.data["Total usuarios activos"];
        this.inactiveUsers = resp.data["Total usuarios inactivos"];
        let arraySeries = [];
        arraySeries.push(
          resp.data.Directo,
          resp.data["Directo Temporal"],
          resp.data.MK,
          resp.data.OMK,
          resp.data.PdC,
          resp.data["PdC Temporal"]
        );
        this.graphic.series = arraySeries;
      } catch (error) {
        console.log(error);
        this.$store.commit("global/SET_LOADING", false);
      } finally {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
    async downloadGraph() {
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.downloadMetricStats();
        let anchor = document.createElement("a");
        anchor.href = resp.url;
        anchor.target = "_blank";
        anchor.click();
      } catch (error) {
        console.log(error);
        this.$store.commit("global/SET_LOADING", false);
      } finally {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
  },
  computed: {
    consultingDate() {
      return moment(new Date()).format("DD/MM/YYYY HH:MM");
    },
  },
};
</script>

<style>
.collaborator {
  background-color: #fff;
  padding: 2rem;
}
.collaborator .chart .stats ul {
  display: inline;
  list-style: none;
}
.collaborator .chart .stats ul li {
  display: inline-block;
  margin-right: 1rem;
}
.collaborator .chart .stats .circle {
  width: 15px;
  height: 15px;
  border-radius: 100%;
}
.collaborator .chart .stats ul li .value {
  display: flex;
  align-items: baseline;
}
.collaborator .btn-download {
  padding: 1rem;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 0px;
  float: right;
}
.collaborator .collaborator-card {
  width: 80%;
  padding: 1.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 13rem;
}
.collaborator .collaborator-card .collaborator-card-header {
  display: flex;
  align-items: flex-start;
}
.collaborator .collaborator-card .collaborator-card-header h5 {
  font-weight: bold;
}
.collaborator .collaborator-card .collaborator-card-body {
  display: flex;
  justify-content: center;
  position: relative;
}
.collaborator .collaborator-card .collaborator-card-body h4 {
  font-weight: bold;
}
.collaborator .collaborator-card .collaborator-card-body .red-line {
  width: 65px;
  height: 5px;
  background-color: #bb0a30;
  position: absolute;
  top: 80%;
}
.collaborator .collaborator-card .collaborator-card-body .gray-line {
  width: 65px;
  height: 5px;
  background-color: #c4c4c4;
  position: absolute;
  top: 80%;
}
.collaborator .chart .subtitle {
  width: 80%;
}
.collaborator .blue {
  background-color: #36b6fe;
}
.collaborator .gray {
  background-color: #c4c4c4;
}
.collaborator .pink {
  background-color: #ff4895;
}
.collaborator .green {
  background-color: #12c464;
}
.collaborator .yellow {
  background-color: #ffae50;
}
.collaborator .darkGray {
  background-color: #4f4f4f;
}
</style>
