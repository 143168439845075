<template>
  <div class="driversCrud">
    <div class="row bg-white">
      <div class="col-md-12 top py-2">
        <router-link
          class="return"
          :to="{
            name: 'Drivers'
          }"
        >
          <i class="fas fa-chevron-left"></i>
          {{ $t("events.newEvent.back") }}
        </router-link>
      </div>
      <div class="col-md-12 px-5 py-3 d-flex justify-content-between">
        <h1 class="labelBold">
          {{
            details
              ? $t("qrAccess.activeDrivers.crud.details")
              : edit
              ? $t("qrAccess.activeDrivers.crud.edit")
              : $t("qrAccess.activeDrivers.crud.create")
          }}
        </h1>
        <button class="btn-download mr-5" @click="changeToEdit" v-if="details">
          {{ $t("qrAccess.activeDrivers.crud.editBtn") }}
        </button>
        <button
          class="btn-download mr-5"
          v-else
          @click="saveDriver"
          :disabled="validateFillInputs"
        >
          {{ $t("qrAccess.activeDrivers.crud.saveBtn") }}
        </button>
      </div>

      <!-- CRUD Pasajero -->
      <div class="col-md-6 px-5 py-4 formulario">
        <!-- Nombre -->
        <label class="labelBold">{{
          $t("qrAccess.activeDrivers.crud.name")
        }}</label>
        <input v-if="!details" type="text" v-model="name" />
        <p v-if="details">{{ data.name }}</p>
        <!-- Apellidos -->
        <label class="labelBold mt-2">{{
          $t("qrAccess.activeDrivers.crud.lastName")
        }}</label>
        <input v-if="!details" type="text" v-model="lastName" />
        <p v-if="details">{{ data.lastName }}</p>
        <label class="labelBold mt-2">{{
          $t("qrAccess.activeDrivers.crud.motherLastName")
        }}</label>
        <input v-if="!details" type="text" v-model="mothersLastName" />
        <p v-if="details">{{ data.mothersLastName }}</p>
        <!-- Número de control -->
        <label class="labelBold mt-2">{{
          $t("qrAccess.activeDrivers.crud.controlOrIdentifier")
        }}</label>
        <!-- <input v-if="!details" type="text" v-model="this.controlNumber" /> -->
        <p>{{ this.controlNumber }}</p>
        <!-- Empresa -->
        <label class="labelBold mt-2">{{
          $t("qrAccess.activeDrivers.crud.company")
        }}</label>
        <input v-if="!details" type="text" v-model="company" />
        <p v-if="details">{{ data.companyName }}</p>
        <!-- Proveedor -->
        <label class="labelBold mt-2">{{
          $t("qrAccess.activeDrivers.crud.providerNumber")
        }}</label>
        <input v-if="!details" type="text" v-model="providerNumber" />
        <p v-if="details">{{ data.providerNumber }}</p>
        <!-- Celular -->
        <label class="labelBold mt-2">{{
          $t("qrAccess.activeDrivers.crud.phone")
        }}</label>
        <input v-if="!details" type="tel" v-model="phone" />
        <p v-if="details">{{ data.personalCellphoneNumber }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import API_SERVICE from "@/lib/api/QRAccess.js";
const api = new API_SERVICE();
export default {
  props: {
    controlNumber: {
      type: String
    },
    data: {
      type: Object
    },
    details: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: "",
      lastName: "",
      mothersLastName: "",
      company: "",
      providerNumber: "",
      phone: ""
    };
  },
  async beforeMount() {
    if(this.controlNumber) {
      await this.getDetailDriver();
      this.fillData();
    }
  },
  methods: {
    getDetailDriver: async function() {
      // let currentLanguage = localStorage.getItem("lang");
      this.$store.commit("global/SET_LOADING", true);
      try {
        const resp = await api.detailDriver(this);
        this.busDriver = resp.busDrivers;
      } catch (error) {
        console.log(error);
        this.$store.commit("global/SET_LOADING", false);
      } finally {
        this.$store.commit("global/SET_LOADING", false);
      }
    },
    fillData: function() {
      try {
        // let details = this.$route.params.details;
        let id = this.$route.params.id;
        this.name = this.busDriver[0].name;
        this.lastName = this.busDriver[0].lastName;
        this.mothersLastName = this.busDriver[0].mothersLastName;
        this.company = this.busDriver[0].companyName;
        this.providerNumber = this.busDriver[0].providerNumber;
        this.phone = this.busDriver[0].personalCellphoneNumber;
      } catch (error) {
        console.log('Error in fillData: ', error);
      }
    },
    changeToEdit() {
      this.edit = true;
      this.details = false;
    },
    async saveDriver() {
      if (this.create) {
        this.$store.commit("global/SET_LOADING", true);
        try {
          let params = {
            name: this.name,
            lastName: this.lastName,
            mothersLastName: this.mothersLastName,
            companyName: this.company,
            providerNumber: this.providerNumber,
            personalCellphoneNumber: this.phone
          };
          const resp = await api.createDriver(params);
          this.$router.push({
            name: "Drivers"
          });
        } catch (error) {
          console.log('Error trying to create driver: ', error);
          this.$store.commit("global/SET_LOADING", false);
        }
      } else if(this.edit) {
        try {
          let params = {
            name: this.name,
            lastName: this.lastName,
            mothersLastName: this.mothersLastName,
            companyName: this.company,
            providerNumber: this.providerNumber,
            personalCellphoneNumber: this.phone,
            controlNumber: this.controlNumber
          };

          const resp = await api.updateDriver(params);
          this.$router.push({
            name: "Drivers"
          });
        } catch (error) {
          console.log('Error trying to update driver: ', error);
          this.$store.commit("global/SET_LOADING", false);
        }
      }
    }
  },
  computed: {
    validateFillInputs() {
      if (this.name === "" || this.lastName === "" || this.mothersLastName === "" || this.company === "" || this.providerNumber === "" || this.phone === "") {
        return true;
      }
      return false;
    }
  }
};
</script>

<style>
.return {
  color: black;
  cursor: pointer;
}
.return:hover {
  text-decoration: underline !important;
  color: black;
}
.labelBold {
  font-weight: bold;
  color: #000;
}
</style>
