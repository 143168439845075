var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"blog-list"}},[_c('header',{staticClass:"header"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("news.list.title")))]),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t("news.list.directions1"))+"\n        "),_c('br'),_vm._v("\n        "+_vm._s(_vm.$t("news.list.directions2"))+"\n      ")])]),_c('div',{staticClass:"controllers"},[_c('button',{staticClass:"btn btn-dark control new-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({ name: 'Blog New' })}}},[_vm._v("\n        "+_vm._s(_vm.$t("news.list.new"))+"\n      ")])])]),(_vm.newsList)?_c('v-client-table',{attrs:{"columns":_vm.table_options.columns,"options":_vm.table_options.options,"data":_vm.newsList},scopedSlots:_vm._u([{key:"updated_at",fn:function(props){return [_vm._v(_vm._s(_vm._f("formatDate")(props.row.updated_at,_vm.appLang)))]}},{key:"top",fn:function(props){return [_c('div',{staticClass:"starred"},[(props.row.top)?_c('i',{staticClass:"fas fa-star"}):_vm._e()])]}},{key:"report",fn:function(props){return [_c('div',[(props.row.hasCommentsReported)?_c('span',{staticClass:"report-warning"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" Reporte\n        ")]):_vm._e()])]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"actions"},[_c('router-link',{attrs:{"to":{
            name: 'Blog Edit',
            params: {
              id: props.row.slug
            }
          }}},[_c('i',{staticClass:"icons-black far fa-edit"})]),_c('i',{staticClass:"icons-black far fa-trash-alt",on:{"click":function($event){return _vm.openModal(props.row)}}}),_c('router-link',{attrs:{"to":{
            name: 'Blog Details',
            params: {
              id: props.row.slug
            }
          }}},[_c('i',{staticClass:"icons-black far fa-eye"})]),(props.row.likes > 0)?_c('router-link',{attrs:{"to":{
            name: 'Blog Likes',
            params: {
              id: props.row.slug
            }
          }}},[_c('i',{staticClass:"icons-black fas fa-heart"})]):_vm._e()],1)]}}],null,false,1823390667)}):_vm._e(),(_vm.modalActive)?_c('Modal',{on:{"closeModal":_vm.closeModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t("news.list.modal.title")))])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"controls"},[_c('span',{staticClass:"control cancel-btn",on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t("news.list.modal.cancel")))]),_c('button',{staticClass:"btn btn-dark control delete-btn",attrs:{"type":"control button","disabled":_vm.isLoading},on:{"click":_vm.deletePost}},[_vm._v("\n          "+_vm._s(_vm.$t("news.list.modal.delete"))+"\n        ")])])]},proxy:true}],null,false,3917848026)},[[_c('strong',[_vm._v(_vm._s(_vm.$t("news.list.modal.body")))]),_c('p',[_vm._v(_vm._s(_vm.postToDelete.title))])]],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }